"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.internalActions = exports.actions = exports.STORE_NAME = exports.RESET_STORES = exports.MANUAL_REMOVE = exports.MANUAL_INSERT = exports.INITIAL_STATE = exports.FETCH_WORK_REQUEST_COMMENTS_SUCCESS = exports.FETCH_WORK_REQUEST_COMMENTS_REQUEST = exports.FETCH_WORK_REQUEST_COMMENTS_FAILURE = exports.FETCH_WORK_REQUEST_COMMENTS = exports.FETCH_OFFLINE_COMMENTS_SUCCESS = exports.FETCH_OFFLINE_COMMENTS_REQUEST = exports.FETCH_OFFLINE_COMMENTS_FAILURE = exports.FETCH_OFFLINE_COMMENTS = void 0;
exports.reducer = reducer;
exports.selectors = void 0;
exports.watchComments = watchComments;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _lodash = _interopRequireDefault(require("lodash"));

var _lodash2 = _interopRequireDefault(require("lodash.get"));

var _reduxActions = require("redux-actions");

var _effects = require("redux-saga/effects");

var _common = require("./common");

var _commentService = _interopRequireDefault(require("~/app/3x/modules/services/comment-service"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var RESET_STORES = 'session.reset-stores'; // Comments come in denormalized (replies attached directly)
// which can make some of the operations tricky.
// TODO: If performance or bugs start to appear, we may need to restructure to something simpler

exports.RESET_STORES = RESET_STORES;
var STORE_NAME = 'commentsStore';
exports.STORE_NAME = STORE_NAME;

function* fetchWorkRequestComments(_ref) {
  var {
    payload
  } = _ref;
  var {
    workRequestId
  } = payload;

  try {
    yield (0, _effects.put)(internalActions.getWorkRequestCommentsRequest({
      workRequestId
    }));
    var comments = yield _commentService.default.getCommentsForWorkRequest(workRequestId);
    yield (0, _effects.put)(internalActions.getWorkRequestCommentsSuccess({
      workRequestId,
      comments
    }));
    return comments;
  } catch (e) {
    var err = (0, _common.handleError)(e);
    yield (0, _effects.put)(internalActions.getWorkRequestCommentsFailure({
      workRequestId,
      err
    }));
    return err;
  }
}

function* fetchOfflineComments(_ref2) {
  var {
    payload
  } = _ref2;
  var {
    offlineId
  } = payload;

  try {
    yield (0, _effects.put)(internalActions.getOfflineCommentsRequest({
      offlineId
    }));
    var comments = yield _commentService.default.getCommentsForOffline(offlineId);
    yield (0, _effects.put)(internalActions.getOfflineCommentsSuccess({
      offlineId,
      comments
    }));
    return comments;
  } catch (e) {
    var err = (0, _common.handleError)(e);
    yield (0, _effects.put)(internalActions.getOfflineCommentsFailure({
      offlineId,
      err
    }));
    return err;
  }
}

var FETCH_OFFLINE_COMMENTS = 'comments.fetch-by-offline';
exports.FETCH_OFFLINE_COMMENTS = FETCH_OFFLINE_COMMENTS;
var FETCH_OFFLINE_COMMENTS_REQUEST = 'comments.fetch-by-offline.request';
exports.FETCH_OFFLINE_COMMENTS_REQUEST = FETCH_OFFLINE_COMMENTS_REQUEST;
var FETCH_OFFLINE_COMMENTS_SUCCESS = 'comments.fetch-by-offline.success';
exports.FETCH_OFFLINE_COMMENTS_SUCCESS = FETCH_OFFLINE_COMMENTS_SUCCESS;
var FETCH_OFFLINE_COMMENTS_FAILURE = 'comments.fetch-by-offline.failure';
exports.FETCH_OFFLINE_COMMENTS_FAILURE = FETCH_OFFLINE_COMMENTS_FAILURE;
var FETCH_WORK_REQUEST_COMMENTS = 'comments.fetch-by-work-request';
exports.FETCH_WORK_REQUEST_COMMENTS = FETCH_WORK_REQUEST_COMMENTS;
var FETCH_WORK_REQUEST_COMMENTS_REQUEST = 'comments.fetch-by-work-request.request';
exports.FETCH_WORK_REQUEST_COMMENTS_REQUEST = FETCH_WORK_REQUEST_COMMENTS_REQUEST;
var FETCH_WORK_REQUEST_COMMENTS_SUCCESS = 'comments.fetch-by-work-request.success';
exports.FETCH_WORK_REQUEST_COMMENTS_SUCCESS = FETCH_WORK_REQUEST_COMMENTS_SUCCESS;
var FETCH_WORK_REQUEST_COMMENTS_FAILURE = 'comments.fetch-by-work-request.failure';
exports.FETCH_WORK_REQUEST_COMMENTS_FAILURE = FETCH_WORK_REQUEST_COMMENTS_FAILURE;
var MANUAL_INSERT = 'comments.insert.manual';
exports.MANUAL_INSERT = MANUAL_INSERT;
var MANUAL_REMOVE = 'comments.remove.manual';
exports.MANUAL_REMOVE = MANUAL_REMOVE;
var INITIAL_STATE = {
  comments: {},
  commentsByOffline: {},
  commentsByWorkRequest: {}
};
exports.INITIAL_STATE = INITIAL_STATE;

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case RESET_STORES:
      return INITIAL_STATE;

    case FETCH_WORK_REQUEST_COMMENTS_REQUEST:
      {
        var {
          workRequestId
        } = action.payload;
        var existingContainer = (0, _lodash2.default)(state.commentsByWorkRequest, workRequestId, {});
        return _objectSpread(_objectSpread({}, state), {}, {
          commentsByWorkRequest: _objectSpread(_objectSpread({}, state.commentsByWorkRequest), {}, {
            [workRequestId]: _objectSpread(_objectSpread({
              lastLoadedAt: null,
              data: []
            }, existingContainer), {}, {
              isLoading: true
            })
          })
        });
      }

    case FETCH_WORK_REQUEST_COMMENTS_SUCCESS:
      {
        var {
          workRequestId: _workRequestId,
          comments
        } = action.payload;
        var now = new Date();

        var updatedComments = _objectSpread({}, state.comments);

        _lodash.default.forEach(comments, comment => {
          updatedComments[comment._id] = {
            isLoading: false,
            lastLoadedAt: now,
            data: comment
          };
        });

        return _objectSpread(_objectSpread({}, state), {}, {
          comments: updatedComments,
          commentsByWorkRequest: _objectSpread(_objectSpread({}, state.commentsByWorkRequest), {}, {
            [_workRequestId]: {
              lastUpdatedAt: now,
              isLoading: false,
              data: _lodash.default.map(comments, '_id')
            }
          })
        });
      }

    case FETCH_WORK_REQUEST_COMMENTS_FAILURE:
      {
        var {
          workRequestId: _workRequestId2,
          err
        } = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          commentsByOffline: _objectSpread(_objectSpread({}, state.commentsByWorkRequest), {}, {
            [_workRequestId2]: _objectSpread(_objectSpread({}, state.commentsByWorkRequest[_workRequestId2]), {}, {
              isLoading: false,
              loadError: err
            })
          })
        });
      }

    case FETCH_OFFLINE_COMMENTS_REQUEST:
      {
        var {
          offlineId
        } = action.payload;

        var _existingContainer = (0, _lodash2.default)(state.commentsByOffline, offlineId, {});

        return _objectSpread(_objectSpread({}, state), {}, {
          commentsByOffline: _objectSpread(_objectSpread({}, state.commentsByOffline), {}, {
            [offlineId]: _objectSpread(_objectSpread({
              lastLoadedAt: null,
              data: []
            }, _existingContainer), {}, {
              isLoading: true
            })
          })
        });
      }

    case FETCH_OFFLINE_COMMENTS_SUCCESS:
      {
        var {
          offlineId: _offlineId,
          comments: _comments
        } = action.payload;

        var _now = new Date();

        var _updatedComments = _objectSpread({}, state.comments);

        _lodash.default.forEach(_comments, comment => {
          _updatedComments[comment._id] = {
            isLoading: false,
            lastLoadedAt: _now,
            data: comment
          };
        });

        return _objectSpread(_objectSpread({}, state), {}, {
          comments: _updatedComments,
          commentsByOffline: _objectSpread(_objectSpread({}, state.commentsByOffline), {}, {
            [_offlineId]: {
              lastUpdatedAt: _now,
              isLoading: false,
              data: _lodash.default.map(_comments, '_id')
            }
          })
        });
      }

    case FETCH_OFFLINE_COMMENTS_FAILURE:
      {
        var {
          offlineId: _offlineId2,
          err: _err
        } = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          commentsByOffline: _objectSpread(_objectSpread({}, state.commentsByOffline), {}, {
            [_offlineId2]: _objectSpread(_objectSpread({}, state.commentsByOffline[_offlineId2]), {}, {
              isLoading: false,
              loadError: _err
            })
          })
        });
      }

    case MANUAL_INSERT:
      {
        var {
          comment
        } = action.payload;

        var _now2 = new Date(); // If its a reply


        if (comment.replyTo) {
          var container = state.comments[comment.replyTo];
          var oldComment = container.data;

          var updatedComment = _objectSpread(_objectSpread({}, oldComment), {}, {
            replies: [...oldComment.replies, comment]
          });

          return _objectSpread(_objectSpread({}, state), {}, {
            comments: _objectSpread(_objectSpread({}, state.comments), {}, {
              [comment.replyTo]: _objectSpread(_objectSpread({}, container), {}, {
                lastUpdatedAt: _now2,
                data: updatedComment
              })
            })
          });
        }

        var commentsByOffline = state.commentsByOffline;
        var commentsByWorkRequest = state.commentsByWorkRequest;

        if (comment.offline) {
          var offlineContainer = (0, _lodash2.default)(state.commentsByOffline, comment.offline, {
            lastLoadedAt: null,
            isLoading: false,
            data: []
          });
          offlineContainer.data.push(comment._id);
          commentsByOffline = _objectSpread(_objectSpread({}, state.commentsByOffline), {}, {
            [comment.offline]: offlineContainer
          });
        } else if (comment.workRequest) {
          var workRequestContainer = (0, _lodash2.default)(state.commentsByWorkRequest, comment.workRequest, {
            lastLoadedAt: null,
            isLoading: false,
            data: []
          });
          workRequestContainer.data.push(comment._id);
          commentsByWorkRequest = _objectSpread(_objectSpread({}, state.commentsByWorkRequest), {}, {
            [comment.workRequest]: workRequestContainer
          });
        }

        return _objectSpread(_objectSpread({}, state), {}, {
          comments: _objectSpread(_objectSpread({}, state.comments), {}, {
            [comment._id]: {
              isLoading: false,
              lastLoadedAt: _now2,
              data: comment
            }
          }),
          commentsByOffline,
          commentsByWorkRequest
        });
      }

    case MANUAL_REMOVE:
      {
        var {
          workRequestId: _workRequestId3,
          offlineId: _offlineId3,
          commentId,
          replyTo
        } = action.payload; // If its a reply

        if (replyTo) {
          var _container = state.comments[replyTo];
          var _comment = _container.data;

          var _updatedComment = _objectSpread(_objectSpread({}, _comment), {}, {
            replies: _lodash.default.reject(_comment.replies, {
              _id: commentId
            })
          });

          return _objectSpread(_objectSpread({}, state), {}, {
            comments: _objectSpread(_objectSpread({}, state.comments), {}, {
              [_comment._id]: _objectSpread(_objectSpread({}, _container), {}, {
                data: _updatedComment
              })
            })
          });
        }

        var _comments2 = _lodash.default.omit(state.comments, commentId);

        var _commentsByOffline = state.commentsByOffline;
        var _commentsByWorkRequest = state.commentsByWorkRequest;

        if (_offlineId3) {
          var _container2 = state.commentsByOffline[_offlineId3];
          _commentsByOffline = _objectSpread(_objectSpread({}, state.commentsByOffline), {}, {
            [_offlineId3]: _objectSpread(_objectSpread({}, _container2), {}, {
              data: _lodash.default.without(_container2.data, commentId)
            })
          });
        } else if (_workRequestId3) {
          var _container3 = state.commentsByWorkRequest[_workRequestId3];
          _commentsByWorkRequest = _objectSpread(_objectSpread({}, state.commentsByWorkRequest), {}, {
            [_workRequestId3]: _objectSpread(_objectSpread({}, _container3), {}, {
              data: _lodash.default.without(_container3.data, commentId)
            })
          });
        }

        return _objectSpread(_objectSpread({}, state), {}, {
          comments: _comments2,
          commentsByOffline: _commentsByOffline,
          commentsByWorkRequest: _commentsByWorkRequest
        });
      }

    default:
      return state;
  }
}

var actions = {
  getWorkRequestComments: (0, _reduxActions.createAction)(FETCH_WORK_REQUEST_COMMENTS),
  getOfflineComments: (0, _reduxActions.createAction)(FETCH_OFFLINE_COMMENTS),
  insertComment: (0, _reduxActions.createAction)(MANUAL_INSERT),
  removeComment: (0, _reduxActions.createAction)(MANUAL_REMOVE)
};
/**
 * Actions that should only be invoked internally
 */

exports.actions = actions;
var internalActions = {
  getWorkRequestCommentsRequest: (0, _reduxActions.createAction)(FETCH_WORK_REQUEST_COMMENTS_REQUEST),
  getWorkRequestCommentsSuccess: (0, _reduxActions.createAction)(FETCH_WORK_REQUEST_COMMENTS_SUCCESS),
  getWorkRequestCommentsFailure: (0, _reduxActions.createAction)(FETCH_WORK_REQUEST_COMMENTS_FAILURE),
  getOfflineCommentsRequest: (0, _reduxActions.createAction)(FETCH_OFFLINE_COMMENTS_REQUEST),
  getOfflineCommentsSuccess: (0, _reduxActions.createAction)(FETCH_OFFLINE_COMMENTS_SUCCESS),
  getOfflineCommentsFailure: (0, _reduxActions.createAction)(FETCH_OFFLINE_COMMENTS_FAILURE)
};
exports.internalActions = internalActions;
var selectors = {
  areWorkRequestCommentsLoading: (0, _common.createSelector)(STORE_NAME, areWorkRequestCommentsLoading),
  areWorkRequestCommentsLoaded: (0, _common.createSelector)(STORE_NAME, areWorkRequestCommentsLoaded),
  didWorkRequestCommentsLoadFail: (0, _common.createSelector)(STORE_NAME, didWorkRequestCommentsLoadFail),
  getWorkRequestComments: (0, _common.createSelector)(STORE_NAME, getWorkRequestComments),
  areOfflineCommentsLoading: (0, _common.createSelector)(STORE_NAME, areOfflineCommentsLoading),
  areOfflineCommentsLoaded: (0, _common.createSelector)(STORE_NAME, areOfflineCommentsLoaded),
  didOfflineCommentsLoadFail: (0, _common.createSelector)(STORE_NAME, didOfflineCommentsLoadFail),
  getOfflineComments: (0, _common.createSelector)(STORE_NAME, getOfflineComments)
};
exports.selectors = selectors;

function areWorkRequestCommentsLoading(state, workRequestId) {
  return !!(0, _lodash2.default)(state.commentsByWorkRequest, [workRequestId, 'isLoading'], false);
}

function areWorkRequestCommentsLoaded(state, workRequestId) {
  return !(0, _lodash2.default)(state.commentsByWorkRequest, [workRequestId, 'lastLoadedAt'], false);
}

function didWorkRequestCommentsLoadFail(state, workRequestId) {
  return !!(0, _lodash2.default)(state.commentsByWorkRequest, [workRequestId, 'loadError'], false);
}

function getWorkRequestComments(state, workRequestId) {
  var commentIds = (0, _lodash2.default)(state.commentsByWorkRequest, [workRequestId, 'data'], []);
  return _lodash.default.map(commentIds, id => state.comments[id].data);
}

function areOfflineCommentsLoading(state, offlineId) {
  return !!(0, _lodash2.default)(state.commentsByOffline, [offlineId, 'isLoading'], false);
}

function areOfflineCommentsLoaded(state, offlineId) {
  return !(0, _lodash2.default)(state.commentsByOffline, [offlineId, 'lastLoadedAt'], false);
}

function didOfflineCommentsLoadFail(state, offlineId) {
  return !!(0, _lodash2.default)(state.commentsByOffline, [offlineId, 'loadError'], false);
}

function getOfflineComments(state, offlineId) {
  var commentIds = (0, _lodash2.default)(state === null || state === void 0 ? void 0 : state.commentsByOffline, [offlineId, 'data'], []);
  return _lodash.default.map(commentIds, id => state.comments[id].data);
}

function* watchComments() {
  yield (0, _effects.takeEvery)(FETCH_WORK_REQUEST_COMMENTS, fetchWorkRequestComments);
  yield (0, _effects.takeEvery)(FETCH_OFFLINE_COMMENTS, fetchOfflineComments);
}