"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.actions = exports.UPDATE_ASSET = exports.STORE_NAME = exports.RESET_STORES = exports.REMOVE_ASSET_SUCCESS = exports.REMOVE_ASSET_REQUEST = exports.REMOVE_ASSET_QUEUE = exports.REMOVE_ASSET_FAILURE = exports.REMOVE_ASSET = exports.INITIAL_STATE = exports.FETCH_SIMILAR_ASSETS_SUCCESS = exports.FETCH_SIMILAR_ASSETS_REQUEST = exports.FETCH_SIMILAR_ASSETS_FAILURE = exports.FETCH_SIMILAR_ASSETS = exports.FETCH_RELATED_ASSETS_SUCCESS = exports.FETCH_RELATED_ASSETS_REQUEST = exports.FETCH_RELATED_ASSETS_FAILURE = exports.FETCH_RELATED_ASSETS = exports.FETCH_PROJECT_ASSETS_SUCCESS = exports.FETCH_PROJECT_ASSETS_REQUEST = exports.FETCH_PROJECT_ASSETS_FAILURE = exports.FETCH_PROJECT_ASSETS = exports.FETCH_ASSET_SUCCESS = exports.FETCH_ASSET_REQUEST = exports.FETCH_ASSET_FAILURE = exports.FETCH_ASSETS_SUCCESS = exports.FETCH_ASSETS_REQUEST = exports.FETCH_ASSETS_FAILURE = exports.FETCH_ASSETS = exports.FETCH_ASSET = exports.CANCEL_ASSET_REMOVAL = void 0;
exports.fetchAsset = fetchAsset;
exports.fetchAssets = fetchAssets;
exports.fetchProjectAssets = fetchProjectAssets;
exports.fetchRelatedAssets = fetchRelatedAssets;
exports.fetchSimilarAssets = fetchSimilarAssets;
exports.internalActions = void 0;
exports.reducer = reducer;
exports.removeAsset = removeAsset;
exports.selectors = void 0;
exports.watchAssets = watchAssets;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _lodash = _interopRequireWildcard(require("lodash"));

var _lodash2 = _interopRequireDefault(require("lodash.get"));

var _reduxActions = require("redux-actions");

var _effects = require("redux-saga/effects");

var _common = require("./common");

var _assetService = _interopRequireDefault(require("../modules/services/asset-service"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var RESET_STORES = 'session.reset-stores';
exports.RESET_STORES = RESET_STORES;
var STORE_NAME = 'assetsStore';
exports.STORE_NAME = STORE_NAME;
var EXTENDED_KEYS = ['sources', 'canAutosub'];

function* fetchProjectAssets(_ref) {
  var {
    payload
  } = _ref;
  var {
    projectId
  } = payload;

  try {
    yield (0, _effects.put)(internalActions.getProjectAssetsRequest({
      projectId
    }));
    var assets = yield _assetService.default.getProjectAssets(projectId);
    yield (0, _effects.put)(internalActions.getProjectAssetsSuccess({
      projectId,
      assets
    }));
    return assets;
  } catch (e) {
    var err = (0, _common.handleError)(e);
    yield (0, _effects.put)(internalActions.getProjectAssetsFailure({
      projectId,
      err
    }));
    return err;
  }
}

function* fetchSimilarAssets(_ref2) {
  var {
    payload
  } = _ref2;
  var {
    type,
    project
  } = payload;

  try {
    yield (0, _effects.put)(internalActions.getSimilarAssetsRequest({
      type
    }));
    var assets = yield _assetService.default.getSimilarAssets({
      type,
      project
    });
    yield (0, _effects.put)(internalActions.getSimilarAssetsSuccess({
      type,
      assets
    }));
    return assets;
  } catch (e) {
    var err = (0, _common.handleError)(e);
    yield (0, _effects.put)(internalActions.getSimilarAssetsFailure({
      type,
      err
    }));
    return err;
  }
}

function* fetchAsset(_ref3) {
  var {
    payload
  } = _ref3;
  var {
    assetId,
    options
  } = payload;

  try {
    yield (0, _effects.put)(internalActions.getAssetByIdRequest({
      assetId,
      options
    }));
    var asset = yield _assetService.default.getAssetById(assetId, options);

    if (!asset || _lodash.default.isEmpty(asset) || !asset.name || !asset.mediaType) {
      throw new Error('Oops... This asset does not exist anymore');
    }

    yield (0, _effects.put)(internalActions.getAssetByIdSuccess({
      asset,
      options
    }));
    return asset;
  } catch (e) {
    var err = (0, _common.handleError)(e);
    yield (0, _effects.put)(internalActions.getAssetByIdFailure({
      assetId,
      err
    }));
    return err;
  }
}

function* fetchAssets(_ref4) {
  var {
    payload
  } = _ref4;
  var {
    assetIds,
    options
  } = payload;

  try {
    yield (0, _effects.put)(internalActions.getAssetsByIdRequest({
      assetIds,
      options
    }));

    var assetPromises = _lodash.default.map(assetIds, assetId => _assetService.default.getAssetById(assetId, options));

    var assets = yield Promise.all(assetPromises);
    yield (0, _effects.put)(internalActions.getAssetsByIdSuccess({
      assets,
      options
    }));
    return assets;
  } catch (e) {
    var err = (0, _common.handleError)(e);
    yield (0, _effects.put)(internalActions.getAssetsByIdFailure({
      assetIds,
      err
    }));
    return err;
  }
}

function* fetchRelatedAssets(_ref5) {
  var {
    payload
  } = _ref5;
  var {
    assetId
  } = payload;

  try {
    yield (0, _effects.put)(internalActions.getRelatedAssetsRequest({
      assetId
    }));
    var relatedAssets = yield _assetService.default.getRelatedAssets(assetId);
    yield (0, _effects.put)(internalActions.getRelatedAssetsSuccess({
      assetId,
      assets: relatedAssets
    }));
    return relatedAssets;
  } catch (e) {
    var err = (0, _common.handleError)(e);
    yield (0, _effects.put)(internalActions.getRelatedAssetsFailure({
      assetId,
      err
    }));
    return err;
  }
}

function* backgroundDeleteAsset(assetId) {
  // If we receive an uniq asset, convert it to array (e.g: when we delete an asset from the asset page)
  if (!(0, _lodash.isArray)(assetId)) {
    assetId = [assetId];
  } // First, we put assets in queue to be able to cancel it later if needed
  // Otherwise, assets which haven't started can't have an updated state in store and being correctly displayed


  for (var i in assetId) {
    yield (0, _effects.put)(internalActions.removeAssetQueue({
      assetId: assetId[i]
    }));
  }

  try {
    for (var _i in assetId) {
      yield (0, _effects.put)(internalActions.removeAssetRequest({
        assetId: assetId[_i]
      }));
      yield (0, _effects.call)(_assetService.default.deleteAsset, assetId[_i]);
      yield (0, _effects.put)(internalActions.removeAssetSuccess({
        assetId: assetId[_i]
      }));
      yield (0, _effects.delay)(1000);
    }

    return;
  } catch (err) {
    if (yield (0, _effects.cancelled)()) {
      // If we cancel the operation, we need to end with last started operation
      yield (0, _effects.put)(internalActions.cancelAssetRemoval());
    } else {
      yield (0, _effects.put)(internalActions.removeAssetFailure({
        assetId,
        err
      }));
    }
  }
}

function* removeAsset(_ref6) {
  var {
    payload
  } = _ref6;
  var {
    assetId
  } = payload;
  yield (0, _effects.race)({
    response: (0, _effects.call)(backgroundDeleteAsset, assetId),
    cancel: (0, _effects.take)(CANCEL_ASSET_REMOVAL)
  });
}

var FETCH_PROJECT_ASSETS = 'project-assets.fetch';
exports.FETCH_PROJECT_ASSETS = FETCH_PROJECT_ASSETS;
var FETCH_PROJECT_ASSETS_REQUEST = 'project-assets.fetch.request';
exports.FETCH_PROJECT_ASSETS_REQUEST = FETCH_PROJECT_ASSETS_REQUEST;
var FETCH_PROJECT_ASSETS_SUCCESS = 'project-assets.fetch.success';
exports.FETCH_PROJECT_ASSETS_SUCCESS = FETCH_PROJECT_ASSETS_SUCCESS;
var FETCH_PROJECT_ASSETS_FAILURE = 'project-assets.fetch.failure';
exports.FETCH_PROJECT_ASSETS_FAILURE = FETCH_PROJECT_ASSETS_FAILURE;
var FETCH_SIMILAR_ASSETS = 'similar-assets.fetch';
exports.FETCH_SIMILAR_ASSETS = FETCH_SIMILAR_ASSETS;
var FETCH_SIMILAR_ASSETS_REQUEST = 'similar-assets.fetch.request';
exports.FETCH_SIMILAR_ASSETS_REQUEST = FETCH_SIMILAR_ASSETS_REQUEST;
var FETCH_SIMILAR_ASSETS_SUCCESS = 'similar-assets.fetch.success';
exports.FETCH_SIMILAR_ASSETS_SUCCESS = FETCH_SIMILAR_ASSETS_SUCCESS;
var FETCH_SIMILAR_ASSETS_FAILURE = 'similar-assets.fetch.failure';
exports.FETCH_SIMILAR_ASSETS_FAILURE = FETCH_SIMILAR_ASSETS_FAILURE;
var FETCH_ASSET = 'asset.fetch';
exports.FETCH_ASSET = FETCH_ASSET;
var FETCH_ASSET_REQUEST = 'asset.fetch.request';
exports.FETCH_ASSET_REQUEST = FETCH_ASSET_REQUEST;
var FETCH_ASSET_SUCCESS = 'asset.fetch.success';
exports.FETCH_ASSET_SUCCESS = FETCH_ASSET_SUCCESS;
var FETCH_ASSET_FAILURE = 'asset.fetch.failure';
exports.FETCH_ASSET_FAILURE = FETCH_ASSET_FAILURE;
var FETCH_ASSETS = 'assets.fetch';
exports.FETCH_ASSETS = FETCH_ASSETS;
var FETCH_ASSETS_REQUEST = 'assets.fetch.request';
exports.FETCH_ASSETS_REQUEST = FETCH_ASSETS_REQUEST;
var FETCH_ASSETS_SUCCESS = 'assets.fetch.success';
exports.FETCH_ASSETS_SUCCESS = FETCH_ASSETS_SUCCESS;
var FETCH_ASSETS_FAILURE = 'assets.fetch.failure';
exports.FETCH_ASSETS_FAILURE = FETCH_ASSETS_FAILURE;
var FETCH_RELATED_ASSETS = 'related-assets.fetch';
exports.FETCH_RELATED_ASSETS = FETCH_RELATED_ASSETS;
var FETCH_RELATED_ASSETS_REQUEST = 'related-assets.fetch.request';
exports.FETCH_RELATED_ASSETS_REQUEST = FETCH_RELATED_ASSETS_REQUEST;
var FETCH_RELATED_ASSETS_SUCCESS = 'related-assets.fetch.success';
exports.FETCH_RELATED_ASSETS_SUCCESS = FETCH_RELATED_ASSETS_SUCCESS;
var FETCH_RELATED_ASSETS_FAILURE = 'related-assets.fetch.failure';
exports.FETCH_RELATED_ASSETS_FAILURE = FETCH_RELATED_ASSETS_FAILURE;
var UPDATE_ASSET = 'asset.update.manual';
exports.UPDATE_ASSET = UPDATE_ASSET;
var REMOVE_ASSET = 'asset.remove';
exports.REMOVE_ASSET = REMOVE_ASSET;
var REMOVE_ASSET_QUEUE = 'asset.remove.queue';
exports.REMOVE_ASSET_QUEUE = REMOVE_ASSET_QUEUE;
var REMOVE_ASSET_REQUEST = 'asset.remove.request';
exports.REMOVE_ASSET_REQUEST = REMOVE_ASSET_REQUEST;
var REMOVE_ASSET_SUCCESS = 'asset.remove.success';
exports.REMOVE_ASSET_SUCCESS = REMOVE_ASSET_SUCCESS;
var REMOVE_ASSET_FAILURE = 'asset.remove.failure';
exports.REMOVE_ASSET_FAILURE = REMOVE_ASSET_FAILURE;
var CANCEL_ASSET_REMOVAL = 'asset.remove.cancel';
exports.CANCEL_ASSET_REMOVAL = CANCEL_ASSET_REMOVAL;
var INITIAL_STATE = {
  assets: {},
  assetsByProject: {},
  similarAssets: {},
  error: undefined
};
exports.INITIAL_STATE = INITIAL_STATE;

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case RESET_STORES:
      return INITIAL_STATE;

    case FETCH_PROJECT_ASSETS_REQUEST:
      {
        var {
          projectId
        } = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          assetsByProject: _objectSpread(_objectSpread({}, state.assetsByProject), {}, {
            [projectId]: _objectSpread(_objectSpread({
              // Place lastLoadedAt: null first so that its overwritten if it already exists
              lastLoadedAt: null,
              assets: []
            }, state.assetsByProject[projectId]), {}, {
              isLoading: true
            })
          })
        });
      }

    case FETCH_PROJECT_ASSETS_SUCCESS:
      {
        var {
          projectId: _projectId,
          assets
        } = action.payload;
        var now = new Date();

        var assetIds = _lodash.default.map(assets, '_id');

        var transformedAssets = _lodash.default.reduce(assets, (result, asset) => {
          var existingPermissions = (0, _lodash2.default)(state, ['assets', asset._id, 'data', '$permissions'], {}); // Merge existing data so that we don't lose extended permission data on a simple refresh

          _lodash.default.merge(asset === null || asset === void 0 ? void 0 : asset.$permissions, _lodash.default.pick(existingPermissions, EXTENDED_KEYS));

          result[asset._id] = {
            isLoading: false,
            lastLoadedAt: now,
            data: asset
          };
          return result;
        }, {});

        return _objectSpread(_objectSpread({}, state), {}, {
          assets: _objectSpread(_objectSpread({}, state.assets), transformedAssets),
          assetsByProject: _objectSpread(_objectSpread({}, state.assetsByProject), {}, {
            [_projectId]: {
              isLoading: false,
              lastLoadedAt: now,
              assets: assetIds
            }
          })
        });
      }

    case FETCH_PROJECT_ASSETS_FAILURE:
      {
        var {
          projectId: _projectId2
        } = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          assetsByProject: {
            [_projectId2]: _objectSpread(_objectSpread({}, state.assetsByProject[_projectId2]), {}, {
              isLoading: false
            })
          }
        });
      }

    case FETCH_SIMILAR_ASSETS_REQUEST:
      {
        var {
          type
        } = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          similarAssets: _objectSpread(_objectSpread({}, state.similarAssets), {}, {
            [type]: _objectSpread(_objectSpread({
              // Place lastLoadedAt: null first so that its overwritten if it already exists
              lastLoadedAt: null,
              assets: []
            }, state.similarAssets[type]), {}, {
              isLoading: true
            })
          })
        });
      }

    case FETCH_SIMILAR_ASSETS_SUCCESS:
      {
        var {
          type: _type,
          assets: _assets
        } = action.payload;

        var _now = new Date();

        var _assetIds = _lodash.default.map(_assets, '_id');

        var _transformedAssets = _lodash.default.reduce(_assets, (result, asset) => {
          var existingPermissions = (0, _lodash2.default)(state, ['assets', asset._id, 'data', '$permissions'], {}); // Merge existing data so that we don't lose extended permission data on a simple refresh

          _lodash.default.merge(asset === null || asset === void 0 ? void 0 : asset.$permissions, _lodash.default.pick(existingPermissions, EXTENDED_KEYS));

          result[asset._id] = {
            isLoading: false,
            lastLoadedAt: _now,
            data: asset
          };
          return result;
        }, {});

        return _objectSpread(_objectSpread({}, state), {}, {
          assets: _objectSpread(_objectSpread({}, state.assets), _transformedAssets),
          similarAssets: _objectSpread(_objectSpread({}, state.similarAssets), {}, {
            [_type]: {
              isLoading: false,
              lastLoadedAt: _now,
              assets: _assetIds
            }
          })
        });
      }

    case FETCH_SIMILAR_ASSETS_FAILURE:
      {
        var {
          type: _type2
        } = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          similarAssets: {
            [_type2]: _objectSpread(_objectSpread({}, state.similarAssets[_type2]), {}, {
              isLoading: false
            })
          }
        });
      }

    case FETCH_ASSET_REQUEST:
      {
        var {
          assetId
        } = action.payload;
        var existingContainer = state.assets[assetId] || {};
        return _objectSpread(_objectSpread({}, state), {}, {
          assets: _objectSpread(_objectSpread({}, state.assets), {}, {
            [assetId]: _objectSpread(_objectSpread({
              lastLoadedAt: null,
              lastLoadedExtendedAt: null,
              data: null
            }, existingContainer), {}, {
              isLoading: true
            })
          })
        });
      }

    case FETCH_ASSET_SUCCESS:
      {
        var {
          asset,
          options
        } = action.payload;

        var _now2 = new Date();

        var withExtended = (0, _lodash2.default)(options, 'withExtended', false);
        var _existingContainer = state.assets[asset._id];
        var existingPermissions = (0, _lodash2.default)(_existingContainer, 'data.$permissions', {}); // If the current query does not include extended permissions, merge possible existing ones

        if (!withExtended) {
          _lodash.default.merge(asset === null || asset === void 0 ? void 0 : asset.$permissions, _lodash.default.pick(existingPermissions, EXTENDED_KEYS));
        }

        return _objectSpread(_objectSpread({}, state), {}, {
          error: undefined,
          assets: _objectSpread(_objectSpread({}, state.assets), {}, {
            [asset._id]: _objectSpread(_objectSpread({}, state.assets[asset._id]), {}, {
              isLoading: false,
              lastLoadedAt: _now2,
              lastLoadedExtendedAt: withExtended ? _now2 : _existingContainer === null || _existingContainer === void 0 ? void 0 : _existingContainer.lastLoadedExtendedAt,
              data: asset
            })
          })
        });
      }

    case FETCH_ASSET_FAILURE:
      {
        var {
          assetId: _assetId,
          err
        } = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          error: err,
          assets: _objectSpread(_objectSpread({}, state.assets), {}, {
            [_assetId]: _objectSpread(_objectSpread({}, state.assets[_assetId]), {}, {
              isLoading: false
            })
          })
        });
      }

    case FETCH_ASSETS_REQUEST:
      {
        var {
          assetIds: _assetIds2
        } = action.payload;

        var assetContainers = _lodash.default.reduce(_assetIds2, (result, assetId) => {
          result[assetId] = _objectSpread(_objectSpread({
            lastLoadedAt: null,
            lastLoadedExtendedAt: null
          }, state.assets[assetId]), {}, {
            isLoading: true
          });
          return result;
        }, {});

        return _objectSpread(_objectSpread({}, state), {}, {
          assets: _objectSpread(_objectSpread({}, state.assets), assetContainers)
        });
      }

    case FETCH_ASSETS_SUCCESS:
      {
        var {
          assets: _assets2,
          options: _options
        } = action.payload;
        var extendAssets = {};

        var _now3 = new Date();

        var _withExtended = (0, _lodash2.default)(_options, 'withExtended', false);

        _assets2.forEach(asset => {
          var existingContainer = state.assets[asset._id];
          var existingPermissions = (0, _lodash2.default)(existingContainer, 'data.$permissions', {}); // If the current query does not include extended permissions, merge possible existing ones

          if (!_withExtended) {
            _lodash.default.merge(asset === null || asset === void 0 ? void 0 : asset.$permissions, _lodash.default.pick(existingPermissions, EXTENDED_KEYS));
          }

          extendAssets[asset._id] = {
            isLoading: false,
            lastLoadedAt: _now3,
            lastLoadedExtendedAt: _withExtended ? _now3 : existingContainer === null || existingContainer === void 0 ? void 0 : existingContainer.lastLoadedExtendedAt,
            data: asset
          };
        });

        return _objectSpread(_objectSpread({}, state), {}, {
          assets: _objectSpread(_objectSpread({}, state.assets), extendAssets)
        });
      }

    case FETCH_ASSETS_FAILURE:
      {
        var {
          assetIds: _assetIds3
        } = action.payload;
        var _extendAssets = {};

        _assetIds3.forEach(assetId => {
          _extendAssets[assetId] = _objectSpread(_objectSpread({}, state.assets[assetId]), {}, {
            isLoading: false
          });
        });

        return _objectSpread(_objectSpread({}, state), {}, {
          assets: _objectSpread(_objectSpread({}, state.assets), _extendAssets)
        });
      }

    case FETCH_RELATED_ASSETS_REQUEST:
      {
        var _state$assets$_assetI, _state$assets$_assetI2;

        var {
          assetId: _assetId2
        } = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          assets: _objectSpread(_objectSpread({}, state.assets), {}, {
            [_assetId2]: _objectSpread(_objectSpread({}, state.assets[_assetId2]), {}, {
              related: {
                assets: (_state$assets$_assetI = state.assets[_assetId2]) === null || _state$assets$_assetI === void 0 ? void 0 : (_state$assets$_assetI2 = _state$assets$_assetI.related) === null || _state$assets$_assetI2 === void 0 ? void 0 : _state$assets$_assetI2.assets,
                isLoading: true,
                isLoaded: false,
                isFailed: false,
                error: null
              }
            })
          })
        });
      }

    case FETCH_RELATED_ASSETS_SUCCESS:
      {
        var {
          assetId: _assetId3,
          assets: _assets3
        } = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          assets: _objectSpread(_objectSpread({}, state.assets), {}, {
            [_assetId3]: _objectSpread(_objectSpread({}, state.assets[_assetId3]), {}, {
              related: {
                assets: _assets3,
                isLoading: false,
                isLoaded: true,
                isFailed: false,
                error: null
              }
            })
          })
        });
      }

    case FETCH_RELATED_ASSETS_FAILURE:
      {
        var _state$assets$_assetI3;

        var {
          assetId: _assetId4,
          err: _err
        } = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          assets: _objectSpread(_objectSpread({}, state.assets), {}, {
            [_assetId4]: _objectSpread(_objectSpread({}, state.assets[_assetId4]), {}, {
              related: _objectSpread(_objectSpread({}, (_state$assets$_assetI3 = state.assets[_assetId4]) === null || _state$assets$_assetI3 === void 0 ? void 0 : _state$assets$_assetI3.related), {}, {
                isLoading: false,
                isLoaded: false,
                isFailed: true,
                error: _err
              })
            })
          })
        });
      }

    case UPDATE_ASSET:
      {
        var _asset = action.payload;

        var _now4 = new Date();

        return _objectSpread(_objectSpread({}, state), {}, {
          assets: _objectSpread(_objectSpread({}, state.assets), {}, {
            [_asset._id]: {
              isLoading: false,
              lastLoadedAt: _now4,
              data: _asset
            }
          })
        });
      }

    case REMOVE_ASSET_QUEUE:
      {
        var {
          assetId: _assetId5
        } = action.payload; // If assetId already in queue, do nothing

        if (_lodash.default.some(state.assets, asset => asset.id === _assetId5)) {
          return state;
        }

        return _objectSpread(_objectSpread({}, state), {}, {
          assets: _objectSpread(_objectSpread({}, state.assets), {}, {
            [_assetId5]: _objectSpread(_objectSpread({}, state.assets[_assetId5]), {}, {
              isRemoving: false,
              isRemoved: false,
              removalError: null
            })
          })
        });
      }

    case REMOVE_ASSET_REQUEST:
      {
        var {
          assetId: _assetId6
        } = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          assets: _objectSpread(_objectSpread({}, state.assets), {}, {
            [_assetId6]: _objectSpread(_objectSpread({}, state.assets[_assetId6]), {}, {
              isRemoving: true,
              isRemoved: false,
              removalError: null
            })
          })
        });
      }

    case REMOVE_ASSET_SUCCESS:
      {
        var {
          assetId: _assetId7
        } = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          assets: _objectSpread(_objectSpread({}, state.assets), {}, {
            [_assetId7]: _objectSpread(_objectSpread({}, state.assets[_assetId7]), {}, {
              isRemoving: false,
              isRemoved: true,
              removalError: null
            })
          })
        });
      }

    case CANCEL_ASSET_REMOVAL:
      {
        var updated = _lodash.default.cloneDeep(state.assets); // Cancel removal only for assets haven't started been removed yet
        // Because, if the request has been sent, we can't cancel it


        _lodash.default.keys(updated).forEach(assetId => {
          if (!updated[assetId].isRemoved && !updated[assetId].isRemoving) {
            updated[assetId] = _objectSpread(_objectSpread({}, updated[assetId]), {}, {
              removalError: 'cancelled'
            });
          }

          if (updated[assetId].isRemoving) {
            updated[assetId] = _objectSpread(_objectSpread({}, updated[assetId]), {}, {
              removalError: null,
              isRemoved: true,
              isRemoving: false
            });
          }
        });

        return _objectSpread(_objectSpread({}, state), {}, {
          assets: updated
        });
      }

    case REMOVE_ASSET_FAILURE:
      {
        var {
          assetId: _assetId8
        } = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          assets: _objectSpread(_objectSpread({}, state.assets), {}, {
            [_assetId8]: _objectSpread(_objectSpread({}, state.assets[_assetId8]), {}, {
              isRemoving: false,
              isRemoved: false,
              removalError: 'cancelled'
            })
          })
        });
      }

    default:
      return state;
  }
}

var actions = {
  getProjectAssets: (0, _reduxActions.createAction)(FETCH_PROJECT_ASSETS),
  getSimilarAssets: (0, _reduxActions.createAction)(FETCH_SIMILAR_ASSETS),
  getAssetById: (0, _reduxActions.createAction)(FETCH_ASSET),
  getAssetsById: (0, _reduxActions.createAction)(FETCH_ASSETS),
  getRelatedAssets: (0, _reduxActions.createAction)(FETCH_RELATED_ASSETS),
  updateAsset: (0, _reduxActions.createAction)(UPDATE_ASSET),
  removeAsset: (0, _reduxActions.createAction)(REMOVE_ASSET),
  cancelAssetRemoval: (0, _reduxActions.createAction)(CANCEL_ASSET_REMOVAL)
};
/**
 * Actions that should only be invoked internally
 */

exports.actions = actions;
var internalActions = {
  getProjectAssetsRequest: (0, _reduxActions.createAction)(FETCH_PROJECT_ASSETS_REQUEST),
  getProjectAssetsSuccess: (0, _reduxActions.createAction)(FETCH_PROJECT_ASSETS_SUCCESS),
  getProjectAssetsFailure: (0, _reduxActions.createAction)(FETCH_PROJECT_ASSETS_FAILURE),
  getSimilarAssetsRequest: (0, _reduxActions.createAction)(FETCH_SIMILAR_ASSETS_REQUEST),
  getSimilarAssetsSuccess: (0, _reduxActions.createAction)(FETCH_SIMILAR_ASSETS_SUCCESS),
  getSimilarAssetsFailure: (0, _reduxActions.createAction)(FETCH_SIMILAR_ASSETS_FAILURE),
  getAssetByIdRequest: (0, _reduxActions.createAction)(FETCH_ASSET_REQUEST),
  getAssetByIdSuccess: (0, _reduxActions.createAction)(FETCH_ASSET_SUCCESS),
  getAssetByIdFailure: (0, _reduxActions.createAction)(FETCH_ASSET_FAILURE),
  getAssetsByIdRequest: (0, _reduxActions.createAction)(FETCH_ASSETS_REQUEST),
  getAssetsByIdSuccess: (0, _reduxActions.createAction)(FETCH_ASSETS_SUCCESS),
  getAssetsByIdFailure: (0, _reduxActions.createAction)(FETCH_ASSETS_FAILURE),
  getRelatedAssetsRequest: (0, _reduxActions.createAction)(FETCH_RELATED_ASSETS_REQUEST),
  getRelatedAssetsSuccess: (0, _reduxActions.createAction)(FETCH_RELATED_ASSETS_SUCCESS),
  getRelatedAssetsFailure: (0, _reduxActions.createAction)(FETCH_RELATED_ASSETS_FAILURE),
  removeAssetQueue: (0, _reduxActions.createAction)(REMOVE_ASSET_QUEUE),
  removeAssetRequest: (0, _reduxActions.createAction)(REMOVE_ASSET_REQUEST),
  removeAssetSuccess: (0, _reduxActions.createAction)(REMOVE_ASSET_SUCCESS),
  removeAssetFailure: (0, _reduxActions.createAction)(REMOVE_ASSET_FAILURE),
  cancelAssetRemoval: (0, _reduxActions.createAction)(CANCEL_ASSET_REMOVAL)
};
exports.internalActions = internalActions;
var selectors = {
  getPageError: createSelector(getPageError),
  areProjectAssetsLoading: createSelector(areProjectAssetsLoading),
  areProjectAssetsLoaded: createSelector(areProjectAssetsLoaded),
  getProjectAssetsLastLoadedAt: createSelector(getProjectAssetsLastLoadedAt),
  getProjectAssets: createSelector(getProjectAssets),
  getSimilarAssets: createSelector(getSimilarAssets),
  isSimilarAssetsLoading: createSelector(isSimilarAssetsLoading),
  isSimilarAssetsLoaded: createSelector(isSimilarAssetsLoaded),
  getSimilarAssetsError: createSelector(getSimilarAssetsError),
  isAssetLoading: createSelector(isAssetLoading),
  isAssetLoaded: createSelector(isAssetLoaded),
  isExtendedAssetLoaded: createSelector(isExtendedAssetLoaded),
  getAssetLastLoadedAt: createSelector(getAssetLastLoadedAt),
  getAssetById: createSelector(getAssetById),
  getAssetsById: createSelector(getAssetsById),
  getRelatedAssets: createSelector(getRelatedAssets),
  isRelatedAssetsLoading: createSelector(isRelatedAssetsLoading),
  isRelatedAssetsLoaded: createSelector(isRelatedAssetsLoaded),
  isRelatedAssetsFailed: createSelector(isRelatedAssetsFailed),
  getRelatedAssetsError: createSelector(getRelatedAssetsError),
  getAssetsRemovingStatus: createSelector(getAssetsRemovingStatus)
};
exports.selectors = selectors;

function areProjectAssetsLoading(state, projectId) {
  var projectContainer = state.assetsByProject[projectId];

  if (!projectContainer) {
    return false;
  }

  return projectContainer.isLoading;
}

function areProjectAssetsLoaded(state, projectId) {
  var projectContainer = state.assetsByProject[projectId];

  if (!projectContainer) {
    return false;
  }

  return !!projectContainer.lastLoadedAt;
}

function getProjectAssetsLastLoadedAt(state, projectId) {
  var projectContainer = state.assetsByProject[projectId];

  if (!projectContainer) {
    return null;
  }

  return projectContainer.lastLoadedAt;
}

function getProjectAssets(state, projectId) {
  var projectContainer = state.assetsByProject[projectId];

  if (!projectContainer) {
    return [];
  }

  return _lodash.default.map(projectContainer.assets, assetId => state.assets[assetId].data);
}

function getSimilarAssets(state, type) {
  var typeContainer = state.similarAssets[type];

  if (!typeContainer) {
    return [];
  }

  return _lodash.default.map(typeContainer.assets, assetId => {
    var _state$assets$assetId;

    return (_state$assets$assetId = state.assets[assetId]) === null || _state$assets$assetId === void 0 ? void 0 : _state$assets$assetId.data;
  });
}

function isSimilarAssetsLoading(state, type) {
  var typeContainer = state.similarAssets[type];

  if (!typeContainer) {
    return false;
  }

  return typeContainer.isLoading;
}

function isSimilarAssetsLoaded(state, type) {
  var typeContainer = state.similarAssets[type];

  if (!typeContainer) {
    return false;
  }

  return !!typeContainer.lastLoadedAt;
}

function getSimilarAssetsError(state, type) {
  var typeContainer = state.similarAssets[type];

  if (!typeContainer) {
    return {};
  }

  return false;
}

function isAssetLoading(state, assetId) {
  var assetContainer = state.assets[assetId];

  if (!assetContainer) {
    return false;
  }

  return assetContainer.isLoading;
}

function isAssetLoaded(state, assetId) {
  var assetContainer = state.assets[assetId];

  if (!assetContainer) {
    return false;
  }

  return !!assetContainer.lastLoadedAt;
}

function isExtendedAssetLoaded(state, assetId) {
  return isAssetLoaded(state, assetId) && !!state.assets[assetId].lastLoadedExtendedAt;
}

function getAssetLastLoadedAt(state, assetId) {
  var assetContainer = state.assets[assetId];

  if (!assetContainer) {
    return null;
  }

  return assetContainer.lastLoadedAt;
}

function getAssetById(state, assetId) {
  var assetContainer = state.assets[assetId];

  if (!assetContainer) {
    return null;
  }

  return assetContainer.data;
}

function getRelatedAssets(state, assetId) {
  var _assetContainer$relat;

  var assetContainer = state.assets[assetId];

  if (!assetContainer) {
    return [];
  }

  return ((_assetContainer$relat = assetContainer.related) === null || _assetContainer$relat === void 0 ? void 0 : _assetContainer$relat.assets) || [];
}

function isRelatedAssetsLoaded(state, assetId) {
  var _assetContainer$relat2;

  var assetContainer = state.assets[assetId];

  if (!assetContainer) {
    return false;
  }

  return (_assetContainer$relat2 = assetContainer.related) === null || _assetContainer$relat2 === void 0 ? void 0 : _assetContainer$relat2.isLoaded;
}

function isRelatedAssetsLoading(state, assetId) {
  var _assetContainer$relat3;

  var assetContainer = state.assets[assetId];

  if (!assetContainer) {
    return false;
  }

  return (_assetContainer$relat3 = assetContainer.related) === null || _assetContainer$relat3 === void 0 ? void 0 : _assetContainer$relat3.isLoading;
}

function isRelatedAssetsFailed(state, assetId) {
  var _assetContainer$relat4;

  var assetContainer = state.assets[assetId];

  if (!assetContainer) {
    return false;
  }

  return (_assetContainer$relat4 = assetContainer.related) === null || _assetContainer$relat4 === void 0 ? void 0 : _assetContainer$relat4.isFailed;
}

function getRelatedAssetsError(state, assetId) {
  var _assetContainer$relat5;

  var assetContainer = state.assets[assetId];

  if (!assetContainer) {
    return null;
  }

  return (_assetContainer$relat5 = assetContainer.related) === null || _assetContainer$relat5 === void 0 ? void 0 : _assetContainer$relat5.error;
}

function getAssetsById(state, assetIds) {
  return assetIds && _lodash.default.map(assetIds, id => getAssetById(state, id));
}

function getPageError(state) {
  return state.error;
}

function getAssetsRemovingStatus(state, assets) {
  if (!assets.length) {
    return false;
  }

  assets = assets.map(asset => {
    var assetContainer = state.assets[asset.id || asset._id];
    return _objectSpread(_objectSpread({}, asset), {}, {
      id: asset.id,
      isRemoving: assetContainer ? assetContainer.isRemoving : false,
      isRemoved: assetContainer ? assetContainer.isRemoved : false,
      removalError: assetContainer ? assetContainer.removalError : null,
      name: asset.name
    });
  });
  return assets;
}
/**
 * Helper method that grabs our specific part of the global store, then passes it into the given selector.
 * This makes it so that each selector doesn't have to grab the sub-state itself, reducing code duplication.
 */


function createSelector(selector) {
  return function selectorWrapper(state) {
    var assetsStore = state[STORE_NAME];

    for (var _len = arguments.length, rest = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      rest[_key - 1] = arguments[_key];
    }

    return selector(assetsStore, ...rest);
  };
}

function* watchAssets() {
  yield (0, _effects.takeLatest)(FETCH_PROJECT_ASSETS, fetchProjectAssets);
  yield (0, _effects.takeLatest)(FETCH_SIMILAR_ASSETS, fetchSimilarAssets);
  yield (0, _effects.takeEvery)(FETCH_ASSET, fetchAsset);
  yield (0, _effects.takeEvery)(FETCH_ASSETS, fetchAssets);
  yield (0, _effects.takeEvery)(FETCH_RELATED_ASSETS, fetchRelatedAssets);
  yield (0, _effects.takeEvery)(REMOVE_ASSET, removeAsset);
}