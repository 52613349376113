"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.actions = exports.UPDATE_ORDER = exports.STORE_NAME = exports.RESET_STORES = exports.QUEUE_UPDATE = exports.QUEUE_REMOVE = exports.QUEUE_CLEAR = exports.QUEUE_ADD = exports.PLACE_ORDER_UPLOAD_FILES_UPDATE = exports.PLACE_ORDER_UPLOAD_FILES_RETRY = exports.PLACE_ORDER_UPLOAD_FILES_DONE = exports.PLACE_ORDER_UPLOAD_FILES_CANCEL = exports.PLACE_ORDER_UPLOAD_FILES = exports.PLACE_ORDER_TRANSLATIONS_START = exports.PLACE_ORDER_TRANSLATIONS_RETRY = exports.PLACE_ORDER_TRANSLATIONS_FAIL = exports.PLACE_ORDER_TRANSLATIONS_DONE = exports.PLACE_ORDER_TRANSLATIONS_CANCEL = exports.PLACE_ORDER_SUCCESS = exports.PLACE_ORDER_SUBMIT_WORK_REQUEST = exports.PLACE_ORDER_FAILURE = exports.PLACE_ORDER_COMPLETE = exports.PLACE_ORDER_CANCEL = exports.PLACE_ORDER = exports.LOAD_PAGE_SUCCESS = exports.LOAD_PAGE_REQUEST = exports.LOAD_PAGE_FAILURE = exports.LOAD_PAGE = exports.INITIAL_STATE = exports.ESTIMATE_ORDER_SUCCESS = exports.ESTIMATE_ORDER_REQUEST = exports.ESTIMATE_ORDER_FAILURE = exports.ESTIMATE_ORDER = void 0;
exports.reducer = reducer;
exports.selectors = void 0;
exports.watchWorkRequestQueue = watchWorkRequestQueue;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _lodash = _interopRequireDefault(require("lodash"));

var _lodash2 = _interopRequireDefault(require("lodash.get"));

var _lodash3 = _interopRequireDefault(require("lodash.chunk"));

var _lodash4 = _interopRequireDefault(require("lodash.iserror"));

var _effects = require("redux-saga/effects");

var _reduxSaga = require("redux-saga");

var _reduxActions = require("redux-actions");

var _v = _interopRequireDefault(require("uuid/v4"));

var _common = require("./common");

var _workRequestService = _interopRequireDefault(require("../modules/services/work-request-service"));

var _workRequestOrderService = _interopRequireDefault(require("../modules/services/work-request-order-service"));

var _uploadService = _interopRequireDefault(require("../modules/services/upload-service"));

var _idService = _interopRequireDefault(require("../modules/services/id-service"));

var _assets = require("./assets");

var _assetTypes = require("./asset-types");

var _projects = require("./projects");

var _languages = require("./languages");

var _territories = require("./territories");

var _workRequests = require("./work-requests");

var _translationService = _interopRequireDefault(require("../modules/services/translation-service"));

var _routeService = _interopRequireDefault(require("~/app/3x/modules/services/route-service"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var STORE_NAME = 'workRequestQueueStore';
exports.STORE_NAME = STORE_NAME;
var RESET_STORES = 'session.reset-stores'; // URLs limited to just over 2000 characters. IDs are 25 characters long. That means we can query about 80 ids at a time.

exports.RESET_STORES = RESET_STORES;
var CHUNK_SIZE = 80;

function* loadPageData() {
  try {
    var assetIDs = yield (0, _effects.select)(selectors.getQueue);

    if (!assetIDs.length) {
      return;
    }

    yield (0, _effects.put)(internalActions.loadPageRequest()); // Fetch all languages and territories

    var languagesTask = yield (0, _effects.fork)(_languages.fetchLanguages, _languages.actions.getLanguages());
    var territoriesTask = yield (0, _effects.fork)(_territories.fetchTerritories, _territories.actions.getTerritories()); // Split asset ids into chunks (to stay within url length limits)

    var chunkedAssetIDs = (0, _lodash3.default)(assetIDs, CHUNK_SIZE); // Fetch all asset chunks in parallel

    var assetsTasks = [];
    var options = {
      withExtended: true
    };

    for (var assetIds of chunkedAssetIDs) {
      var assetTask = yield (0, _effects.fork)(_assets.fetchAssets, _assets.actions.getAssetsById({
        assetIds,
        options
      }));
      assetsTasks.push(assetTask);
    } // See if any of the chunk requests failed, if so, total wash and error out


    var chunkedAssets = yield (0, _effects.join)(...assetsTasks);
    var badAssets = [];
    chunkedAssets.forEach(asset => {
      if ((0, _lodash4.default)(asset)) {
        throw asset;
      }

      if (_lodash.default.isEmpty(asset)) {
        badAssets.push(asset);

        if (chunkedAssets.length === 1) {
          throw new Error('Asset Not Found');
        }
      }
    });

    if (badAssets.length) {
      var newQueue = chunkedAssets.filter(o => !badAssets.includes(o));
      yield (0, _effects.call)(actions.clearQueue);
      yield (0, _effects.put)({
        type: QUEUE_UPDATE,
        payload: newQueue.map(e => e._id)
      }); // refresh to update url with new queue

      _routeService.default.navigateTo('work-request-queue');
    } // Get a list of unique projects for all the assets in queue


    var assets = _lodash.default.flatten(chunkedAssets).filter(asset => !badAssets.includes(asset));

    var projectIDs = _lodash.default.reduce(assets, (result, asset) => {
      result.add(asset.project);
      return result;
    }, new Set()); // Split project ids into chunks ( to stay within url length limits)


    var chunkedProjectIDs = (0, _lodash3.default)(Array.from(projectIDs), CHUNK_SIZE); // Fetch all project chunks in parallel

    var projectsTasks = [];

    for (var _projectIDs of chunkedProjectIDs) {
      var projectTask = yield (0, _effects.fork)(_projects.fetchProjectsById, _projects.actions.fetchProjectsById(_projectIDs));
      projectsTasks.push(projectTask);
    } // See if any of the chunk requests failed, if so, total wash and error out


    var chunkedProjects = [];

    if (projectsTasks.length > 0) {
      chunkedProjects = yield (0, _effects.join)(...projectsTasks);

      for (var projects of chunkedProjects) {
        if ((0, _lodash4.default)(projects)) {
          throw projects;
        }
      }
    } // Wait and check if languages or territores failed to laod


    var languages = yield (0, _effects.join)(languagesTask);
    var territories = yield (0, _effects.join)(territoriesTask);

    if ((0, _lodash4.default)(languages) || (0, _lodash4.default)(territories)) {
      throw new Error('Failed to load language and territory data');
    } // Trigger an initial estimate but don't worry about the response for load success


    yield (0, _effects.call)(estimateOrder);
    yield (0, _effects.put)(internalActions.loadPageSuccess());
  } catch (e) {
    var err = (0, _common.handleError)(e);
    yield (0, _effects.put)(internalActions.loadPageFailure(err));
    return err;
  }
}

function* estimateOrder() {
  var assetId;

  try {
    var order = yield (0, _effects.select)(selectors.getCurrentWorkRequestOrder);

    if (!order) {
      throw new Error('Cannot place order. Order not found');
    }

    assetId = yield (0, _effects.select)(selectors.getCurrentWorkRequestOrderAssetID);
    yield (0, _effects.put)(internalActions.estimateOrderRequest(assetId));
    var asset = yield (0, _effects.select)(s => _assets.selectors.getAssetById(s, assetId));

    if (!asset) {
      throw new Error('Cannot place order, Asset not found');
    }

    var assetType = yield (0, _effects.select)(s => _assetTypes.selectors.getAssetType(s, asset === null || asset === void 0 ? void 0 : asset.type)); // Asset type not guarenteed to be loaded

    if (!assetType) {
      var assetTypes = yield (0, _effects.call)(_assetTypes.fetchAssetTypes, _assetTypes.actions.getAssetTypes());

      if ((0, _lodash4.default)(assetTypes)) {
        throw new Error('Failed to place order. Could not query asset types');
      }

      assetType = yield (0, _effects.select)(s => _assetTypes.selectors.getAssetType(s, asset === null || asset === void 0 ? void 0 : asset.type));
    }

    var estimate = yield _workRequestOrderService.default.getCostEstimate(order, asset, assetType);

    if ((0, _lodash4.default)(estimate)) {
      throw estimate;
    }

    yield (0, _effects.put)(internalActions.estimateOrderSuccess({
      assetId,
      estimate
    }));
    return estimate;
  } catch (e) {
    var err = (0, _common.handleError)(e);
    yield (0, _effects.put)(internalActions.estimateOrderFailure({
      assetId,
      error: err
    }));
    return err;
  }
}

function* placeOrder() {
  try {
    yield* function* () {
      yield (0, _effects.put)(internalActions.placeOrderSubmitWorkRequest());
      var order = yield (0, _effects.select)(selectors.getCurrentWorkRequestOrder);

      if (!order) {
        throw new Error('Cannot place order. Order not found');
      } // The asset gets a fresh load when the queue is loaded so we can just use a selector
      // and grab the recently cached copy.


      var assetId = yield (0, _effects.select)(selectors.getCurrentWorkRequestOrderAssetID);
      var asset = yield (0, _effects.select)(s => _assets.selectors.getAssetById(s, assetId));
      var assetType = yield (0, _effects.select)(s => _assetTypes.selectors.getAssetType(s, asset.type));

      var needsTranslation = _workRequestOrderService.default.doesOrderNeedTranslation(order); // Asset type not guarenteed to be loaded


      if (!assetType) {
        var assetTypes = yield (0, _effects.call)(_assetTypes.fetchAssetTypes, _assetTypes.actions.getAssetTypes());

        if ((0, _lodash4.default)(assetTypes)) {
          throw new Error('Failed to place order. Could not query asset types');
        }

        assetType = yield (0, _effects.select)(s => _assetTypes.selectors.getAssetType(s, asset.type));
      }

      var transformedOrder = _workRequestOrderService.default.toWorkRequest(order, asset, assetType);

      var workRequest = yield _workRequestService.default.create(transformedOrder); // Update local work request store

      yield (0, _effects.put)(_workRequests.actions.manualUpdateWorkRequest(workRequest)); // Generate upload requests for each tag file

      var tagFiles = _lodash.default.map(order.tags.tags, (tag, tagIndex) => {
        return _lodash.default.map(tag.files, (file, fileIndex) => {
          // Generate a unique id for tracking purposes
          return {
            id: (0, _v.default)(),
            tagIndex,
            fileIndex,
            tag,
            file,
            workRequestId: workRequest._id
          };
        });
      });

      tagFiles = _lodash.default.flatten(tagFiles); // Generate upload requests for each file

      var files = _lodash.default.map(order.additionalInfo.files, (file, fileIndex) => {
        return {
          id: (0, _v.default)(),
          fileIndex,
          file,
          workRequestId: workRequest._id
        };
      });

      if (tagFiles.length || files.length) {
        var allFiles = [...tagFiles, ...files]; // Update store with initial progress data

        yield (0, _effects.put)(internalActions.placeOrderUploadFiles(allFiles)); // Repeat until all uploads are successful (retry enabled)

        while (tagFiles.length || files.length) {
          var uploadTasks = []; // Fork an upload task for each tag file

          for (var tagFile of tagFiles) {
            var task = yield (0, _effects.fork)(uploadFile, tagFile);
            uploadTasks.push(task);
          } // Fork an upload task for each file


          for (var file of files) {
            var _task = yield (0, _effects.fork)(uploadFile, file);

            uploadTasks.push(_task);
          }

          var uploadResults = yield (0, _effects.join)(uploadTasks); // Split upload results back into tag files and regular files

          var tagFileUploadResults = _lodash.default.filter(uploadResults, _ref => {
            var {
              uploadRequest
            } = _ref;
            return uploadRequest.hasOwnProperty('tagIndex');
          });

          var fileUploadResults = _lodash.default.reject(uploadResults, _ref2 => {
            var {
              uploadRequest
            } = _ref2;
            return uploadRequest.hasOwnProperty('tagIndex');
          }); // Update work request


          tagFiles = _lodash.default.reduce(tagFileUploadResults, (result, tagFileUploadResult) => {
            // Requeue failed uploads
            if ((0, _lodash4.default)(tagFileUploadResult.result)) {
              result.push(tagFileUploadResult.uploadRequest);
              return result;
            } // Update the work request tag file paths


            var {
              tagIndex,
              fileIndex
            } = tagFileUploadResult.uploadRequest;
            workRequest.tags[tagIndex].files[fileIndex] = tagFileUploadResult.result;
            return result;
          }, []);
          files = _lodash.default.reduce(fileUploadResults, (result, fileUploadResult) => {
            // Requeue failed uploads
            if ((0, _lodash4.default)(fileUploadResult.result)) {
              result.push(fileUploadResult.uploadRequest);
              return result;
            } // Update the work request file paths


            var {
              fileIndex
            } = fileUploadResult.uploadRequest;
            workRequest.files[0].files[fileIndex] = fileUploadResult.result;
            return result;
          }, []); // TODO: Handle possible failure
          // Update our work request (locally and remotely). It may be used again if
          // a retry is needed (i.e. a file failed to upload).
          // eslint-disable-next-line require-atomic-updates

          workRequest = yield _workRequestService.default.update(workRequest._id, {
            files: workRequest.files,
            tags: workRequest.tags
          });
          yield (0, _effects.put)(internalActions.placeOrderUploadFilesDone()); // If there were any upload failures, wait for user input (retry/cancel failed uploads)

          if (tagFiles.length || files.length) {
            var retryOrCancel = yield (0, _effects.take)([PLACE_ORDER_UPLOAD_FILES_RETRY, PLACE_ORDER_UPLOAD_FILES_CANCEL]); // If the user selected cancel, otherwise let the loop continue for a retry

            if (retryOrCancel.type === PLACE_ORDER_UPLOAD_FILES_CANCEL) {
              tagFiles.length = 0;
              files.length = 0; // We could remove the empty object from the work request tag's files, however it doesn't seem to affect anything
              // so we'll skip the extra network request (and potential error). In the future we should not put placeholders for tag files
            }
          }
        }
      }

      if (needsTranslation) {
        yield (0, _effects.put)(internalActions.placeOrderTranslationsStart()); // check if the order is a graphics order to update the workRequest.graphicsTranslation instead of workRequest.translation

        var graphicsTranslationId = (0, _common.isGfxOrder)(workRequest) ? workRequest.graphicsTranslation._id : false;
        var translationId = (0, _common.isDialogueOrder)(workRequest) ? workRequest.translation._id : false;
        var translationUpdate = {};
        var graphicsTranslationUpdate = {}; // Current user is the default translator. Update that if a translationProvider is provided.

        if (order.localization.translationProvider) {
          if (translationId) {
            translationUpdate.translator = _idService.default.convertFromId(order.localization.translationProvider);
          }

          if (graphicsTranslationId) {
            graphicsTranslationUpdate.translator = _idService.default.convertFromId(order.localization.translationProvider);
          }
        } // If translator is 3rd party subtitling vendor, does translation need approval?


        var isThirdPartyTranslator = order.localization.translationProviderType === 'email';

        if (isThirdPartyTranslator) {
          if (translationId) {
            translationUpdate.approval = order.localization.needsApproval;
          }

          if (graphicsTranslationId) {
            graphicsTranslationUpdate.approval = order.localization.needsApproval;
          }
        } // If translations are provided via a file, start the file upload phase


        var needsFileUpload = order.localization.translationProviderType === 'upload';

        while (needsFileUpload) {
          if (translationId) {
            try {
              var uploadResult = yield (0, _effects.call)(uploadTranslations, translationId, order.localization.translationFile);

              if ((0, _lodash4.default)(uploadResult.result)) {
                throw uploadResult.result;
              }

              translationUpdate.file = uploadResult.result;
              translationUpdate.status = 'submitted';
              needsFileUpload = false;
            } catch (e) {
              yield (0, _effects.put)(internalActions.placeOrderTranslationsFail());

              var _retryOrCancel = yield (0, _effects.take)([PLACE_ORDER_TRANSLATIONS_RETRY, PLACE_ORDER_TRANSLATIONS_CANCEL]); // If the user selected cancel, otherwise let the loop continue for a retry


              if (_retryOrCancel.type === PLACE_ORDER_TRANSLATIONS_CANCEL) {
                needsFileUpload = false;
                delete translationUpdate.file;
                delete translationUpdate.status;
              }
            }
          }

          if (graphicsTranslationId) {
            try {
              var _uploadResult = yield (0, _effects.call)(uploadTranslations, graphicsTranslationId, order.localization.translationFile);

              if ((0, _lodash4.default)(_uploadResult.result)) {
                throw _uploadResult.result;
              }

              graphicsTranslationUpdate.file = _uploadResult.result;
              graphicsTranslationUpdate.status = 'submitted';
              needsFileUpload = false;
            } catch (e) {
              yield (0, _effects.put)(internalActions.placeOrderTranslationsFail());

              var _retryOrCancel2 = yield (0, _effects.take)([PLACE_ORDER_TRANSLATIONS_RETRY, PLACE_ORDER_TRANSLATIONS_CANCEL]); // If the user selected cancel, otherwise let the loop continue for a retry


              if (_retryOrCancel2.type === PLACE_ORDER_TRANSLATIONS_CANCEL) {
                needsFileUpload = false;
                delete graphicsTranslationUpdate.file;
                delete graphicsTranslationUpdate.status;
              }
            }
          }
        }

        if (!_lodash.default.isEmpty(translationUpdate) || !_lodash.default.isEmpty(graphicsTranslationUpdate)) {
          if (translationId) {
            yield (0, _effects.call)(_translationService.default.updateTranslation, translationId, translationUpdate);
          }

          if (graphicsTranslationId) {
            yield (0, _effects.call)(_translationService.default.updateTranslation, graphicsTranslationId, graphicsTranslationUpdate);
          }
        }
      }

      yield (0, _effects.put)(internalActions.placeOrderSuccess(workRequest._id));
    }();
  } catch (e) {
    var err = (0, _common.handleError)(e);
    yield (0, _effects.put)(internalActions.placeOrderFailure(err));
    return err;
  }
}

function* uploadTranslations(translationId, translationFile) {
  var uploadRequest = {
    translationId,
    file: translationFile
  }; // Create an event channel so that our async updates can dispatch update events.
  // The inner upload method always resolves, that way parallel tasks are not

  var resultPromise;
  var channel = (0, _reduxSaga.eventChannel)(emit => {
    var emitUpdate = update => {
      emit(update);
    };

    resultPromise = _uploadService.default.uploadTranslation(uploadRequest.file, uploadRequest, emitUpdate).then(result => {
      return {
        uploadRequest,
        result
      };
    }).catch(e => {
      var err = (0, _lodash4.default)(e) ? e : new Error(e); // Do not emit Error directly as it will crash the channel

      emit({
        error: err
      });
      return {
        uploadRequest,
        result: err
      };
    }).finally(() => {
      // End this channel
      emit(_reduxSaga.END);
    });
    return _lodash.default.noop;
  }); // We have to fork the update dispatcher because the when a channel ends, the generator
  // function is ended at the `take` and no additional code is run. We don't want this
  // generator function to end because we need to be able to return the promise to the
  // calling function. Can't do that when a generator function just ends.

  yield (0, _effects.fork)(_common.updateDispatcher, channel, uploadRequest);
  var result = yield resultPromise;
  return result;
}

function* uploadFile(uploadRequest) {
  var uploadMethod = uploadRequest.hasOwnProperty('tagIndex') ? _uploadService.default.uploadTagFile : _uploadService.default.uploadFile; // Create an event channel so that our async updates can dispatch update events.
  // The inner upload method always resolves, that way parallel tasks are not

  var resultPromise;
  var channel = (0, _reduxSaga.eventChannel)(emit => {
    var emitUpdate = update => {
      emit(update);
    };

    resultPromise = uploadMethod(uploadRequest.file, uploadRequest, emitUpdate).then(result => {
      return {
        uploadRequest,
        result
      };
    }).catch(e => {
      var err = (0, _lodash4.default)(e) ? e : new Error(e); // Do not emit Error directly as it will crash the channel

      emit({
        error: err
      });
      return {
        uploadRequest,
        result: err
      };
    }).finally(() => {
      // End this channel
      emit(_reduxSaga.END);
    });
    return _lodash.default.noop;
  }); // We have to fork the update dispatcher because the when a channel ends, the generator
  // function is ended at the `take` and no additional code is run. We don't want this
  // generator function to end because we need to be able to return the promise to the
  // calling function. Can't do that when a generator function just ends.

  yield (0, _effects.fork)(_common.updateDispatcher, channel, uploadRequest, update => {
    return internalActions.placeOrderUploadFilesUpdate(update);
  });
  var result = yield resultPromise;
  return result;
}

var LOAD_PAGE = 'work-request-queue.load-page';
exports.LOAD_PAGE = LOAD_PAGE;
var LOAD_PAGE_REQUEST = 'work-request-queue.load-page.request';
exports.LOAD_PAGE_REQUEST = LOAD_PAGE_REQUEST;
var LOAD_PAGE_SUCCESS = 'work-request-queue.load-page.success';
exports.LOAD_PAGE_SUCCESS = LOAD_PAGE_SUCCESS;
var LOAD_PAGE_FAILURE = 'work-request-queue.load-page.failure';
exports.LOAD_PAGE_FAILURE = LOAD_PAGE_FAILURE;
var ESTIMATE_ORDER = 'work-request-queue.estimate-order';
exports.ESTIMATE_ORDER = ESTIMATE_ORDER;
var ESTIMATE_ORDER_REQUEST = 'work-request-queue.estimate-order.request';
exports.ESTIMATE_ORDER_REQUEST = ESTIMATE_ORDER_REQUEST;
var ESTIMATE_ORDER_SUCCESS = 'work-request-queue.estimate-order.success';
exports.ESTIMATE_ORDER_SUCCESS = ESTIMATE_ORDER_SUCCESS;
var ESTIMATE_ORDER_FAILURE = 'work-request-queue.estimate-order.failure';
exports.ESTIMATE_ORDER_FAILURE = ESTIMATE_ORDER_FAILURE;
var PLACE_ORDER = 'work-request-queue.place-order';
exports.PLACE_ORDER = PLACE_ORDER;
var PLACE_ORDER_SUBMIT_WORK_REQUEST = 'work-request-queue.place-order.submit-work-request';
exports.PLACE_ORDER_SUBMIT_WORK_REQUEST = PLACE_ORDER_SUBMIT_WORK_REQUEST;
var PLACE_ORDER_UPLOAD_FILES = 'work-request-queue.place-order.upload-files';
exports.PLACE_ORDER_UPLOAD_FILES = PLACE_ORDER_UPLOAD_FILES;
var PLACE_ORDER_UPLOAD_FILES_UPDATE = 'work-request-queue.place-order.upload-files-update';
exports.PLACE_ORDER_UPLOAD_FILES_UPDATE = PLACE_ORDER_UPLOAD_FILES_UPDATE;
var PLACE_ORDER_UPLOAD_FILES_DONE = 'work-request-queue.place-order.upload-files-done';
exports.PLACE_ORDER_UPLOAD_FILES_DONE = PLACE_ORDER_UPLOAD_FILES_DONE;
var PLACE_ORDER_UPLOAD_FILES_CANCEL = 'work-request-queue.place-order.upload-files-cancel';
exports.PLACE_ORDER_UPLOAD_FILES_CANCEL = PLACE_ORDER_UPLOAD_FILES_CANCEL;
var PLACE_ORDER_UPLOAD_FILES_RETRY = 'work-request-queue.place-order.upload-files-retry';
exports.PLACE_ORDER_UPLOAD_FILES_RETRY = PLACE_ORDER_UPLOAD_FILES_RETRY;
var PLACE_ORDER_TRANSLATIONS_START = 'work-request-queue.place-order.translations-start';
exports.PLACE_ORDER_TRANSLATIONS_START = PLACE_ORDER_TRANSLATIONS_START;
var PLACE_ORDER_TRANSLATIONS_FAIL = 'work-request-queue.place-order.translations-fail';
exports.PLACE_ORDER_TRANSLATIONS_FAIL = PLACE_ORDER_TRANSLATIONS_FAIL;
var PLACE_ORDER_TRANSLATIONS_RETRY = 'work-request-queue.place-order.translations-retry';
exports.PLACE_ORDER_TRANSLATIONS_RETRY = PLACE_ORDER_TRANSLATIONS_RETRY;
var PLACE_ORDER_TRANSLATIONS_CANCEL = 'work-request-queue.place-order.translations-cancel';
exports.PLACE_ORDER_TRANSLATIONS_CANCEL = PLACE_ORDER_TRANSLATIONS_CANCEL;
var PLACE_ORDER_TRANSLATIONS_DONE = 'work-request-queue.place-order.translations-done';
exports.PLACE_ORDER_TRANSLATIONS_DONE = PLACE_ORDER_TRANSLATIONS_DONE;
var PLACE_ORDER_SUCCESS = 'work-request-queue.place-order.success';
exports.PLACE_ORDER_SUCCESS = PLACE_ORDER_SUCCESS;
var PLACE_ORDER_COMPLETE = 'work-request-queue.place-order.complete';
exports.PLACE_ORDER_COMPLETE = PLACE_ORDER_COMPLETE;
var PLACE_ORDER_FAILURE = 'work-request-queue.place-order.failure';
exports.PLACE_ORDER_FAILURE = PLACE_ORDER_FAILURE;
var PLACE_ORDER_CANCEL = 'work-request-queue.place-order.cancel';
exports.PLACE_ORDER_CANCEL = PLACE_ORDER_CANCEL;
var QUEUE_ADD = 'work-request-queue.queue.add';
exports.QUEUE_ADD = QUEUE_ADD;
var QUEUE_REMOVE = 'work-request-queue.queue.remove';
exports.QUEUE_REMOVE = QUEUE_REMOVE;
var QUEUE_UPDATE = 'work-request-queue.queue.update';
exports.QUEUE_UPDATE = QUEUE_UPDATE;
var QUEUE_CLEAR = 'work-request-queue.queue.clear';
exports.QUEUE_CLEAR = QUEUE_CLEAR;
var UPDATE_ORDER = 'work-request-queue.order.update';
exports.UPDATE_ORDER = UPDATE_ORDER;
var INITIAL_STATE = {
  isLoading: false,
  loadError: null,
  // isPlacingOrder can be a boolean OR an object
  isPlacingOrder: false,
  currentOrderIndex: -1,
  workRequestOrders: {},
  workRequestOrderEstimates: {},
  workRequestQueue: []
};
exports.INITIAL_STATE = INITIAL_STATE;

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case RESET_STORES:
      return INITIAL_STATE;

    case LOAD_PAGE_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          isLoading: true,
          loadError: null
        });
      }

    case LOAD_PAGE_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          isLoading: false,
          loadError: null
        });
      }

    case LOAD_PAGE_FAILURE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          isLoading: false,
          loadError: action.payload
        });
      }

    case QUEUE_ADD:
      {
        var toAdd = action.payload;
        var workRequestQueue = (0, _common.extendQueue)(state.workRequestQueue, toAdd); // If the work request queue is unchanged and has zero assets, state update is not needed

        if (!workRequestQueue.length) {
          return state;
        }

        var {
          currentOrderIndex,
          workRequestOrders: wrOrders
        } = state;

        if (currentOrderIndex < 0) {
          currentOrderIndex = 0;
          var currentAssetID = workRequestQueue[0];
          wrOrders[currentAssetID] = _workRequestOrderService.default.createBlankOrder();
        }

        return _objectSpread(_objectSpread({}, state), {}, {
          currentOrderIndex,
          workRequestQueue,
          workRequestOrders: wrOrders
        });
      }

    case QUEUE_REMOVE:
      {
        var toRemove = action.payload; // Store the asset id for the current order

        var currentOrderAssetID = state.workRequestQueue[state.currentOrderIndex]; // Remove toRemove asset id(s) from the work request queue

        var _workRequestQueue = (0, _common.filterQueue)(state.workRequestQueue, toRemove); // Update current order index to its new position (may be -1 if current order asset was removed)


        var _currentOrderIndex = _workRequestQueue.indexOf(currentOrderAssetID); // Ensure assetIDs is an array even if only one element


        var assetIDs = toRemove;

        if (!_lodash.default.isArray(assetIDs)) {
          assetIDs = [assetIDs];
        } // Remove any on-going work request orders for removed asset ids


        var workRequestOrders = _lodash.default.omit(state.workRequestOrders, assetIDs);

        var workRequestOrderEstimates = _lodash.default.omit(state.workRequestOrderEstimates, assetIDs); // If the current order asset is among the removed asset ids, remove it and start a new order


        if (_currentOrderIndex < 0 && _workRequestQueue.length) {
          _currentOrderIndex = 0;
          var firstAssetID = _workRequestQueue[0];

          if (!(firstAssetID in workRequestOrders)) {
            var order = _workRequestOrderService.default.createBlankOrder();

            workRequestOrders[firstAssetID] = order;
          }
        }

        return _objectSpread(_objectSpread({}, state), {}, {
          currentOrderIndex: _currentOrderIndex,
          workRequestQueue: _workRequestQueue,
          workRequestOrders,
          workRequestOrderEstimates
        });
      }

    case QUEUE_UPDATE:
      {
        var _workRequestQueue2 = action.payload;
        var {
          currentOrderIndex: _currentOrderIndex2,
          workRequestOrders: _workRequestOrders,
          workRequestOrderEstimates: _workRequestOrderEstimates
        } = state;

        var _toRemove = state.workRequestQueue.filter(id => !_workRequestQueue2.includes(id));

        if (_toRemove.length) {
          var _currentOrderAssetID = state.workRequestQueue[_currentOrderIndex2];
          _currentOrderIndex2 = _workRequestQueue2.indexOf(_currentOrderAssetID);
          _workRequestOrders = _lodash.default.omit(_workRequestOrders, _toRemove);
          _workRequestOrderEstimates = _lodash.default.omit(_workRequestOrderEstimates, _toRemove);
        }

        if (_currentOrderIndex2 < 0 && _workRequestQueue2.length) {
          _currentOrderIndex2 = 0;

          if (!(_workRequestQueue2[0] in _workRequestOrders)) {
            _workRequestOrders[_workRequestQueue2[0]] = _workRequestOrderService.default.createBlankOrder();
          }
        }

        return _objectSpread(_objectSpread({}, state), {}, {
          currentOrderIndex: _currentOrderIndex2,
          workRequestQueue: _workRequestQueue2,
          workRequestOrders: _workRequestOrders,
          workRequestOrderEstimates: _workRequestOrderEstimates
        });
      }

    case QUEUE_CLEAR:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          currentOrderIndex: -1,
          workRequestQueue: [],
          workRequestOrders: {},
          workRequestOrderEstimates: {}
        });
      }

    case UPDATE_ORDER:
      {
        if (state.currentOrderIndex < 0) {
          return state;
        }

        var _order = action.payload;
        var assetID = state.workRequestQueue[state.currentOrderIndex];

        var _workRequestOrders2 = _objectSpread(_objectSpread({}, state.workRequestOrders), {}, {
          [assetID]: _order
        });

        return _objectSpread(_objectSpread({}, state), {}, {
          workRequestOrders: _workRequestOrders2
        });
      }

    case ESTIMATE_ORDER_REQUEST:
      {
        var assetId = action.payload;
        var existingEstimate = (0, _lodash2.default)(state.workRequestOrderEstimates, [assetId, 'estimate'], {});
        return _objectSpread(_objectSpread({}, state), {}, {
          workRequestOrderEstimates: _objectSpread(_objectSpread({}, state.workRequestOrderEstimates), {}, {
            [assetId]: _objectSpread(_objectSpread({
              data: null
            }, existingEstimate), {}, {
              isLoading: true,
              loadError: null
            })
          })
        });
      }

    case ESTIMATE_ORDER_SUCCESS:
      {
        var {
          assetId: _assetId,
          estimate
        } = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          workRequestOrderEstimates: _objectSpread(_objectSpread({}, state.workRequestOrderEstimates), {}, {
            [_assetId]: {
              data: estimate,
              isLoading: false,
              loadError: null
            }
          })
        });
      }

    case ESTIMATE_ORDER_FAILURE:
      {
        var {
          assetId: _assetId2,
          error
        } = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          workRequestOrderEstimates: _objectSpread(_objectSpread({}, state.workRequestOrderEstimates), {}, {
            [_assetId2]: {
              data: null,
              isLoading: false,
              loadError: error
            }
          })
        });
      }

    case PLACE_ORDER_SUBMIT_WORK_REQUEST:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          isPlacingOrder: {
            isSubmittingWorkRequest: true,
            isUploadingFiles: false,
            isUpdatingTranslations: false,
            didFailWorkRequestOrder: false,
            didFailFileUpload: false,
            didFailTranslations: false
          }
        });
      }

    case PLACE_ORDER_UPLOAD_FILES:
      {
        var tagFiles = action.payload;

        var fileUploadProgress = _lodash.default.reduce(tagFiles, (result, tagFile) => {
          result[tagFile.id] = {
            name: tagFile.file.name,
            progress: 0
          };
          return result;
        }, {});

        return _objectSpread(_objectSpread({}, state), {}, {
          isPlacingOrder: _objectSpread(_objectSpread({}, state.isPlacingOrder), {}, {
            isSubmittingWorkRequest: false,
            isUploadingFiles: true,
            isUpdatingTranslations: false,
            fileUploadProgress
          })
        });
      }

    case PLACE_ORDER_UPLOAD_FILES_UPDATE:
      {
        var update = action.payload;

        var _isPlacingOrder = _objectSpread(_objectSpread({}, state.isPlacingOrder), {}, {
          isUploadingFiles: true,
          fileUploadProgress: _objectSpread(_objectSpread({}, state.isPlacingOrder.fileUploadProgress), {}, {
            [update.id]: update
          })
        });

        var didFailFileUpload = _lodash.default.reduce(_isPlacingOrder.fileUploadProgress, (result, entry) => {
          return result || !!entry.error;
        }, false);

        _isPlacingOrder.didFailFileUpload = didFailFileUpload;
        return _objectSpread(_objectSpread({}, state), {}, {
          isPlacingOrder: _isPlacingOrder
        });
      }

    case PLACE_ORDER_UPLOAD_FILES_DONE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          isPlacingOrder: _objectSpread(_objectSpread({}, state.isPlacingOrder), {}, {
            isUploadingFiles: false
          })
        });
      }

    case PLACE_ORDER_TRANSLATIONS_START:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          isPlacingOrder: _objectSpread(_objectSpread({}, state.isPlacingOrder), {}, {
            isSubmittingWorkRequest: false,
            isUploadingFiles: false,
            isUpdatingTranslations: true
          })
        });
      }

    case PLACE_ORDER_TRANSLATIONS_FAIL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          isPlacingOrder: _objectSpread(_objectSpread({}, state.isPlacingOrder), {}, {
            isUpdatingTranslations: false,
            didFailTranslations: true
          })
        });
      }

    case PLACE_ORDER_TRANSLATIONS_RETRY:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          isPlacingOrder: _objectSpread(_objectSpread({}, state.isPlacingOrder), {}, {
            isUpdatingTranslations: true,
            didFailTranslations: false
          })
        });
      }

    case PLACE_ORDER_TRANSLATIONS_CANCEL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          isPlacingOrder: _objectSpread(_objectSpread({}, state.isPlacingOrder), {}, {
            isUpdatingTranslations: false,
            didFailTranslations: false
          })
        });
      }

    case PLACE_ORDER_TRANSLATIONS_DONE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          isPlacingOrder: _objectSpread(_objectSpread({}, state.isPlacingOrder), {}, {
            isUpdatingTranslations: false,
            didFailTranslations: false
          })
        });
      }

    case PLACE_ORDER_SUCCESS:
      {
        var workRequestId = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          isPlacingOrder: {
            isSubmittingWorkRequest: false,
            isUploadingFiles: false,
            isUpdatingTranslations: false,
            didFailWorkRequestOrder: false,
            didFailFileUpload: false,
            didFailTranslations: false,
            workRequestId
          }
        });
      }

    case PLACE_ORDER_COMPLETE:
      {
        var _workRequestQueue3 = [...state.workRequestQueue];
        var _assetId3 = _workRequestQueue3[state.currentOrderIndex]; // Remove from queue

        _workRequestQueue3.splice(state.currentOrderIndex, 1); // Remove order form data


        var _workRequestOrders3 = _objectSpread({}, state.workRequestOrders);

        delete _workRequestOrders3[_assetId3]; // Remove order estimate

        var _workRequestOrderEstimates2 = _objectSpread({}, state.workRequestOrderEstimates);

        delete _workRequestOrderEstimates2[_assetId3]; // Reset current order index

        var _currentOrderIndex3 = _workRequestQueue3.length ? 0 : -1; // If the queue is not empty, make sure an order exists


        if (_workRequestQueue3.length) {
          var nextAssetId = _workRequestQueue3[0];
          _workRequestOrders3[nextAssetId] = _workRequestOrderService.default.createBlankOrder();
        }

        return _objectSpread(_objectSpread({}, state), {}, {
          workRequestQueue: _workRequestQueue3,
          workRequestOrders: _workRequestOrders3,
          workRequestOrderEstimates: _workRequestOrderEstimates2,
          currentOrderIndex: _currentOrderIndex3,
          isPlacingOrder: false
        });
      }

    case PLACE_ORDER_FAILURE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          isPlacingOrder: {
            isSubmittingWorkRequest: false,
            isUploadingFiles: false,
            isUpdatingTranslations: false,
            didFailWorkRequestOrder: true,
            didFailFileUpload: false,
            didFailTranslations: false
          }
        });
      }

    case PLACE_ORDER_CANCEL:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          isPlacingOrder: false
        });
      }

    default:
      return state;
  }
}

var actions = {
  loadPageData: (0, _reduxActions.createAction)(LOAD_PAGE),
  addToQueue: (0, _reduxActions.createAction)(QUEUE_ADD),
  removeFromQueue: (0, _reduxActions.createAction)(QUEUE_REMOVE),
  updateQueue: (0, _reduxActions.createAction)(QUEUE_UPDATE),
  clearQueue: (0, _reduxActions.createAction)(QUEUE_CLEAR),
  updateWorkRequestOrder: (0, _reduxActions.createAction)(UPDATE_ORDER),
  estimateWorkRequestOrder: (0, _reduxActions.createAction)(ESTIMATE_ORDER),
  placeWorkRequestOrder: (0, _reduxActions.createAction)(PLACE_ORDER),
  completePlacedOrder: (0, _reduxActions.createAction)(PLACE_ORDER_COMPLETE),
  cancelPlaceWorkRequestOrder: (0, _reduxActions.createAction)(PLACE_ORDER_CANCEL),
  retryPlaceOrderFileUpload: (0, _reduxActions.createAction)(PLACE_ORDER_UPLOAD_FILES_RETRY),
  cancelPlaceOrderFileUpload: (0, _reduxActions.createAction)(PLACE_ORDER_UPLOAD_FILES_CANCEL),
  retryPlaceOrderTranslations: (0, _reduxActions.createAction)(PLACE_ORDER_TRANSLATIONS_RETRY),
  cancelPlaceOrderTranslations: (0, _reduxActions.createAction)(PLACE_ORDER_TRANSLATIONS_CANCEL)
};
/**
 * Actions that should only be invoked internally
 */

exports.actions = actions;
var internalActions = {
  loadPageRequest: (0, _reduxActions.createAction)(LOAD_PAGE_REQUEST),
  loadPageSuccess: (0, _reduxActions.createAction)(LOAD_PAGE_SUCCESS),
  loadPageFailure: (0, _reduxActions.createAction)(LOAD_PAGE_FAILURE),
  estimateOrderRequest: (0, _reduxActions.createAction)(ESTIMATE_ORDER_REQUEST),
  estimateOrderSuccess: (0, _reduxActions.createAction)(ESTIMATE_ORDER_SUCCESS),
  estimateOrderFailure: (0, _reduxActions.createAction)(ESTIMATE_ORDER_FAILURE),
  placeOrderSubmitWorkRequest: (0, _reduxActions.createAction)(PLACE_ORDER_SUBMIT_WORK_REQUEST),
  placeOrderUploadFiles: (0, _reduxActions.createAction)(PLACE_ORDER_UPLOAD_FILES),
  placeOrderUploadFilesUpdate: (0, _reduxActions.createAction)(PLACE_ORDER_UPLOAD_FILES_UPDATE),
  placeOrderUploadFilesDone: (0, _reduxActions.createAction)(PLACE_ORDER_UPLOAD_FILES_DONE),
  placeOrderTranslationsStart: (0, _reduxActions.createAction)(PLACE_ORDER_TRANSLATIONS_START),
  placeOrderTranslationsFail: (0, _reduxActions.createAction)(PLACE_ORDER_TRANSLATIONS_FAIL),
  placeOrderTranslationsDone: (0, _reduxActions.createAction)(PLACE_ORDER_TRANSLATIONS_DONE),
  placeOrderSuccess: (0, _reduxActions.createAction)(PLACE_ORDER_SUCCESS),
  placeOrderFailure: (0, _reduxActions.createAction)(PLACE_ORDER_FAILURE)
};
var selectors = {
  isReady,
  isLoading: (0, _common.createSelector)(STORE_NAME, isLoading),
  didLoadFail: (0, _common.createSelector)(STORE_NAME, didLoadFail),
  loadError: (0, _common.createSelector)(STORE_NAME, loadError),
  isPlacingOrder: (0, _common.createSelector)(STORE_NAME, isPlacingOrder),
  getQueue: (0, _common.createSelector)(STORE_NAME, getQueue),
  isAssetInQueue: (0, _common.createSelector)(STORE_NAME, isAssetInQueue),
  getCurrentWorkRequestOrderAssetID: (0, _common.createSelector)(STORE_NAME, getCurrentWorkRequestOrderAssetID),
  getCurrentWorkRequestOrder: (0, _common.createSelector)(STORE_NAME, getCurrentWorkRequestOrder),
  getCurrentWorkRequestOrderEstimate: (0, _common.createSelector)(STORE_NAME, getCurrentWorkRequestOrderEstimate),
  isLoadingCurrentOrderCostEstimate: (0, _common.createSelector)(STORE_NAME, isLoadingCurrentOrderCostEstimate)
};
exports.selectors = selectors;

function isReady(state) {
  var queue = getQueue(state[STORE_NAME]);

  if (!queue.length) {
    return true;
  }

  if (!_languages.selectors.isLoaded(state)) {
    return false;
  }

  if (!_territories.selectors.isLoaded(state)) {
    return false;
  } // Make sure all the assets are loaded


  for (var i = 0; i < queue.length; ++i) {
    var assetID = queue[i];

    if (!_assets.selectors.isExtendedAssetLoaded(state, assetID)) {
      return false;
    }

    var asset = _assets.selectors.getAssetById(state, assetID);

    var projectID = asset.project;

    if (!_projects.selectors.isLoaded(state, projectID)) {
      return false;
    }
  }

  return true;
}

function isLoading(state) {
  return state.isLoading;
}

function didLoadFail(state) {
  var _$values, _$values$find;

  return !!(state !== null && state !== void 0 && state.loadError || (_$values = _lodash.default.values(state === null || state === void 0 ? void 0 : state.workRequestOrderEstimates)) !== null && _$values !== void 0 && (_$values$find = _$values.find(e => e === null || e === void 0 ? void 0 : e.loadError)) !== null && _$values$find !== void 0 && _$values$find.loadError);
}

function loadError(state) {
  var _$values2, _$values2$find;

  return (state === null || state === void 0 ? void 0 : state.loadError) || ((_$values2 = _lodash.default.values(state === null || state === void 0 ? void 0 : state.workRequestOrderEstimates)) === null || _$values2 === void 0 ? void 0 : (_$values2$find = _$values2.find(e => e === null || e === void 0 ? void 0 : e.loadError)) === null || _$values2$find === void 0 ? void 0 : _$values2$find.loadError);
}

function isPlacingOrder(state) {
  return state.isPlacingOrder;
}

function getQueue(state) {
  return state.workRequestQueue;
}

function isAssetInQueue(state, assetId) {
  return state.workRequestQueue.indexOf(assetId) > -1;
}

function getCurrentWorkRequestOrderAssetID(state) {
  if (state.currentOrderIndex < 0) {
    return null;
  }

  return state.workRequestQueue[state.currentOrderIndex];
}

function getCurrentWorkRequestOrder(state) {
  if (state.currentOrderIndex < 0) {
    return null;
  }

  var assetId = state.workRequestQueue[state.currentOrderIndex];
  var order = state.workRequestOrders[assetId];
  return order;
}

function getCurrentWorkRequestOrderEstimate(state) {
  if (state.currentOrderIndex < 0) {
    return null;
  }

  var assetId = state.workRequestQueue[state.currentOrderIndex];
  return (0, _lodash2.default)(state.workRequestOrderEstimates, [assetId, 'data'], null);
}

function isLoadingCurrentOrderCostEstimate(state) {
  if (state.currentOrderIndex < 0) {
    return false;
  }

  var assetId = state.workRequestQueue[state.currentOrderIndex];
  return (0, _lodash2.default)(state.workRequestOrderEstimates, [assetId, 'isLoading'], false);
}

function* watchWorkRequestQueue() {
  yield (0, _effects.takeLeading)(LOAD_PAGE, loadPageData);
  yield (0, _effects.takeLeading)(PLACE_ORDER, placeOrder);
  yield (0, _effects.takeLatest)(ESTIMATE_ORDER, estimateOrder);
}