"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.actions = exports.UPDATE_PROJECT = exports.STORE_NAME = exports.RESET_STORES = exports.INITIAL_STATE = exports.FETCH_PROJECT_BY_SLUG_SUCCESS = exports.FETCH_PROJECT_BY_SLUG_REQUEST = exports.FETCH_PROJECT_BY_SLUG_FAILURE = exports.FETCH_PROJECT_BY_SLUG = exports.FETCH_PROJECT_BY_ID_SUCCESS = exports.FETCH_PROJECT_BY_ID_REQUEST = exports.FETCH_PROJECT_BY_ID_FAILURE = exports.FETCH_PROJECT_BY_ID = exports.FETCH_PROJECTS_SUCCESS = exports.FETCH_PROJECTS_REQUEST = exports.FETCH_PROJECTS_FAILURE = exports.FETCH_PROJECTS_BY_ID_SUCCESS = exports.FETCH_PROJECTS_BY_ID_REQUEST = exports.FETCH_PROJECTS_BY_ID_FAILURE = exports.FETCH_PROJECTS_BY_ID = exports.FETCH_PROJECTS = void 0;
exports.fetchProjectById = fetchProjectById;
exports.fetchProjectBySlug = fetchProjectBySlug;
exports.fetchProjects = fetchProjects;
exports.fetchProjectsById = fetchProjectsById;
exports.internalActions = void 0;
exports.reducer = reducer;
exports.selectors = void 0;
exports.watchProjects = watchProjects;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _lodash = _interopRequireDefault(require("lodash"));

var _reduxActions = require("redux-actions");

var _effects = require("redux-saga/effects");

var _common = require("./common");

var _projectService = _interopRequireDefault(require("~/app/3x/modules/services/project-service"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var RESET_STORES = 'session.reset-stores';
exports.RESET_STORES = RESET_STORES;
var STORE_NAME = 'projectsStore';
exports.STORE_NAME = STORE_NAME;

function* fetchProjects(_ref) {
  var {
    payload
  } = _ref;

  try {
    yield (0, _effects.put)(internalActions.getProjectsRequest());
    var projects = yield _projectService.default.getAllProjects(payload);
    yield (0, _effects.put)(internalActions.getProjectsSuccess(projects));
    return projects;
  } catch (e) {
    var err = (0, _common.handleError)(e);
    yield (0, _effects.put)(internalActions.getProjectsFailure(err));
    return err;
  }
}

function* fetchProjectBySlug(_ref2) {
  var {
    payload: slug
  } = _ref2;

  try {
    yield (0, _effects.put)(internalActions.getProjectBySlugRequest(slug));
    var project = yield _projectService.default.getProjectsBySlug({
      slug
    });

    if (project === null) {
      throw new Error('Failed to load project');
    }

    yield (0, _effects.put)(internalActions.getProjectBySlugSuccess(project));
    return project;
  } catch (e) {
    var err = (0, _common.handleError)(e);
    yield (0, _effects.put)(internalActions.getProjectBySlugFailure({
      slug,
      err
    }));
    return err;
  }
}

function* fetchProjectById(_ref3) {
  var {
    payload: id
  } = _ref3;

  try {
    yield (0, _effects.put)(internalActions.getProjectByIdRequest(id));
    var project = yield _projectService.default.getProjectById({
      id
    });

    if (project === null) {
      throw new Error('Failed to load project');
    }

    yield (0, _effects.put)(internalActions.getProjectByIdSuccess(project));
    return project;
  } catch (e) {
    var err = (0, _common.handleError)(e);
    yield (0, _effects.put)(internalActions.getProjectByIdFailure({
      id,
      err
    }));
    return err;
  }
}

function* fetchProjectsById(_ref4) {
  var {
    payload: projectIds
  } = _ref4;

  try {
    yield (0, _effects.put)(internalActions.fetchProjectsByIdRequest(projectIds));

    var projectPromises = _lodash.default.map(projectIds, projectId => _projectService.default.getProjectById({
      id: projectId
    }));

    var projects = yield Promise.all(projectPromises);
    yield (0, _effects.put)(internalActions.fetchProjectsByIdSuccess(projects));
    return projects;
  } catch (e) {
    var err = (0, _common.handleError)(e);
    yield (0, _effects.put)(internalActions.fetchProjectsByIdFailure({
      projectIds,
      err
    }));
    return err;
  }
}

var FETCH_PROJECTS = 'projects.fetch';
exports.FETCH_PROJECTS = FETCH_PROJECTS;
var FETCH_PROJECTS_REQUEST = 'projects.fetch.request';
exports.FETCH_PROJECTS_REQUEST = FETCH_PROJECTS_REQUEST;
var FETCH_PROJECTS_SUCCESS = 'projects.fetch.success';
exports.FETCH_PROJECTS_SUCCESS = FETCH_PROJECTS_SUCCESS;
var FETCH_PROJECTS_FAILURE = 'projects.fetch.failure';
exports.FETCH_PROJECTS_FAILURE = FETCH_PROJECTS_FAILURE;
var FETCH_PROJECT_BY_SLUG = 'project-by-slug.fetch';
exports.FETCH_PROJECT_BY_SLUG = FETCH_PROJECT_BY_SLUG;
var FETCH_PROJECT_BY_SLUG_REQUEST = 'project-by-slug.fetch.request';
exports.FETCH_PROJECT_BY_SLUG_REQUEST = FETCH_PROJECT_BY_SLUG_REQUEST;
var FETCH_PROJECT_BY_SLUG_SUCCESS = 'project-by-slug.fetch.success';
exports.FETCH_PROJECT_BY_SLUG_SUCCESS = FETCH_PROJECT_BY_SLUG_SUCCESS;
var FETCH_PROJECT_BY_SLUG_FAILURE = 'project-by-slug.fetch.failure';
exports.FETCH_PROJECT_BY_SLUG_FAILURE = FETCH_PROJECT_BY_SLUG_FAILURE;
var FETCH_PROJECT_BY_ID = 'project-by-id.fetch';
exports.FETCH_PROJECT_BY_ID = FETCH_PROJECT_BY_ID;
var FETCH_PROJECT_BY_ID_REQUEST = 'project-by-id.fetch.request';
exports.FETCH_PROJECT_BY_ID_REQUEST = FETCH_PROJECT_BY_ID_REQUEST;
var FETCH_PROJECT_BY_ID_SUCCESS = 'project-by-id.fetch.success';
exports.FETCH_PROJECT_BY_ID_SUCCESS = FETCH_PROJECT_BY_ID_SUCCESS;
var FETCH_PROJECT_BY_ID_FAILURE = 'project-by-id.fetch.failure';
exports.FETCH_PROJECT_BY_ID_FAILURE = FETCH_PROJECT_BY_ID_FAILURE;
var FETCH_PROJECTS_BY_ID = 'projects-by-id.fetch';
exports.FETCH_PROJECTS_BY_ID = FETCH_PROJECTS_BY_ID;
var FETCH_PROJECTS_BY_ID_REQUEST = 'projects-by-id.fetch.request';
exports.FETCH_PROJECTS_BY_ID_REQUEST = FETCH_PROJECTS_BY_ID_REQUEST;
var FETCH_PROJECTS_BY_ID_SUCCESS = 'projects-by-id.fetch.success';
exports.FETCH_PROJECTS_BY_ID_SUCCESS = FETCH_PROJECTS_BY_ID_SUCCESS;
var FETCH_PROJECTS_BY_ID_FAILURE = 'projects-by-id.fetch.failure';
exports.FETCH_PROJECTS_BY_ID_FAILURE = FETCH_PROJECTS_BY_ID_FAILURE;
var UPDATE_PROJECT = 'projects.update.manual';
exports.UPDATE_PROJECT = UPDATE_PROJECT;
var INITIAL_STATE = {
  isLoading: false,
  lastLoadedAt: null,
  projects: {},
  slugToId: {},
  loadingSlugs: []
};
exports.INITIAL_STATE = INITIAL_STATE;

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case RESET_STORES:
      return INITIAL_STATE;

    case FETCH_PROJECTS_REQUEST:
      return _objectSpread(_objectSpread({
        lastLoadedAt: null
      }, state), {}, {
        isLoading: true
      });

    case FETCH_PROJECTS_SUCCESS:
      {
        var now = new Date();

        var {
          projects,
          slugToId
        } = _lodash.default.reduce(action.payload, (result, project) => {
          result.projects[project._id] = {
            isLoading: false,
            lastLoadedAt: now,
            data: project
          };
          result.slugToId[project.slug] = project._id;
          return result;
        }, {
          projects: {},
          slugToId: {}
        });

        return _objectSpread(_objectSpread({}, state), {}, {
          isLoading: false,
          lastLoadedAt: now,
          projects: _objectSpread({}, projects),
          slugToId: _objectSpread({}, slugToId)
        });
      }

    case FETCH_PROJECTS_FAILURE:
      // TODO: Store error?
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });

    case FETCH_PROJECT_BY_SLUG_REQUEST:
      {
        var projectSlug = action.payload;
        var project = getProjectBySlug(state, projectSlug);
        var loadingSlugs = [...state.loadingSlugs];

        var _projects = _objectSpread({}, state.projects); // If we can't find the project by slug, we don't know the id. Place the slug in the loadingSlugs array


        if (!project && loadingSlugs.indexOf(projectSlug) === -1) {
          loadingSlugs.push(projectSlug);
        } // If we know the project and thus project id for the given slug, just update the project by id


        if (project) {
          _projects[project._id].isLoading = true;
        }

        return _objectSpread(_objectSpread({}, state), {}, {
          loadingSlugs,
          projects: _projects
        });
      }

    case FETCH_PROJECT_BY_SLUG_SUCCESS:
      {
        var _project = action.payload;

        var _loadingSlugs = _lodash.default.reject(state.loadingSlugs, _project.slug);

        return _objectSpread(_objectSpread({}, state), {}, {
          isLoading: false,
          loadingSlugs: _loadingSlugs,
          projects: _objectSpread(_objectSpread({}, state.projects), {}, {
            [_project._id]: {
              isLoading: false,
              lastLoadedAt: new Date(),
              data: _project
            }
          }),
          slugToId: _objectSpread(_objectSpread({}, state.slugToId), {}, {
            [_project.slug]: _project._id
          })
        });
      }

    case FETCH_PROJECT_BY_SLUG_FAILURE:
      {
        var {
          slug
        } = action.payload;

        var _loadingSlugs2 = _lodash.default.reject(state.loadingSlugs, slug);

        return _objectSpread(_objectSpread({}, state), {}, {
          loadingSlugs: _loadingSlugs2,
          isLoading: false
        });
      }

    case FETCH_PROJECT_BY_ID_REQUEST:
      {
        var projectId = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          projects: _objectSpread(_objectSpread({}, state.projects), {}, {
            [projectId]: _objectSpread(_objectSpread({
              lastLoadedAt: null
            }, state.projects[projectId]), {}, {
              isLoading: true
            })
          })
        });
      }

    case FETCH_PROJECT_BY_ID_SUCCESS:
      {
        var _project2 = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          projects: _objectSpread(_objectSpread({}, state.projects), {}, {
            [_project2._id]: {
              isLoading: false,
              lastLoadedAt: new Date(),
              data: _project2
            }
          }),
          slugToId: _objectSpread(_objectSpread({}, state.slugToId), {}, {
            [_project2.slug]: _project2._id
          })
        });
      }

    case FETCH_PROJECT_BY_ID_FAILURE:
      {
        var {
          id
        } = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          projects: _objectSpread(_objectSpread({}, state.projects), {}, {
            [id]: _objectSpread(_objectSpread({}, state.projects[id]), {}, {
              isLoading: false
            })
          })
        });
      }

    case FETCH_PROJECTS_BY_ID_REQUEST:
      {
        var projectIDs = action.payload;

        var updatedProjects = _lodash.default.reduce(projectIDs, (result, projectID) => {
          result[projectID] = _objectSpread(_objectSpread({
            lastLoadedAt: null
          }, state.projects[projectID]), {}, {
            isLoading: true
          });
          return result;
        }, {});

        return _objectSpread(_objectSpread({}, state), {}, {
          projects: _objectSpread(_objectSpread({}, state.projects), updatedProjects)
        });
      }

    case FETCH_PROJECTS_BY_ID_SUCCESS:
      {
        var _projects2 = action.payload;

        var _updatedProjects = _lodash.default.reduce(_projects2, (result, project) => {
          result.projects[project._id] = {
            isLoading: false,
            lastLoadedAt: new Date(),
            data: project
          };
          result.slugToId[project._id] = project.slug;
          return result;
        }, {
          projects: {},
          slugToId: {}
        });

        return _objectSpread(_objectSpread({}, state), {}, {
          projects: _objectSpread(_objectSpread({}, state.projects), _updatedProjects.projects),
          slugToId: _objectSpread(_objectSpread({}, state.slugToId), _updatedProjects.slugToId)
        });
      }

    case FETCH_PROJECTS_BY_ID_FAILURE:
      {
        var {
          projectIds
        } = action.payload;

        var _updatedProjects2 = _lodash.default.reduce(projectIds, (result, projectId) => {
          result[projectId] = {
            isLoading: false
          };
          return result;
        }, {});

        return _objectSpread(_objectSpread({}, state), {}, {
          projects: _objectSpread(_objectSpread({}, state.projects), _updatedProjects2)
        });
      }

    case UPDATE_PROJECT:
      {
        var _project3 = action.payload;
        return _objectSpread(_objectSpread({}, state), {}, {
          projects: _objectSpread(_objectSpread({}, state.projects), {}, {
            [_project3._id]: _objectSpread(_objectSpread({}, state.projects[_project3._id]), {}, {
              data: _project3
            })
          }),
          slugToId: _objectSpread(_objectSpread({}, state.slugToId), {}, {
            [_project3.slug]: _project3._id
          })
        });
      }

    default:
      return state;
  }
}

var actions = {
  getProjects: (0, _reduxActions.createAction)(FETCH_PROJECTS),
  getProjectBySlug: (0, _reduxActions.createAction)(FETCH_PROJECT_BY_SLUG),
  getProjectById: (0, _reduxActions.createAction)(FETCH_PROJECT_BY_ID),
  fetchProjectsById: (0, _reduxActions.createAction)(FETCH_PROJECTS_BY_ID),
  updateProject: (0, _reduxActions.createAction)(UPDATE_PROJECT)
};
/**
 * Actions that should only be invoked internally
 */

exports.actions = actions;
var internalActions = {
  getProjectsRequest: (0, _reduxActions.createAction)(FETCH_PROJECTS_REQUEST),
  getProjectsSuccess: (0, _reduxActions.createAction)(FETCH_PROJECTS_SUCCESS),
  getProjectsFailure: (0, _reduxActions.createAction)(FETCH_PROJECTS_FAILURE),
  getProjectBySlugRequest: (0, _reduxActions.createAction)(FETCH_PROJECT_BY_SLUG_REQUEST),
  getProjectBySlugSuccess: (0, _reduxActions.createAction)(FETCH_PROJECT_BY_SLUG_SUCCESS),
  getProjectBySlugFailure: (0, _reduxActions.createAction)(FETCH_PROJECT_BY_SLUG_FAILURE),
  getProjectByIdRequest: (0, _reduxActions.createAction)(FETCH_PROJECT_BY_ID_REQUEST),
  getProjectByIdSuccess: (0, _reduxActions.createAction)(FETCH_PROJECT_BY_ID_SUCCESS),
  getProjectByIdFailure: (0, _reduxActions.createAction)(FETCH_PROJECT_BY_ID_FAILURE),
  fetchProjectsByIdRequest: (0, _reduxActions.createAction)(FETCH_PROJECTS_BY_ID_REQUEST),
  fetchProjectsByIdSuccess: (0, _reduxActions.createAction)(FETCH_PROJECTS_BY_ID_SUCCESS),
  fetchProjectsByIdFailure: (0, _reduxActions.createAction)(FETCH_PROJECTS_BY_ID_FAILURE)
};
exports.internalActions = internalActions;
var selectors = {
  isLoading: createSelector(isLoading),
  isLoadingBySlug: createSelector(isLoadingBySlug),
  isLoaded: createSelector(isLoaded),
  isLoadedBySlug: createSelector(isLoadedBySlug),
  getLastLoadedAt: createSelector(getLastLoadedAt),
  getLastLoadedAtBySlug: createSelector(getLastLoadedAtBySlug),
  getProjects: createSelector(getProjects),
  getProjectById: createSelector(getProjectById),
  getProjectBySlug: createSelector(getProjectBySlug)
};
exports.selectors = selectors;

function isLoading(state) {
  var id = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

  // If we're loading the whole store, any individual project is considered loading as well
  if (state.isLoading) {
    return true;
  } // If no id was provided, we already checked if all projects were loading and they aren't so return false;


  if (!id) {
    return false;
  }

  var projectContainer = getProjectContainer(state, id);

  if (!projectContainer) {
    return false;
  }

  return projectContainer.isLoading;
}

function isLoadingBySlug(state, slug) {
  var projectId = state.slugToId[slug];

  if (!projectId) {
    return state.loadingSlugs.indexOf(slug) !== -1;
  }

  return isLoading(state, projectId);
}

function isLoaded(state) {
  var id = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

  if (!id) {
    return !!state.lastLoadedAt;
  }

  var projectContainer = getProjectContainer(state, id);

  if (!projectContainer) {
    return false;
  }

  return !!projectContainer.lastLoadedAt;
}

function isLoadedBySlug(state, slug) {
  var projectId = state.slugToId[slug];

  if (!projectId) {
    return false;
  }

  return isLoaded(state, projectId);
}

function getProjects(state) {
  return _lodash.default.values(state.projects).filter(wrapper => wrapper.data).map(wrapper => wrapper.data);
}

function getProjectById(state, id) {
  var projectContainer = getProjectContainer(state, id);
  return projectContainer ? projectContainer.data : null;
}

function getProjectBySlug(state, slug) {
  var projectId = state.slugToId[slug];

  if (!projectId) {
    return null;
  }

  return getProjectById(state, projectId);
}

function getLastLoadedAt(state, id) {
  if (!id) {
    return state.lastLoadedAt;
  }

  var projectContainer = getProjectContainer(state, id);
  return projectContainer ? projectContainer.lastLoadedAt : null;
}

function getLastLoadedAtBySlug(state, slug) {
  var projectId = state.slugToId[slug];

  if (!projectId) {
    return null;
  }

  return getLastLoadedAt(state, projectId);
}

function getProjectContainer(state, id) {
  return state.projects[id];
}
/**
 * Helper method that grabs our specific part of the global store, then passes it into the given selector.
 * This makes it so that each selector doesn't have to grab the sub-state itself, reducing code duplication.
 */


function createSelector(selector) {
  return function selectorWrapper(state) {
    var projectsStore = state[STORE_NAME];

    for (var _len = arguments.length, rest = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      rest[_key - 1] = arguments[_key];
    }

    return selector(projectsStore, ...rest);
  };
}

function* watchProjects() {
  yield (0, _effects.takeLatest)(FETCH_PROJECTS, fetchProjects);
  yield (0, _effects.takeEvery)(FETCH_PROJECT_BY_SLUG, fetchProjectBySlug);
  yield (0, _effects.takeEvery)(FETCH_PROJECT_BY_ID, fetchProjectById);
  yield (0, _effects.takeEvery)(FETCH_PROJECTS_BY_ID, fetchProjectsById);
}