"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.actions = exports.STORE_NAME = exports.RESET_STORES = exports.QUEUE_REMOVE = exports.QUEUE_CLEAR = exports.QUEUE_ADD = exports.INITIAL_STATE = void 0;
exports.reducer = reducer;
exports.selectors = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _reduxActions = require("redux-actions");

var _common = require("./common");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var RESET_STORES = 'session.reset-stores';
exports.RESET_STORES = RESET_STORES;
var STORE_NAME = 'socialRequestStore';
exports.STORE_NAME = STORE_NAME;
var QUEUE_ADD = 'social-request.queue.add';
exports.QUEUE_ADD = QUEUE_ADD;
var QUEUE_REMOVE = 'social-request.queue.remove';
exports.QUEUE_REMOVE = QUEUE_REMOVE;
var QUEUE_CLEAR = 'social-request.queue.clear';
exports.QUEUE_CLEAR = QUEUE_CLEAR;
var INITIAL_STATE = {
  socialRequestQueue: []
};
exports.INITIAL_STATE = INITIAL_STATE;

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case RESET_STORES:
      return INITIAL_STATE;

    case QUEUE_ADD:
      {
        var toAdd = action.payload;
        var socialRequestQueue = (0, _common.extendQueue)(state.socialRequestQueue, toAdd);
        return _objectSpread(_objectSpread({}, state), {}, {
          socialRequestQueue
        });
      }

    case QUEUE_REMOVE:
      {
        var toRemove = action.payload;

        var _socialRequestQueue = (0, _common.filterQueue)(state.socialRequestQueue, toRemove);

        return _objectSpread(_objectSpread({}, state), {}, {
          socialRequestQueue: _socialRequestQueue
        });
      }

    case QUEUE_CLEAR:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          socialRequestQueue: []
        });
      }

    default:
      return state;
  }
}

var actions = {
  addToQueue: (0, _reduxActions.createAction)(QUEUE_ADD),
  removeFromQueue: (0, _reduxActions.createAction)(QUEUE_REMOVE),
  clearQueue: (0, _reduxActions.createAction)(QUEUE_CLEAR)
};
exports.actions = actions;
var selectors = {
  getQueue: (0, _common.createSelector)(STORE_NAME, getQueue),
  isAssetInQueue: (0, _common.createSelector)(STORE_NAME, isAssetInQueue)
};
exports.selectors = selectors;

function getQueue(state) {
  return state.socialRequestQueue;
}

function isAssetInQueue(state, assetId) {
  return state.socialRequestQueue.indexOf(assetId) > -1;
}