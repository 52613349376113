"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.rootSaga = rootSaga;

var _effects = require("redux-saga/effects");

var _assetPage = require("~/app/3x/store/asset-page");

var _assetTypes = require("~/app/3x/store/asset-types");

var _assets = require("~/app/3x/store/assets");

var _comments = require("~/app/3x/store/comments");

var _downloadsPage = require("~/app/3x/store/downloads-page");

var _files = require("~/app/3x/store/files");

var _groups = require("~/app/3x/store/groups");

var _ingestUpload = require("~/app/3x/store/ingest-upload");

var _ingests = require("~/app/3x/store/ingests");

var _languages = require("~/app/3x/store/languages");

var _notifications = require("~/app/3x/store/notifications");

var _studios = require("~/app/3x/store/studios");

var _projectCrud = require("~/app/3x/store/project-crud");

var _previews = require("~/app/3x/store/previews");

var _projectSidebar = require("~/app/3x/store/project-sidebar");

var _projects = require("~/app/3x/store/projects");

var _projectsIndex = require("~/app/3x/store/projects-index");

var _registerPage = require("~/app/3x/store/register-page");

var _users = require("~/app/3x/store/users");

var _territories = require("~/app/3x/store/territories");

var _shareEditPage = require("~/app/3x/store/share-edit-page");

var _shares = require("~/app/3x/store/shares");

var _shareFeedbackPage = require("~/app/3x/store/share-feedback-page");

var _shareViewPage = require("~/app/3x/store/share-view-page");

var _sharesPage = require("~/app/3x/store/shares-page");

var _subtitlerPage = require("~/app/3x/store/subtitler-page");

var _translations = require("~/app/3x/store/translations");

var _workRequestPage = require("~/app/3x/store/work-request-page");

var _workRequestQueue = require("~/app/3x/store/work-request-queue");

var _workRequests = require("~/app/3x/store/work-requests");

var _locale = require("~/app/3x/store/locale");

var _workRequestsPage = require("~/app/3x/store/work-requests-page");

function* rootSaga() {
  yield (0, _effects.all)([(0, _assetPage.watchAssetPage)(), (0, _assetTypes.watchAssetTypes)(), (0, _assets.watchAssets)(), (0, _comments.watchComments)(), (0, _downloadsPage.watchDownloadsPage)(), (0, _downloadsPage.watchDownloadsPage)(), (0, _files.watchFiles)(), (0, _groups.watchGroups)(), (0, _ingestUpload.watchIngestUpload)(), (0, _ingests.watchIngests)(), (0, _languages.watchLanguages)(), (0, _locale.watchLocale)(), (0, _notifications.watchNotifications)(), (0, _projectCrud.watchProjectCrud)(), (0, _previews.watchPreviews)(), (0, _projectSidebar.watchProjectSidebar)(), (0, _projects.watchProjects)(), (0, _projectsIndex.watchProjectsIndex)(), (0, _registerPage.watchRegisterPage)(), (0, _shareEditPage.watchShareEditPage)(), (0, _shareFeedbackPage.watchFeedbackPage)(), (0, _shareViewPage.watchShareViewPage)(), (0, _shares.watchShares)(), (0, _sharesPage.watchSharesPage)(), (0, _studios.watchStudios)(), (0, _subtitlerPage.watchSubtitlerPage)(), (0, _territories.watchTerritories)(), (0, _translations.watchTranslations)(), (0, _users.watchUsers)(), (0, _workRequestPage.watchWorkRequestPage)(), (0, _workRequestQueue.watchWorkRequestQueue)(), (0, _workRequests.watchWorkRequests)(), (0, _workRequestsPage.watchWorkRequestsPage)()]);
}