"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.UPDATE_SHARE_QUEUE_MUTATION = exports.UPDATE_SHARE_MUTATION = exports.GET_SHARE_QUEUE_QUERY = exports.CREATE_SHARE_MUTATION = exports.ALL_SHARES_QUERY = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _lodash = _interopRequireDefault(require("lodash"));

var _routeService = _interopRequireDefault(require("./route-service"));

var _apiService = _interopRequireWildcard(require("./api-service"));

var _exceptionHandler = require("../pixwel/exception-handler.factory");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var GET_SHARE_QUEUE_QUERY = "\nquery getShareQueue {\n\tsession {\n\t\tid\n\t\tshareQueue {\n\t\t\tid\n\t\t\tmediaType\n\t\t}\n\t}\n}\n";
exports.GET_SHARE_QUEUE_QUERY = GET_SHARE_QUEUE_QUERY;
var CREATE_SHARE_MUTATION = "\nmutation createShare($input: ShareInput) {\n\tcreateShare(input: $input) {\n\t\tshare {\n\t\t\tid\n\t\t}\n\t}\n}\n";
exports.CREATE_SHARE_MUTATION = CREATE_SHARE_MUTATION;
var UPDATE_SHARE_MUTATION = "\nmutation updateShare($input: ShareInput) {\n\tupdateShare(input: $input) {\n\t\tshare {\n\t\t\tid\n\t\t}\n\t}\n}\n";
exports.UPDATE_SHARE_MUTATION = UPDATE_SHARE_MUTATION;
var UPDATE_SHARE_QUEUE_MUTATION = "\nmutation updateShareQueue($input: UserInput) {\n\tupdateSession(input: $input) {\n\t\tsession {\n\t\t\tid\n\t\t\temailNormal\n\t\t\tshareQueue {\n\t\t\t\tid\n\t\t\t\tname\n\t\t\t\tthumbnail\n\t\t\t\tcreative\n\t\t\t\tproject {\n\t\t\t\t\tslug\n\t\t\t\t\tname\n\t\t\t\t}\n\t\t\t\tassetType {\n\t\t\t\t\tcategory\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n}\n";
exports.UPDATE_SHARE_QUEUE_MUTATION = UPDATE_SHARE_QUEUE_MUTATION;
var ALL_SHARES_QUERY = "\nquery getAllShares($orderBy: String, $orderDirection: String, $limit: Int, $offset: Int) {\n  shares(orderBy: $orderBy, orderDirection: $orderDirection, limit: $limit, offset: $offset) {\n    totalCount\n    nodes {\n      id\n      created\n      from {\n        id\n        name\n        emailNormal\n      }\n      to {\n        id\n        name\n        emailNormal\n      }\n      projectNames\n      assets {\n        id\n        name\n        thumbnail\n      }\n      assetsCount\n      expired\n      advancedTags\n      tags\n      assetCategory\n      approved\n      groups {\n        id\n        name\n      }\n      notes\n      languages\n    }\n  }\n}";
exports.ALL_SHARES_QUERY = ALL_SHARES_QUERY;
var ShareService = {
  getAllShares(params) {
    return _apiService.default.graphql.request(ALL_SHARES_QUERY, params).then(data => data.shares);
  },

  patchShare(share) {
    return _apiService.default.client.patch("/shares/".concat(share.id), share);
  },

  getShareQueue(id) {
    return _apiService.default.graphql.request(GET_SHARE_QUEUE_QUERY, {
      id
    });
  },

  initializeShare(data) {
    var _assets$;

    var {
      id,
      shareQueue: assets
    } = data.session;
    return {
      assets,
      assetCategory: (_assets$ = assets[0]) === null || _assets$ === void 0 ? void 0 : _assets$.assetType.category,
      from: id,
      to: [],
      groups: [],
      notes: '',
      feedback: {
        enabled: assets === null || assets === void 0 ? void 0 : assets.every(asset => asset.creative === true)
      },
      tags: [],
      advancedTags: null
    };
  },

  createShare(share) {
    var _share$languages$map, _share$languages;

    var assetUrls = share.assets.reduce((result, asset) => {
      result[asset.id] = _routeService.default.stateHref('asset', {
        projectId: asset.project.id,
        assetId: asset.id
      }, {
        absolute: true
      });
      return result;
    }, {}); // add 'txtd' to online tag

    var tags = share.tags.map(tag => {
      if (tag[0] === 'online') {
        tag.push('txtd');
      }

      return tag;
    });

    var urls = _objectSpread(_objectSpread({}, assetUrls), {}, {
      playlist: _routeService.default.stateHref('share-view', {
        id: ':share'
      }, {
        absolute: true
      }),
      invite: _routeService.default.stateHref('register', {
        code: ':code'
      }, {
        absolute: true
      })
    });

    var params = {
      input: _objectSpread(_objectSpread({}, share), {}, {
        to: share.to.map(to => typeof to === 'string' ? to : to.id),
        groups: share.groups.map(group => group.id),
        assets: share.assets.map(asset => asset.id),
        tags,
        urls: _lodash.default.transform(urls, (result, url, key) => {
          result[key] = decodeURIComponent(url);
        }),
        languages: (_share$languages$map = share === null || share === void 0 ? void 0 : (_share$languages = share.languages) === null || _share$languages === void 0 ? void 0 : _share$languages.map(language => language.value)) !== null && _share$languages$map !== void 0 ? _share$languages$map : []
      })
    };
    return _apiService.default.graphql.request(CREATE_SHARE_MUTATION, params);
  },

  updateShare(share) {
    var _share$tags;

    // add 'txtd' to online tag (if missing)
    var tags = (_share$tags = share.tags) === null || _share$tags === void 0 ? void 0 : _share$tags.map(tag => {
      if (tag[0] === 'online' && !tag.includes('txtd')) {
        tag.push('txtd');
      }

      return tag;
    });
    var params = {
      input: {
        id: share.id,
        to: share.to.map(to => typeof to === 'string' ? to : to.id),
        groups: share.groups.map(group => group.id),
        assets: share.assets.map(asset => asset.id),
        notes: share.notes,
        feedback: share.feedback,
        tags,
        languages: share.languages,
        advancedTags: share.advancedTags
      }
    };
    return _apiService.default.graphql.request(UPDATE_SHARE_MUTATION, params);
  },

  updateShareQueue(id, assets) {
    var shareQueue = assets.map(asset => asset.id || asset._id); // Filter out any null values

    shareQueue = shareQueue.filter(assetId => !!assetId);
    return _apiService.default.graphql.request(UPDATE_SHARE_QUEUE_MUTATION, {
      input: {
        id,
        shareQueue
      }
    });
  },

  mapRecipients(recipients) {
    var _groupedRecipients$to, _groupedRecipients$gr;

    var groupedRecipients = _lodash.default.groupBy(recipients, contact => {
      if (typeof contact === 'string') {
        return 'to';
      }

      return 'emailNormal' in contact ? 'to' : 'groups';
    });

    return {
      to: (_groupedRecipients$to = groupedRecipients === null || groupedRecipients === void 0 ? void 0 : groupedRecipients.to) !== null && _groupedRecipients$to !== void 0 ? _groupedRecipients$to : [],
      groups: (_groupedRecipients$gr = groupedRecipients === null || groupedRecipients === void 0 ? void 0 : groupedRecipients.groups) !== null && _groupedRecipients$gr !== void 0 ? _groupedRecipients$gr : []
    };
  },

  validateShare(share, isDownloadEnabled, isAdmin) {
    // validate recipients
    if (!share.to.length && !share.groups.length) {
      return 'Select recipients';
    }

    if (!share.languages && !share.id && !isAdmin) {
      return 'Select languages';
    } // validate download access


    if (isDownloadEnabled) {
      var downloadInvalid;

      if (share.tags.length) {
        downloadInvalid = share.tags.some(arr => arr.length === 1 && arr[0] !== 'online');
      } else {
        downloadInvalid = true;
      }

      if (downloadInvalid) {
        return 'Download access invalid';
      }
    } // validate voting


    if (share.feedback.enabled && !share.feedback.expires) {
      return 'Select voting expiration';
    }

    return null;
  },

  checkTypesMatch(assets, queue) {
    var typesMatch = assets.every(asset => asset.mediaType === assets[0].mediaType);
    var typesAllowed = queue.length > 0 ? queue[0].mediaType === assets[0].mediaType : true;
    return typesMatch && typesAllowed;
  },

  isFeedbackEnabled(share) {
    return share.feedback && share.feedback.enabled;
  },

  isVotingClosed(share) {
    if (!share.feedback || !share.feedback.enabled) {
      return;
    }

    return share.feedback.expires <= Math.floor(Date.now() / 1000);
  },

  isShareExpired(share) {
    return !!share.expired;
  },

  isVoteSubmitted(share) {
    return share.assets.every(asset => {
      if (!asset.$feedback.vote) {
        return false;
      }

      return asset.$feedback.vote.submitted;
    });
  },

  expireVoting(share) {
    if (!share.feedback || !share.feedback.enabled) {
      throw new Error('only shares with feedback enabled can have an expiration value');
    }

    share.feedback.expires = Math.floor(Date.now() / 1000);
    return _apiService.default.client.patch("Shares/".concat(share._id), {
      feedback: share.feedback
    }).then(res => res.data);
  },

  expireShare(share) {
    return _apiService.default.client.patch("/Shares/".concat(share._id), {
      expired: true
    }).then(res => res.data);
  },

  getFeedback(assets) {
    return (0, _lodash.default)(assets).map(asset => {
      return _objectSpread(_objectSpread({
        asset: asset._id
      }, asset.$feedback.vote), {}, {
        $links: {
          self: asset.$links.assetFeedback
        }
      });
    }).indexBy('asset').value();
  },

  getShareById(shareId) {
    return _apiService.default.client.get((0, _apiService.buildURL)('/shares', {
      id: shareId
    })).then(res => {
      return res.data[0] || res.data;
    });
  },

  getSharesByQueryAndPage(_ref) {
    var _query$created;

    var {
      query,
      start,
      end
    } = _ref;
    var params = {
      user: query.user,
      order: {
        _id: query.order._id
      },
      created: {
        from: (_query$created = query.created) === null || _query$created === void 0 ? void 0 : _query$created.from
      }
    };

    if (query.from) {
      params.from = query.from;
    } else {
      params.to = true;
    }

    return _apiService.default.client.get((0, _apiService.buildURL)('/shares', params), {
      headers: {
        'X-Range': "resources=".concat(start, "-").concat(end)
      }
    });
  },

  getSharesByQuery(query) {
    return _apiService.default.client.get((0, _apiService.buildURL)('/shares', query)).then(res => res.data);
  },

  submitFeedback(allFeedback) {
    var unvotedFeedback = Object.values(allFeedback);
    return unvotedFeedback.reduce((chain, feedback) => {
      return chain.then(() => {
        var {
          comment,
          vote,
          wouldOrder
        } = feedback;
        return _apiService.default.client.patch("/Assetfeedback/".concat(feedback._id), {
          comment,
          vote,
          wouldOrder,
          submitted: true
        });
      }).catch(err => {
        var _err$data, _err$data$message;

        var errorMessage = 'An error occurred saving feedback';

        if ((_err$data = err.data) !== null && _err$data !== void 0 && (_err$data$message = _err$data.message) !== null && _err$data$message !== void 0 && _err$data$message.includes('Forbidden')) {
          errorMessage = 'You do not have permission to change your vote. Voting is closed or your permission has been removed.';
        }

        (0, _exceptionHandler.captureError)(err, errorMessage);
      });
    }, Promise.resolve());
  },

  deleteShare(share) {
    var _share$id;

    var id = typeof share === 'object' ? (_share$id = share.id) !== null && _share$id !== void 0 ? _share$id : share === null || share === void 0 ? void 0 : share._id : share;
    return _apiService.default.client.delete("/shares/".concat(id));
  }

};
var _default = ShareService;
exports.default = _default;