"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _lodash = _interopRequireDefault(require("lodash"));

var _lodash2 = _interopRequireDefault(require("lodash.padstart"));

var _lodash3 = _interopRequireDefault(require("lodash.padend"));

var _toastService = _interopRequireDefault(require("./toast-service"));

var _sessionService = _interopRequireDefault(require("./session-service"));

var _apiService = _interopRequireDefault(require("./api-service"));

var _srtService = _interopRequireDefault(require("./srt-service"));

var _subService = _interopRequireDefault(require("./sub-service"));

var _assService = _interopRequireDefault(require("./ass-service"));

var _stripTagsService = _interopRequireDefault(require("./strip-tags-service"));

var _timeFormatService = _interopRequireDefault(require("./time-format-service"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var DEFAULT_TRANSLATION = {
  line: 0,
  ovText: '',
  translationText: '',
  translationFrom: 'ov',
  alignment: 'bottom',
  textAlign: 'center',
  inTime: '00:00:00,000',
  outTime: '00:00:00,000',
  split: null,
  merged: null
};
var SubtitleService = {
  parse: data => {
    var lines = data.split('\n');

    switch (SubtitleService.detectFormat(lines)) {
      case 'srt':
        return _srtService.default.parse(data);

      case 'sub':
        return _subService.default.parse(lines);

      case 'ass':
        return _assService.default.parse(lines);
    }
  },
  detectFormat: lines => {
    var firstChar = lines[0].replace(/s+/g, '').charCodeAt(0);

    if (firstChar === '1'.charCodeAt(0)) {
      return 'srt';
    } else if (firstChar === '['.charCodeAt(0)) {
      return 'ass';
    }

    return 'sub';
  },
  importSubtitles: (file, ovTranslations) => {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();

      reader.onload = () => {
        try {
          var translationLines = SubtitleService.parse(reader.result); // TODO: Attempt to support importing translations that don't match ov line count

          if (translationLines.length !== ovTranslations.length) {
            var errorMsg = 'Cannot import translations with a different cell count than OV cell count';

            _toastService.default.create('error', errorMsg);

            throw new Error(errorMsg);
          }

          var translations = _lodash.default.map(translationLines, (translationLine, i) => {
            var translation = SubtitleService.to3xTranslation(translationLine, ovTranslations[i].text, false);
            translation.line = i + 1;
            return _lodash.default.defaults(translation, DEFAULT_TRANSLATION);
          });

          resolve(translations);
        } catch (e) {
          reject(e);
        }
      };

      reader.readAsText(file);
    });
  },
  exportSubtitlesAsSrt: (subtitles, filename) => {
    var {
      workRequestId,
      translations
    } = subtitles;

    if (!filename) {
      filename = "".concat(workRequestId);
    } // Convert to structure Subtitle expects


    var lines = _lodash.default.map(translations, translation => ({
      text: translation.translationText.replaceAll('<div>', '').replaceAll('</div>', '\n'),
      startTime: translation.inTime,
      stopTime: translation.outTime,
      alignment: translation.alignment
    }));

    var translation = {
      lines
    };
    return SubtitleService.downloadSrt(translation, filename);
  },
  exportSubtitlesAsAss: function exportSubtitlesAsAss(subtitles, filename) {
    var isTiktok = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    var {
      workRequestId,
      translations,
      translationFont
    } = subtitles;

    if (!filename) {
      filename = "".concat(workRequestId);
    }

    var lines = _lodash.default.map(translations, translation => ({
      text: translation.translationText,
      startTime: translation.inTime,
      stopTime: translation.outTime,
      alignment: translation.alignment
    }));

    var translation = {
      lines
    };
    return SubtitleService.downloadAss(translation, filename, {
      translationFont,
      isTiktok
    });
  },
  downloadFile: (data, type, filename) => {
    var element = document.createElement('a');
    var file = new Blob([data], {
      type: type
    });
    element.href = URL.createObjectURL(file);
    element.download = filename;
    document.body.appendChild(element);
    element.click();
  },
  downloadSrt: (translation, filename) => {
    SubtitleService.downloadFile(_srtService.default.export(translation.lines), 'application/x-subrip', filename + '.srt');
  },
  downloadAss: function downloadAss(translation, filename) {
    var {
      translationFont,
      isTiktok = false
    } = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    SubtitleService.downloadFile(_assService.default.export(translation.lines, {
      font: translationFont,
      isTiktok
    }), 'application/x-ass', filename + '.ass');
  },
  // @todo Test this one when uploading InsertReport is enable
  downloadInsertReport: function () {
    var _downloadInsertReport = (0, _asyncToGenerator2.default)(function* (asset) {
      var session = yield _sessionService.default.getSession();
      return _apiService.default.client.post('/events', {
        from: session._id,
        files: [asset.file.insertReport],
        type: 'rd'
      }).then(res => {
        return _apiService.default.client.get("/files/".concat(res.data.files[0]));
      });
    });

    function downloadInsertReport(_x) {
      return _downloadInsertReport.apply(this, arguments);
    }

    return downloadInsertReport;
  }(),
  downloadCueSheet: function () {
    var _downloadCueSheet = (0, _asyncToGenerator2.default)(function* (asset) {
      var _asset$file;

      var session = yield _sessionService.default.getSession();
      return _apiService.default.client.post('/events', {
        from: session._id,
        files: [(_asset$file = asset.file) === null || _asset$file === void 0 ? void 0 : _asset$file.cueSheet],
        type: 'rd'
      }).then(res => {
        return _apiService.default.client.get("/files/".concat(res.data.files[0]));
      });
    });

    function downloadCueSheet(_x2) {
      return _downloadCueSheet.apply(this, arguments);
    }

    return downloadCueSheet;
  }(),
  // @todo Test this one when uploading graphics is enable
  downloadGraphicsProject: function () {
    var _downloadGraphicsProject = (0, _asyncToGenerator2.default)(function* (asset) {
      var session = yield _sessionService.default.getSession();
      return _apiService.default.client.post('/events', {
        from: session._id,
        files: [asset.file.graphicsProject],
        type: 'rd'
      }).then(res => {
        return _apiService.default.client.get("/files/".concat(res.data.files[0]));
      });
    });

    function downloadGraphicsProject(_x3) {
      return _downloadGraphicsProject.apply(this, arguments);
    }

    return downloadGraphicsProject;
  }(),

  /**
   * Converts work request translation lines to 3.x structure, taking
   * split/merged segments into account
   *
   * @param   {array} translations   workRequest.translation.lines
   * @param   {array} ovTranslations OV translation lines
   * @param   {array} graphicsTranslations Graphics lines
   * @param 	{array} dialogueTranslations Dialogue lines
   * @returns {array} 3.x translations
   */
  map3xTranslations(translations, ovTranslations) {
    var result = [];
    var ovIndex = 0;

    for (var i = 0; i < translations.length; i++) {
      var _ovTranslations$ovInd;

      var translationLine = translations[i];
      var ovText = void 0;
      var isGfx = (_ovTranslations$ovInd = ovTranslations[ovIndex]) === null || _ovTranslations$ovInd === void 0 ? void 0 : _ovTranslations$ovInd.gfx;

      if (translationLine.merged) {
        // line was merged
        ovText = "".concat(translationLine.merged[0], " ").concat(translationLine.merged[1]);
        ovIndex += 1; // jump over merged OV line
      } else if (translationLine.split) {
        var _ovTranslations$ovInd2, _ovTranslations$ovInd3, _translations;

        // line was split
        ovText = (_ovTranslations$ovInd2 = (_ovTranslations$ovInd3 = ovTranslations[ovIndex]) === null || _ovTranslations$ovInd3 === void 0 ? void 0 : _ovTranslations$ovInd3.text) !== null && _ovTranslations$ovInd2 !== void 0 ? _ovTranslations$ovInd2 : ''; // advance to next OV line only if this is split line 2 of 2

        if (translationLine.split !== ((_translations = translations[i + 1]) === null || _translations === void 0 ? void 0 : _translations.split)) {
          ovIndex++;
        }
      } else {
        var _ovTranslations$ovInd4;

        ovText = ((_ovTranslations$ovInd4 = ovTranslations[ovIndex]) === null || _ovTranslations$ovInd4 === void 0 ? void 0 : _ovTranslations$ovInd4.text) || '';
        ovIndex++;
      }

      if (ovIndex >= translations.length) {
        ovIndex = translations.length - 1;
      }

      if (ovIndex <= ovTranslations.length) {
        var translation3x = SubtitleService.to3xTranslation(translationLine, ovText, isGfx);
        translation3x.line = i + 1;
        result.push(translation3x);
      }
    }

    return result;
  },

  /**
   * Transforms 2x translation structure to 3x translation structure
   */
  to3xTranslation(translationLine, ovTranslationText, isGfx) {
    var ovText = ovTranslationText.trim();
    var text = translationLine.text.trim();
    var translationText = to3xTranslationText(text);
    var translationFrom = translationLine.auto ? 'tm' : translationLine.machine ? 'mt' : SubtitleService.isCellTranslated({
      ovText,
      translationText
    }) ? 'custom' : 'ov';
    var res = {
      ovText,
      translationText,
      translationFrom,
      inTime: translationLine.startTime,
      outTime: translationLine.stopTime,
      alignment: translationLine.alignment || 'bottom',
      textAlign: translationLine.textAlign || 'center',
      split: translationLine.split || null,
      merged: translationLine.merged || null,
      gfx: isGfx
    };

    if (translationLine.ctMeta) {
      res.ctMeta = translationLine.ctMeta;
    }

    return res;
  },

  sortLinesByTimeCodes(lines, automatedLocalization) {
    var result = _lodash.default.map(lines, (line, index) => _objectSpread(_objectSpread({}, line), {}, {
      number: index + 1,
      automatedLocalization
    }));

    result = result.sort((a, b) => {
      return _timeFormatService.default.msDiff(b.startTime, a.startTime);
    });
    return result;
  },

  sortLinesByRange(lines, automatedLocalization) {
    return lines.sort((a, b) => {
      return a.range[0] - b.range[0] || a.range[1] - b.range[1];
    }).map((line, index) => _objectSpread(_objectSpread({}, line), {}, {
      number: index + 1,
      automatedLocalization
    }));
  },

  trimLines(line) {
    return (0, _stripTagsService.default)(line.replace(/(\r\n|\n|\r| )/gm, '').toLowerCase());
  },

  /**
   * Transforms 3x translation structure to a 2x translation structure
   */
  to2xTranslation(translation, i, graphicsTranslations, dialogueTranslations) {
    var text = (0, _stripTagsService.default)(translation.translationText, '<div><br><b><i><u><span>').replace(/&nbsp;/g, '').replace(/\r\n/g, '').replace(/\n/g, '');
    var res = {
      number: i + 1,
      text,
      auto: translation.translationFrom === 'tm',
      custom: translation.translationFrom === 'custom',
      machine: translation.translationFrom === 'mt',
      startTime: translation.inTime,
      stopTime: translation.outTime,
      alignment: translation.alignment,
      textAlign: translation.textAlign,
      split: translation.split || null,
      merged: translation.merged || null,
      range: [SubtitleService.timecodeToOffset(translation.inTime), SubtitleService.timecodeToOffset(translation.outTime)]
    };

    if (translation.ctMeta) {
      res.ctMeta = translation.ctMeta;
    }

    if (graphicsTranslations && dialogueTranslations) {
      res.gfx = graphicsTranslations.find(e => SubtitleService.trimLines(text) === SubtitleService.trimLines(e.text)) && !dialogueTranslations.find(e => SubtitleService.trimLines(text) === SubtitleService.trimLines(e.text));
    }

    return res;
  },

  map3xPrintTranslations(translations, ovTranslations) {
    var result = [];
    var ovIndex = 0;

    for (var i = 0; i < translations.length; i++) {
      var translationLine = translations[i];
      var ovText = ovTranslations[ovIndex].text;
      ovIndex++;
      var translation3x = SubtitleService.to3xPrintTranslation(translationLine, ovText);
      translation3x.line = i + 1;
      result.push(translation3x);
    }

    return result;
  },

  to3xPrintTranslation(translationLine, ovTranslationText) {
    var ovText = ovTranslationText.trim();
    var text = translationLine.text.trim();
    var translationText = to3xTranslationText(text);
    var translationFrom = translationLine.auto ? 'tm' : translationLine.machine ? 'mt' : SubtitleService.isCellTranslated({
      ovText,
      translationText
    }) ? 'custom' : 'ov';
    var position = translationLine.position;
    return {
      ovText,
      translationText,
      translationFrom,
      position
    };
  },

  to2xPrintTranslation(translation) {
    var text = (0, _stripTagsService.default)(translation.translationText, '<div><br><b><i><u><span>').replace(/&nbsp;/g, '').replace(/\r\n/g, '').replace(/\n/g, '');
    return {
      text,
      auto: translation.translationFrom === 'tm',
      custom: translation.translationFrom === 'custom',
      machine: translation.translationFrom === 'mt',
      alignment: 'bottom',
      position: translation.position
    };
  },

  resetToOv(translationLine) {
    var ovText = translationLine.ovText.indexOf('<div>') === 0 ? translationLine.ovText : "<div>".concat(translationLine.ovText, "</div>");
    return _objectSpread(_objectSpread({}, translationLine), {}, {
      translationText: ovText,
      translationFrom: 'ov',
      auto: false,
      machine: false
    });
  },

  isCellTranslated(translation) {
    var {
      ovText,
      translationText
    } = translation;
    var strippedOvText = ovText.replace(/\s+/g, '').replace(/<div>|<\/div>/g, '');
    var strippedTranslationText = translationText.replace(/\s+/g, '').replace(/<div>|<\/div>/g, '');
    return strippedOvText !== strippedTranslationText;
  },

  timecodeToOffset(timecode) {
    var [timeHms, timeFrac] = timecode.split(',');
    var [timeHr, timeMin, timeSec] = timeHms.split(':');
    var inSeconds = parseInt(timeHr) * 3600 + parseInt(timeMin) * 60 + parseInt(timeSec) + parseInt(timeFrac) * 0.001;
    return inSeconds;
  },

  offsetToTimecode(offset) {
    var timeHr = String(Math.trunc(offset / 3600));
    var timeMin = String(Math.trunc((offset - timeHr * 3600) / 60));
    var timeSec = String(Math.trunc(offset - timeHr * 3600 - timeMin * 60));
    var timeFrac = String(offset).split('.')[1] || '000';
    var hh = (0, _lodash2.default)(timeHr, 2, '0');
    var mm = (0, _lodash2.default)(timeMin, 2, '0');
    var ss = (0, _lodash2.default)(timeSec, 2, '0');
    var frac = (0, _lodash3.default)(timeFrac.substr(0, 3), 3, '0');
    return "".concat(hh, ":").concat(mm, ":").concat(ss, ",").concat(frac);
  },

  /**
   * Transforms 3x subtitles to WebVTT format base64-encoded string
   */
  subsToEncodedVtt(translations) {
    var result = 'WEBVTT\n\n';
    translations.forEach(line => {
      if (line.ovText === undefined) {
        return;
      }

      var text = line.automatedLocalization && line.gfx && !line.showSubs ? '' : this.htmlToVtt(line.translationText); // TODO: adjust for offsets

      var inTime = _timeFormatService.default.normalize(line.inTime);

      var outTime = _timeFormatService.default.normalize(line.outTime);

      result += inTime + ' --> ' + outTime + '\n';
      result += text + '\n\n';
    });
    return 'data:text/plain;base64,' + b64EncodeUnicode(result);
  },

  htmlToVtt(str) {
    var _result, _result2;

    // Convert </div> and <br> to newlines
    var result = str === null || str === void 0 ? void 0 : str.replace(/<br><\/div>/g, '\n');
    result = (_result = result) === null || _result === void 0 ? void 0 : _result.replace(/<br>/g, '\n');
    result = (_result2 = result) === null || _result2 === void 0 ? void 0 : _result2.replace(/<\/div>/g, '\n'); // Strip any remaining tags or HTML entities other than <b> <i> and <u>

    result = _lodash.default.unescape((0, _stripTagsService.default)(result, '<b><i><u>'));
    return result;
  },

  timecodeConvertFps(time, fps) {
    var result = time.split(',');
    result[1] = parseInt(result[1] * fps / 1000);

    if (result[1] < 10) {
      result[1] = '0' + result[1];
    }

    return fps === 29.97 ? result.join(';') : result.join(':');
  },

  splitSegment(translations, i) {
    var fps = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 24;
    var beforeSplit = [...translations];
    var toSplitArr = beforeSplit.splice(i);
    var afterSplit = toSplitArr.splice(1).map(ln => _objectSpread(_objectSpread({}, ln), {}, {
      line: ln.line + 1
    }));
    var toSplit = toSplitArr[0];
    var splitInSec = SubtitleService.timecodeToOffset(toSplit.inTime);
    var splitOutSec = SubtitleService.timecodeToOffset(toSplit.outTime);
    var durationSec = (splitOutSec - splitInSec) / 2;
    var newOutTime = SubtitleService.offsetToTimecode(splitInSec + durationSec);
    var newInTime = SubtitleService.offsetToTimecode(splitInSec + durationSec + 1 / fps);
    var split = [_objectSpread(_objectSpread({}, toSplit), {}, {
      inTime: toSplit.inTime,
      outTime: newOutTime,
      split: toSplit.ovText
    }), _objectSpread(_objectSpread({}, toSplit), {}, {
      inTime: newInTime,
      outTime: toSplit.outTime,
      line: toSplit.line + 1,
      split: toSplit.ovText
    })];
    return [...beforeSplit, ...split, ...afterSplit];
  },

  mergeDownSegment(translations, i) {
    var beforeMerge = [...translations];
    var toMerge = beforeMerge.splice(i);
    var afterMerge = toMerge.splice(2).map(ln => _objectSpread(_objectSpread({}, ln), {}, {
      line: ln.line - 1
    })); // combine OV text with space between

    var ovText = toMerge.map(ln => ln.ovText).join(' '); // combine translation text w/o space (wrapped in divs)

    var translationText = toMerge.map(ln => ln.translationText).join('');
    var merge = [_objectSpread(_objectSpread({}, toMerge[0]), {}, {
      ovText,
      translationText,
      outTime: toMerge[1].outTime,
      split: null,
      merged: [toMerge[0].ovText, toMerge[1].ovText]
    })];
    return [...beforeMerge, ...merge, ...afterMerge];
  },

  revertMerge(translations, i) {
    var fps = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 24;
    var beforeSplit = [...translations];
    var toSplitArr = beforeSplit.splice(i);
    var afterSplit = toSplitArr.splice(1).map(ln => _objectSpread(_objectSpread({}, ln), {}, {
      line: ln.line + 1
    }));
    var toSplit = toSplitArr[0];
    var splitInSec = SubtitleService.timecodeToOffset(toSplit.inTime);
    var splitOutSec = SubtitleService.timecodeToOffset(toSplit.outTime);
    var durationSec = (splitOutSec - splitInSec) / 2;
    var newOutTime = SubtitleService.offsetToTimecode(splitInSec + durationSec);
    var newInTime = SubtitleService.offsetToTimecode(splitInSec + durationSec + 1 / fps); // `merged` value is array of original segments' OV text

    var ovTextArr = toSplit.merged; // wrap OV text in divs

    var translationTextArr = ovTextArr.map(ovText => to3xTranslationText(ovText));
    var split = [_objectSpread(_objectSpread({}, toSplit), {}, {
      ovText: ovTextArr[0],
      translationText: translationTextArr[0],
      inTime: toSplit.inTime,
      outTime: newOutTime,
      split: null,
      merged: null,
      auto: false,
      machine: false,
      translationFrom: 'ov'
    }), _objectSpread(_objectSpread({}, toSplit), {}, {
      ovText: ovTextArr[1],
      translationText: translationTextArr[1],
      inTime: newInTime,
      outTime: toSplit.outTime,
      line: toSplit.line + 1,
      split: null,
      merged: null,
      auto: false,
      machine: false,
      translationFrom: 'ov'
    })];
    return [...beforeSplit, ...split, ...afterSplit];
  },

  revertSplit(translations, i) {
    var beforeMerge = [...translations];
    var toMerge = beforeMerge.splice(i);
    var afterMerge = toMerge.splice(2).map(ln => _objectSpread(_objectSpread({}, ln), {}, {
      line: ln.line - 1
    })); // `split` value is original OV text

    var ovText = toMerge[0].split; // wrap original OV text in divs

    var translationText = to3xTranslationText(ovText);
    var merge = [_objectSpread(_objectSpread({}, toMerge[0]), {}, {
      ovText,
      translationText,
      outTime: toMerge[1].outTime,
      split: null,
      merged: null,
      auto: false,
      machine: false,
      translationFrom: 'ov'
    })];
    return [...beforeMerge, ...merge, ...afterMerge];
  },

  getPrintSubtitlerMode(printTranslation) {
    if (printTranslation === 'full') {
      return 'print';
    }

    if (printTranslation === 'date') {
      return 'printdate';
    }

    return null;
  }

};
var _default = SubtitleService;
/**
 * Escapes Unicode characters to prevent `Character Out Of Range` exception
 * See: https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding#The_Unicode_Problem
 * @param {string} str - string to encode
 * @returns {string} - encoded string
 */

exports.default = _default;

function b64EncodeUnicode(str) {
  return window.btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function toSolidBytes(match, p1) {
    return String.fromCharCode('0x' + p1);
  }));
}
/**
 * If the text has a div, it's likely already been converted to 3x. If it
 * doesn't, then replace all the carriage returns and line feeds with divs
 * @param {string} text translation to convert
 */


function to3xTranslationText(text) {
  return (text !== null && text !== void 0 ? text : '').indexOf('<div>') === 0 ? text : text.replace(/\r/g, '').split('\n').map(t => "<div>".concat(t, "</div>")).join('');
}