"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PREVIEW_WATERMARK_FORMAT = exports.POSTHOG_TOKEN = exports.POSTHOG_ENABLED = exports.POSTHOG_CONFIG = exports.OPENREPLAY_URL = exports.OPENREPLAY_KEY = exports.NTSC = exports.MESSAGE_ENDPOINT = exports.LOG_ROCKET_ENABLED = exports.LOG_ROCKET_APP_ID = exports.LANGUAGE_LABELS = exports.LANGUAGES = exports.JWPLAYER_KEY = exports.IntercomAPI = exports.INTERCOM_ENABLED = exports.ICON_USER = exports.ICON_GROUP = exports.CLERK_PUBLISH_KEY = exports.CATALOG_MANIFEST = exports.ASPERA_INSTALLER_ROOT = exports.ASPERA_DOWNLOAD_ROOT = void 0;
window.env = 'staging';
window.version = '';
var posthogEnabled = env === 'production' || env === 'staging';
var logRocketEnabled = env === 'production' || env === 'staging';
var intercomEnabled = env !== 'development';
var CLERK_PUBLISH_KEY = "pk_test_Zmxvd2luZy1jcmF5ZmlzaC03NS5jbGVyay5hY2NvdW50cy5kZXYk";
exports.CLERK_PUBLISH_KEY = CLERK_PUBLISH_KEY;
var OPENREPLAY_KEY = 'U0xxno52HZG7hqKPchbR';
exports.OPENREPLAY_KEY = OPENREPLAY_KEY;
var OPENREPLAY_URL = 'https://openreplay.pixwel.com/ingest';
exports.OPENREPLAY_URL = OPENREPLAY_URL;
var POSTHOG_TOKEN = 'phc_zgDxtfXLNAP5NXvsn9xRCWI2tKyMlYRyvOesqHYzPLX';
exports.POSTHOG_TOKEN = POSTHOG_TOKEN;
var POSTHOG_CONFIG = {
  api_host: 'https://posthog.pixwel.com'
};
exports.POSTHOG_CONFIG = POSTHOG_CONFIG;
var MESSAGE_ENDPOINT = 'https://notifications.pixwel.com/staging/faye';
exports.MESSAGE_ENDPOINT = MESSAGE_ENDPOINT;
var ASPERA_INSTALLER_ROOT = '//d3gcli72yxqn2z.cloudfront.net/connect/';
exports.ASPERA_INSTALLER_ROOT = ASPERA_INSTALLER_ROOT;
var ASPERA_DOWNLOAD_ROOT = 'ats-aws-us-west-2.aspera.io';
exports.ASPERA_DOWNLOAD_ROOT = ASPERA_DOWNLOAD_ROOT;
var ICON_USER = 'icon-user';
exports.ICON_USER = ICON_USER;
var ICON_GROUP = 'icon-globe';
exports.ICON_GROUP = ICON_GROUP;
var JWPLAYER_KEY = 'DdkKJYHp0YIPk/58zT7tGD6gHSWfKbJppglfg7yskmM=';
exports.JWPLAYER_KEY = JWPLAYER_KEY;
var IntercomAPI = 'ef30d123f304de60eb93ebdb931cc694dab2e19c';
exports.IntercomAPI = IntercomAPI;
var INTERCOM_ENABLED = intercomEnabled;
exports.INTERCOM_ENABLED = INTERCOM_ENABLED;
var LOG_ROCKET_ENABLED = logRocketEnabled;
exports.LOG_ROCKET_ENABLED = LOG_ROCKET_ENABLED;
var LOG_ROCKET_APP_ID = 'bkntis/platform';
exports.LOG_ROCKET_APP_ID = LOG_ROCKET_APP_ID;
var POSTHOG_ENABLED = posthogEnabled;
exports.POSTHOG_ENABLED = POSTHOG_ENABLED;
var LANGUAGES = {
  'ar': 'ARA',
  'bg': 'BUL',
  'ca': 'CAT',
  'cs': 'CZE',
  'cy': 'CYM',
  'da': 'DAN',
  'de': 'GER',
  'el': 'GRK',
  'en': 'ENG',
  'es': 'CSP',
  'es-mx': 'LAS',
  'et': 'EST',
  'fi': 'FIN',
  'fr': 'PFR',
  'fr-ca': 'CFR',
  'he': 'HEB',
  'hi': 'HIN',
  'hr': 'CRO',
  'hu': 'HUN',
  'id': 'BAH',
  'is': 'ICE',
  'it': 'ITA',
  'ja': 'JPN',
  'ko': 'KOR',
  'lt': 'LIT',
  'lv': 'LAT',
  'ml': 'MLM',
  'nl': 'DUT',
  'nl-be': 'FLE',
  'no': 'NOR',
  'pl': 'POL',
  'pt': 'EPT',
  'pt-br': 'BPO',
  'ro': 'ROM',
  'ru': 'RUS',
  'sk': 'SLK',
  'sl': 'SLN',
  'sr': 'SER',
  'sv': 'SWE',
  'ta-in': 'TAM',
  'te': 'TEL',
  'th': 'THA',
  'tr': 'TUR',
  'uk': 'UKR',
  'vi': 'VIE',
  'zh-cn': 'PRC',
  'zh-hk': 'CAN',
  'zh-tw': 'MAN'
};
exports.LANGUAGES = LANGUAGES;
var NTSC = {
  "pt-br": true,
  "fr-ca": true,
  is: true,
  ja: true,
  ko: true,
  mx: true,
  "zh-tw": true,
  "en-us": true
};
exports.NTSC = NTSC;
var LANGUAGE_LABELS = [{
  lang: "en",
  label: "English"
}, {
  lang: "fr",
  label: "Français"
}, {
  lang: "es",
  label: "Español"
}, {
  lang: "pt-br",
  label: "Português Brasileiro"
}, {
  lang: "ja",
  label: "日本語"
}, {
  lang: "it",
  label: "Italiano"
}, {
  lang: "ru",
  label: "русский"
}, {
  lang: "iw",
  label: "עברית"
}, {
  lang: "zh-CN",
  label: "中国的"
}, {
  lang: "de",
  label: "Deutsch"
}];
exports.LANGUAGE_LABELS = LANGUAGE_LABELS;
var PREVIEW_WATERMARK_FORMAT = {
  default: 'FULL_SCREEEN_DIAGONAL',
  'FULL_SCREEEN_DIAGONAL': {
    label: 'Full Screen Diagonal',
    class: 'px-watermark-full-screen-diagonal'
  },
  'SINGLE_CENTER': {
    label: 'Single Center',
    class: 'px-watermark-single-center'
  },
  'SINGLE_TOP_MIDDLE': {
    label: 'Single Top Middle',
    class: 'px-watermark-single-top-middle'
  },
  'SINGLE_BOTTOM_MIDDLE': {
    label: 'Single Bottom Middle',
    class: 'px-watermark-single-bottom-middle'
  }
};
exports.PREVIEW_WATERMARK_FORMAT = PREVIEW_WATERMARK_FORMAT;
var CATALOG_MANIFEST = [{
  lang: "en",
  url: "https://d1r87wbxedxibq.cloudfront.net/catalogs/lang-en.json"
}, {
  lang: "fr",
  url: "https://d1r87wbxedxibq.cloudfront.net/catalogs/lang-fr.json"
}, {
  lang: "es",
  url: "https://d1r87wbxedxibq.cloudfront.net/catalogs/lang-es.json"
}, {
  lang: "ja",
  url: "https://d1r87wbxedxibq.cloudfront.net/catalogs/lang-ja.json"
}, {
  lang: "pt-br",
  url: "https://d1r87wbxedxibq.cloudfront.net/catalogs/lang-pt-br.json"
}, {
  lang: "it",
  url: "https://d1r87wbxedxibq.cloudfront.net/catalogs/lang-it.json"
}, {
  lang: "ru",
  url: "https://d1r87wbxedxibq.cloudfront.net/catalogs/lang-ru.json"
}, {
  lang: "iw",
  url: "https://d1r87wbxedxibq.cloudfront.net/catalogs/lang-iw.json"
}, {
  lang: "zh-CN",
  url: "https://d1r87wbxedxibq.cloudfront.net/catalogs/lang-zh-CN.json"
}, {
  lang: "de",
  url: "https://d1r87wbxedxibq.cloudfront.net/catalogs/lang-de.json"
}];
exports.CATALOG_MANIFEST = CATALOG_MANIFEST;