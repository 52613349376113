"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _apiService = _interopRequireDefault(require("./api-service"));

var _translationReplicator = _interopRequireDefault(require("./translation-replicator"));

var _stripTagsService = _interopRequireDefault(require("./strip-tags-service"));

var _srtService = _interopRequireDefault(require("./srt-service"));

var _lodash = _interopRequireDefault(require("lodash"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var TranslationService = {
  getById(id) {
    return _apiService.default.client.get("/translations/".concat(id)).then(res => res.data).catch(e => {
      if (e.status === 404) {
        return null;
      }

      throw e;
    });
  },

  isSubtitlerTranslation(translation) {
    return translation && !translation.file && translation.lines && (translation.status === 'submitted' || translation.status === 'complete');
  },

  isCustomTranslation(translation) {
    return !!(translation !== null && translation !== void 0 && translation.file);
  },

  getTranslationMemories(id, language) {
    return _apiService.default.client.get("/translations/translate?id=".concat(id, "&language=").concat(language)).then(_ref => {
      var {
        data
      } = _ref;
      return data;
    });
  },

  getMachineTranslations(id, language) {
    return _apiService.default.client.get("/translations/translate?id=".concat(id, "&language=").concat(language, "&machine=true")).then(_ref2 => {
      var {
        data
      } = _ref2;
      return data;
    });
  },

  getTranslationLanguages() {
    return _apiService.default.client.get("/translations/languages").then(_ref3 => {
      var {
        data
      } = _ref3;
      return data;
    });
  },

  newlineToDivs(value) {
    var translationText = value ? value.includes('<div>') ? value : "<div>".concat(value, "</div>") : '<div></div>'; // since we prefer divs over newlines, convert them

    translationText = translationText.replace(/\r\n/g, '</div><div>');
    translationText = translationText.replace(/\r/g, '</div><div>');
    translationText = translationText.replace(/\n/g, '</div><div>'); // remove any useless empty divs

    return translationText.replace(/<div><\/div>/g, '');
  },

  autoTranslate(subtitles, applyTranslationMemories, applyMachineTranslations) {
    var {
      translations,
      tmTranslations,
      machineTranslations,
      ovTranslations
    } = subtitles;
    return _lodash.default.map(translations, (translation, i) => {
      var _ovTranslations$print, _ovTranslations$print2, _ovTranslations$lines;

      if (translation.translationFrom === 'custom') {
        return translation;
      }

      var ovText = translation.ovText || ((_ovTranslations$print = ovTranslations.printLines) === null || _ovTranslations$print === void 0 ? void 0 : (_ovTranslations$print2 = _ovTranslations$print[i]) === null || _ovTranslations$print2 === void 0 ? void 0 : _ovTranslations$print2.text) || ((_ovTranslations$lines = ovTranslations.lines[i]) === null || _ovTranslations$lines === void 0 ? void 0 : _ovTranslations$lines.text);
      var tmTranslationText = tmTranslations[i];
      var mtTranslationText = machineTranslations[i];
      var translationFrom;
      var translationText;
      var auto;
      var machine;

      if (applyMachineTranslations && mtTranslationText) {
        translationText = this.newlineToDivs(mtTranslationText);
        translationFrom = 'mt';
        auto = false;
        machine = true;
      }

      if (applyTranslationMemories && tmTranslationText && (0, _stripTagsService.default)(tmTranslationText).length > 0) {
        translationText = this.newlineToDivs(tmTranslationText);
        translationFrom = 'tm';
        auto = true;
        machine = false;
      }

      if (!translationText) {
        translationText = ovText;
        translationFrom = 'ov';
        auto = false;
        machine = false;
      }

      return _objectSpread(_objectSpread({}, translation), {}, {
        translationFrom,
        translationText,
        auto,
        machine
      });
    });
  },

  createTranslation(data) {
    return _apiService.default.client.post("/translations", data);
  },

  updateTranslation(id, data) {
    return _apiService.default.client.patch("/translations/".concat(id), data);
  },

  translationReplicator(asset, translation) {
    var type = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'srt';
    return _translationReplicator.default.translate(asset, translation, type);
  },

  stripTags(text) {
    return (0, _stripTagsService.default)(text, '').replace(/&nbsp;/g, '').replace(/\r\n/g, '').replace(/\n/g, '');
  },

  downloadFile: (data, type, filename) => {
    var element = document.createElement('a');
    var file = new Blob([data], {
      type: type
    });
    element.href = URL.createObjectURL(file);
    element.download = filename;
    document.body.appendChild(element);
    element.click();
  },
  downloadSrt: function () {
    var _downloadSrt = (0, _asyncToGenerator2.default)(function* (translationId, filename) {
      var translation = yield TranslationService.getById(translationId);
      TranslationService.downloadFile(_srtService.default.export(translation.lines), 'application/x-subrip', filename + '.srt');
    });

    function downloadSrt(_x, _x2) {
      return _downloadSrt.apply(this, arguments);
    }

    return downloadSrt;
  }()
};
var _default = TranslationService;
exports.default = _default;