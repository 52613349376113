"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.actions = exports.STORE_NAME = exports.REFETCH_ASSET_SUCCESS = exports.REFETCH_ASSET_REQUEST = exports.REFETCH_ASSET_FAILURE = exports.REFETCH_ASSET = exports.LOAD_SUPPLEMENTAL_SUCCESS = exports.LOAD_SUPPLEMENTAL_REQUEST = exports.LOAD_SUPPLEMENTAL_FAILURE = exports.LOAD_SUPPLEMENTAL = exports.LOAD_PAGE_SUCCESS = exports.LOAD_PAGE_REQUEST = exports.LOAD_PAGE_FAILURE = exports.LOAD_PAGE = exports.INITIAL_STATE = void 0;
exports.reducer = reducer;
exports.selectors = void 0;
exports.watchAssetPage = watchAssetPage;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _lodash = _interopRequireDefault(require("lodash"));

var _lodash2 = _interopRequireDefault(require("lodash.iserror"));

var _reduxActions = require("redux-actions");

var _effects = require("redux-saga/effects");

var _common = require("./common");

var _projects = require("./projects");

var _assets = require("./assets");

var _previews = require("./previews");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var STORE_NAME = 'assetPageStore';
exports.STORE_NAME = STORE_NAME;

function* refetchAsset(_ref) {
  var {
    payload: assetId
  } = _ref;

  try {
    yield (0, _effects.put)(internalActions.refetchAssetRequest());
    var assetTask = yield (0, _effects.fork)(_assets.fetchAsset, _assets.actions.getAssetById({
      assetId
    }));
    var asset = yield (0, _effects.join)(assetTask);

    if ((0, _lodash2.default)(asset)) {
      throw new Error('Failed to load asset data');
    }

    yield (0, _effects.put)(internalActions.refetchAssetSuccess());
  } catch (e) {
    var err = (0, _common.handleError)(e);
    yield (0, _effects.put)(internalActions.refetchAssetFailure(err));
    return err;
  }
}

function* loadPageData(_ref2) {
  var {
    payload: assetId
  } = _ref2;

  try {
    yield (0, _effects.put)(internalActions.loadPageRequest());
    var assetTask = yield (0, _effects.fork)(_assets.fetchAsset, _assets.actions.getAssetById({
      assetId
    }));
    var previewsTask = yield (0, _effects.fork)(_previews.fetchPreviews, _previews.actions.getAssetPreviews({
      assetId
    }));
    var asset = yield (0, _effects.join)(assetTask);
    var previews = yield (0, _effects.join)(previewsTask);

    if ((0, _lodash2.default)(asset) || (0, _lodash2.default)(previews)) {
      throw new Error('Failed to load asset page data');
    }

    yield (0, _effects.put)(internalActions.loadPageSuccess());
  } catch (e) {
    var err = (0, _common.handleError)(e);
    yield (0, _effects.put)(internalActions.loadPageFailure(err));
    return err;
  }
}

function* loadSupplementalData(_ref3) {
  var {
    payload: projectId
  } = _ref3;

  try {
    yield (0, _effects.put)(internalActions.loadSupplementalDataRequest());
    var project = yield (0, _effects.call)(_projects.fetchProjectById, _projects.actions.getProjectById(projectId));

    if ((0, _lodash2.default)(project)) {
      throw new Error('Failed to load related assets data. Project data failed to load.');
    }

    yield (0, _effects.put)(internalActions.loadSupplementalDataSuccess());
  } catch (e) {
    var err = (0, _common.handleError)(e);
    yield (0, _effects.put)(internalActions.loadSupplementalDataFailure(err));
    return err;
  }
}

var LOAD_PAGE = 'asset-page.load-page';
exports.LOAD_PAGE = LOAD_PAGE;
var LOAD_PAGE_REQUEST = 'asset-page.load-page.request';
exports.LOAD_PAGE_REQUEST = LOAD_PAGE_REQUEST;
var LOAD_PAGE_SUCCESS = 'asset-page.load-page.success';
exports.LOAD_PAGE_SUCCESS = LOAD_PAGE_SUCCESS;
var LOAD_PAGE_FAILURE = 'asset-page.load-page.failure';
exports.LOAD_PAGE_FAILURE = LOAD_PAGE_FAILURE;
var REFETCH_ASSET = 'asset-page.refetch-asset';
exports.REFETCH_ASSET = REFETCH_ASSET;
var REFETCH_ASSET_REQUEST = 'asset-page.refetch-asset.request';
exports.REFETCH_ASSET_REQUEST = REFETCH_ASSET_REQUEST;
var REFETCH_ASSET_SUCCESS = 'asset-page.refetch-asset.success';
exports.REFETCH_ASSET_SUCCESS = REFETCH_ASSET_SUCCESS;
var REFETCH_ASSET_FAILURE = 'asset-page.refetch-asset.failure';
exports.REFETCH_ASSET_FAILURE = REFETCH_ASSET_FAILURE;
var LOAD_SUPPLEMENTAL = 'asset-page.load-supplemental';
exports.LOAD_SUPPLEMENTAL = LOAD_SUPPLEMENTAL;
var LOAD_SUPPLEMENTAL_REQUEST = 'asset-page.load-supplemental.request';
exports.LOAD_SUPPLEMENTAL_REQUEST = LOAD_SUPPLEMENTAL_REQUEST;
var LOAD_SUPPLEMENTAL_SUCCESS = 'asset-page.load-supplemental.success';
exports.LOAD_SUPPLEMENTAL_SUCCESS = LOAD_SUPPLEMENTAL_SUCCESS;
var LOAD_SUPPLEMENTAL_FAILURE = 'asset-page.load-supplemental.failure';
exports.LOAD_SUPPLEMENTAL_FAILURE = LOAD_SUPPLEMENTAL_FAILURE;
var INITIAL_STATE = {
  isLoading: false,
  loadFailed: false,
  isSupplementalDataLoading: false,
  didSupplementalDataFail: false
};
exports.INITIAL_STATE = INITIAL_STATE;

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case LOAD_PAGE_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });

    case LOAD_PAGE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false,
        loadFailed: false
      });

    case LOAD_PAGE_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false,
        loadFailed: true
      });

    case REFETCH_ASSET_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });

    case REFETCH_ASSET_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false,
        loadFailed: false
      });

    case REFETCH_ASSET_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false,
        loadFailed: true
      });

    case LOAD_SUPPLEMENTAL_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        isSupplementalDataLoading: true
      });

    case LOAD_SUPPLEMENTAL_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isSupplementalDataLoading: false,
        didSupplementalDataFail: false
      });

    case LOAD_SUPPLEMENTAL_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isSupplementalDataLoading: false,
        didSupplementalDataFail: true
      });

    default:
      return state;
  }
}

var actions = {
  loadPage: (0, _reduxActions.createAction)(LOAD_PAGE),
  refetchAsset: (0, _reduxActions.createAction)(REFETCH_ASSET),
  loadSupplementalData: (0, _reduxActions.createAction)(LOAD_SUPPLEMENTAL)
};
/**
 * Actions that should only be invoked internally
 */

exports.actions = actions;
var internalActions = {
  loadPageRequest: (0, _reduxActions.createAction)(LOAD_PAGE_REQUEST),
  loadPageSuccess: (0, _reduxActions.createAction)(LOAD_PAGE_SUCCESS),
  loadPageFailure: (0, _reduxActions.createAction)(LOAD_PAGE_FAILURE),
  refetchAssetRequest: (0, _reduxActions.createAction)(REFETCH_ASSET_REQUEST),
  refetchAssetSuccess: (0, _reduxActions.createAction)(REFETCH_ASSET_SUCCESS),
  refetchAssetFailure: (0, _reduxActions.createAction)(REFETCH_ASSET_FAILURE),
  loadSupplementalDataRequest: (0, _reduxActions.createAction)(LOAD_SUPPLEMENTAL_REQUEST),
  loadSupplementalDataSuccess: (0, _reduxActions.createAction)(LOAD_SUPPLEMENTAL_SUCCESS),
  loadSupplementalDataFailure: (0, _reduxActions.createAction)(LOAD_SUPPLEMENTAL_FAILURE)
};
var selectors = {
  isPageLoading,
  isPageReady,
  isPageLoadError,
  isSupplementalDataError,
  doesPageNeedRefresh,
  isSupplementalDataLoading,
  isSupplementalDataReady,
  doesSupplementalDataNeedRefresh,
  getSimilarAssets
};
exports.selectors = selectors;

function isPageLoading(state) {
  var {
    assetPageStore
  } = state;
  return assetPageStore.isLoading;
}

function isPageReady(state, assetId, sessionDataLastLoadedAt) {
  var isAssetLoaded = _assets.selectors.isAssetLoaded(state, assetId);

  var arePreviewsLoaded = _previews.selectors.areAssetPreviewsLoaded(state, assetId);

  var isSessionLoaded = !!sessionDataLastLoadedAt;

  if (!isAssetLoaded || !arePreviewsLoaded || !isSessionLoaded) {
    return false;
  }

  var assetLastLoadedAt = _assets.selectors.getAssetLastLoadedAt(state, assetId);

  var previewsLastLoadedAt = _previews.selectors.getAssetPreviewsLastLoadedAt(state, assetId);

  var now = new Date();
  var isAssetsDataStale = now - assetLastLoadedAt > 7200000;
  var isPreviewsDataStale = now - previewsLastLoadedAt > 7200000;
  var isSessionDataStale = now - sessionDataLastLoadedAt > 7200000; // If the data has been loaded within the last past two hours, its ready

  return !isAssetsDataStale && !isPreviewsDataStale && !isSessionDataStale;
}

function isPageLoadError(state) {
  var {
    assetPageStore
  } = state;
  return assetPageStore.loadFailed;
}

function isSupplementalDataError(state) {
  var {
    assetPageStore
  } = state;
  return assetPageStore.didSupplementalDataFail;
}

function doesPageNeedRefresh(state, assetId, sessionDataLastLoadedAt) {
  var isAssetLoaded = _assets.selectors.isAssetLoaded(state, assetId);

  var arePreviewsLoaded = _previews.selectors.areAssetPreviewsLoaded(state, assetId);

  var isSessionLoaded = !!sessionDataLastLoadedAt;

  if (!isAssetLoaded || !arePreviewsLoaded || !isSessionLoaded) {
    return true;
  }

  var assetLastLoadedAt = _assets.selectors.getAssetLastLoadedAt(state, assetId);

  var previewsLastLoadedAt = _previews.selectors.getAssetPreviewsLastLoadedAt(state, assetId);

  var now = new Date();
  var isAssetsDataStale = now - assetLastLoadedAt > 1800000;
  var isPreviewsDataStale = now - previewsLastLoadedAt > 1800000;
  var isSessionDataStale = now - sessionDataLastLoadedAt > 1800000; // If the data has been loaded within the last past two hours, its ready

  return isAssetsDataStale || isPreviewsDataStale || isSessionDataStale;
}

function isSupplementalDataLoading(state) {
  return state[STORE_NAME].isSupplementalDataLoading;
}

function isSupplementalDataReady(state, projectId) {
  var project = _projects.selectors.getProjectById(state, projectId);

  if (!project) {
    return false;
  }

  var areAssetsLoaded = _assets.selectors.areProjectAssetsLoaded(state, project._id);

  if (!areAssetsLoaded) {
    return false;
  }

  var assetsLastLoadedAt = _assets.selectors.getProjectAssetsLastLoadedAt(state, project._id);

  var now = new Date();
  var isAssetsDataStale = now - assetsLastLoadedAt > 7200000;
  return !isAssetsDataStale;
}

function doesSupplementalDataNeedRefresh(state, projectId) {
  var project = _projects.selectors.getProjectById(state, projectId);

  if (!project) {
    return true;
  }

  var areAssetsLoaded = _assets.selectors.areProjectAssetsLoaded(state, project._id);

  if (!areAssetsLoaded) {
    return true;
  }

  var assetsLastLoadedAt = _assets.selectors.getProjectAssetsLastLoadedAt(state, project._id);

  var now = new Date();
  var isAssetsDataStale = now - assetsLastLoadedAt > 1800000;
  return isAssetsDataStale;
}

function getSimilarAssets(state, asset) {
  if (!asset) {
    return [];
  }

  var assets = _assets.selectors.getProjectAssets(state, asset.project);

  var filteredAssets = _lodash.default.filter(assets, a => a.type === asset.type && a._id !== asset._id);

  return filteredAssets;
}

function* watchAssetPage() {
  yield (0, _effects.takeLatest)(LOAD_PAGE, loadPageData);
  yield (0, _effects.takeLatest)(REFETCH_ASSET, refetchAsset);
  yield (0, _effects.takeLatest)(LOAD_SUPPLEMENTAL, loadSupplementalData);
}