"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.actions = exports.STORE_NAME = exports.RESET_STORES = exports.INITIAL_STATE = exports.FETCH_FILES_SUCCESS = exports.FETCH_FILES_REQUEST = exports.FETCH_FILES_FAILURE = exports.FETCH_FILES = void 0;
exports.reducer = reducer;
exports.selectors = void 0;
exports.watchFiles = watchFiles;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _lodash = _interopRequireDefault(require("lodash"));

var _lodash2 = _interopRequireDefault(require("lodash.get"));

var _reduxActions = require("redux-actions");

var _effects = require("redux-saga/effects");

var _common = require("./common");

var _fileService = _interopRequireDefault(require("~/app/3x/modules/services/file-service"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var RESET_STORES = 'session.reset-stores';
exports.RESET_STORES = RESET_STORES;
var STORE_NAME = 'filesStore';
exports.STORE_NAME = STORE_NAME;

function* fetchFiles(_ref) {
  var {
    payload
  } = _ref;
  var {
    assetId,
    language
  } = payload;

  try {
    yield (0, _effects.put)(internalActions.getAssetFilesRequest({
      assetId,
      language
    }));
    var files = yield _fileService.default.getAllFiles({
      asset: assetId,
      language
    });
    yield (0, _effects.put)(internalActions.getAssetFilesSuccess({
      assetId,
      language,
      files
    }));
    return files;
  } catch (e) {
    var err = (0, _common.handleError)(e);
    yield (0, _effects.put)(internalActions.getAssetFilesFailure({
      assetId,
      language,
      err
    }));
    return err;
  }
}

var FETCH_FILES = 'files.fetch';
exports.FETCH_FILES = FETCH_FILES;
var FETCH_FILES_REQUEST = 'files.fetch.request';
exports.FETCH_FILES_REQUEST = FETCH_FILES_REQUEST;
var FETCH_FILES_SUCCESS = 'files.fetch.success';
exports.FETCH_FILES_SUCCESS = FETCH_FILES_SUCCESS;
var FETCH_FILES_FAILURE = 'files.fetch.failure';
exports.FETCH_FILES_FAILURE = FETCH_FILES_FAILURE;
var INITIAL_STATE = {
  files: {},
  filesByAsset: {}
};
exports.INITIAL_STATE = INITIAL_STATE;

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : INITIAL_STATE;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case RESET_STORES:
      return INITIAL_STATE;

    case FETCH_FILES_REQUEST:
      {
        var {
          assetId,
          language
        } = action.payload;
        var languageContainer = state.filesByAsset[assetId] || {
          filesByLanguage: {}
        };
        var filesByLanguage = languageContainer.filesByLanguage;
        return _objectSpread(_objectSpread({}, state), {}, {
          filesByAsset: _objectSpread(_objectSpread({}, state.filesByAsset), {}, {
            [assetId]: _objectSpread(_objectSpread({}, languageContainer), {}, {
              filesByLanguage: _objectSpread(_objectSpread({}, filesByLanguage), {}, {
                [language]: _objectSpread(_objectSpread({
                  // Place lastLoadedAt: null and files: [] first
                  // so that its overwritten if it already exists
                  lastLoadedAt: null,
                  files: []
                }, filesByLanguage[language]), {}, {
                  isLoading: true
                })
              })
            })
          })
        });
      }

    case FETCH_FILES_SUCCESS:
      {
        var {
          assetId: _assetId,
          language: _language,
          files
        } = action.payload;

        var _languageContainer = state.filesByAsset[_assetId] || {
          filesByLanguage: {}
        };

        var _filesByLanguage = _languageContainer.filesByLanguage;
        var now = new Date();

        var fileIds = _lodash.default.map(files, '_id');

        var transformedFiles = _lodash.default.reduce(files, (result, file) => {
          result[file._id] = {
            isLoading: false,
            lastLoadedAt: now,
            data: file
          };
          return result;
        }, {}); // Remove all existing files for the given asset and language (they will be replaced with our new results)


        var removeFileIds = (0, _lodash2.default)(state.filesByAsset, [_assetId, 'filesByLanguage', _language, 'files'], []);

        var filteredFiles = _lodash.default.reduce(state.files, (result, file, fileId) => {
          if (removeFileIds.includes(fileId)) {
            return result;
          }

          result[fileId] = file;
          return result;
        }, {});

        return _objectSpread(_objectSpread({}, state), {}, {
          files: _objectSpread(_objectSpread({}, filteredFiles), transformedFiles),
          filesByAsset: _objectSpread(_objectSpread({}, state.filesByAsset), {}, {
            [_assetId]: _objectSpread(_objectSpread({}, _languageContainer), {}, {
              filesByLanguage: _objectSpread(_objectSpread({}, _filesByLanguage), {}, {
                [_language]: {
                  files: fileIds,
                  lastLoadedAt: now,
                  isLoading: false
                }
              })
            })
          })
        });
      }

    case FETCH_FILES_FAILURE:
      {
        var {
          assetId: _assetId2,
          language: _language2
        } = action.payload;

        var _languageContainer2 = state.filesByAsset[_assetId2] || {
          filesByLanguage: {}
        };

        var _filesByLanguage2 = _languageContainer2.filesByLanguage;
        var filesForLanguage = _filesByLanguage2[_language2] || {};
        return _objectSpread(_objectSpread({}, state), {}, {
          filesByAsset: _objectSpread(_objectSpread({}, state.filesByAsset), {}, {
            [_assetId2]: _objectSpread(_objectSpread({}, _languageContainer2), {}, {
              filesByLanguage: _objectSpread(_objectSpread({}, _filesByLanguage2), {}, {
                [_language2]: _objectSpread(_objectSpread({}, filesForLanguage), {}, {
                  isLoading: false
                })
              })
            })
          })
        });
      }

    default:
      return state;
  }
}

var actions = {
  getAssetFiles: (0, _reduxActions.createAction)(FETCH_FILES)
};
/**
 * Actions that should only be invoked internally
 */

exports.actions = actions;
var internalActions = {
  getAssetFilesRequest: (0, _reduxActions.createAction)(FETCH_FILES_REQUEST),
  getAssetFilesSuccess: (0, _reduxActions.createAction)(FETCH_FILES_SUCCESS),
  getAssetFilesFailure: (0, _reduxActions.createAction)(FETCH_FILES_FAILURE)
};
var selectors = {
  areAssetFilesLoading: createSelector(areAssetFilesLoading),
  areAssetFilesLoaded: createSelector(areAssetFilesLoaded),
  getAssetFilesLastLoadedAt: createSelector(getAssetFilesLastLoadedAt),
  getAssetFilesForLanguage: createSelector(getAssetFilesForLanguage),
  getAssetFilesByLanguage: createSelector(getAssetFilesByLanguage)
};
exports.selectors = selectors;

function getAssetFilesByLanguage(state, assetId) {
  var assetContainer = state.filesByAsset[assetId];

  if (!assetContainer) {
    return {};
  }

  if (!assetContainer.filesByLanguage) {
    return {};
  }

  var assetFilesByLanguage = _objectSpread({}, assetContainer.filesByLanguage);

  return _lodash.default.mapValues(assetFilesByLanguage, languageContainer => {
    return {
      isLoading: languageContainer.isLoading,
      lastLoadedAt: languageContainer.lastLoadedAt,
      files: _lodash.default.map(languageContainer.files, fileId => state.files[fileId].data)
    };
  });
}

function areAssetFilesLoading(state, assetId, language) {
  var assetContainer = state.filesByAsset[assetId];

  if (!assetContainer) {
    return false;
  }

  var languageContainer = assetContainer.filesByLanguage[language];

  if (!languageContainer) {
    return false;
  }

  return !!languageContainer.isLoading[language];
}

function areAssetFilesLoaded(state, assetId, language) {
  var assetContainer = state.filesByAsset[assetId];

  if (!assetContainer) {
    return false;
  }

  var languageContainer = assetContainer.filesByLanguage[language];

  if (!languageContainer) {
    return false;
  }

  return !!languageContainer.lastLoadedAt;
}

function getAssetFilesLastLoadedAt(state, assetId, language) {
  var assetContainer = state.filesByAsset[assetId];

  if (!assetContainer) {
    return null;
  }

  var languageContainer = assetContainer.filesByLanguage[language];

  if (!languageContainer) {
    return false;
  }

  return languageContainer.lastLoadedAt;
}

function getAssetFilesForLanguage(state, assetId, language) {
  var assetContainer = state.filesByAsset[assetId];

  if (!assetContainer) {
    return [];
  }

  var languageContainer = assetContainer.filesByLanguage[language];

  if (!languageContainer) {
    return false;
  }

  var assets = _lodash.default.map(languageContainer.files, fileId => state.files[fileId].data);

  if (language) {
    assets = _lodash.default.filter(assets, {
      language
    });
  }

  return assets;
}
/**
 * Helper method that grabs our specific part of the global store, then passes it into the given selector.
 * This makes it so that each selector doesn't have to grab the sub-state itself, reducing code duplication.
 */


function createSelector(selector) {
  return function selectorWrapper(state) {
    var filesStore = state[STORE_NAME];

    for (var _len = arguments.length, rest = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      rest[_key - 1] = arguments[_key];
    }

    return selector(filesStore, ...rest);
  };
}

function* watchFiles() {
  yield (0, _effects.takeEvery)(FETCH_FILES, fetchFiles);
}