"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.ORDER_CONSTRAINTS = void 0;

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _lodash = _interopRequireDefault(require("lodash"));

var _lodash2 = _interopRequireDefault(require("lodash.get"));

var _lodash3 = _interopRequireDefault(require("lodash.capitalize"));

var _moment = _interopRequireDefault(require("moment"));

var _jstz = _interopRequireDefault(require("jstz"));

var _assetService = _interopRequireDefault(require("./asset-service"));

var _validationService = _interopRequireDefault(require("./validation-service"));

var _translationService = _interopRequireDefault(require("./translation-service"));

var _uploadService = _interopRequireDefault(require("./upload-service"));

var _routeService = _interopRequireDefault(require("./route-service"));

var _idService = _interopRequireDefault(require("./id-service"));

var _apiService = _interopRequireDefault(require("./api-service"));

var _exceptionHandler = require("../pixwel/exception-handler.factory");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var ROADSHOW_REQUIRED = {
  if: {
    condition: (value, _ref) => {
      var {
        meta
      } = _ref;
      return _assetService.default.isRoadshowAsset(meta.asset);
    },
    validations: {
      presence: {
        allowEmpty: false
      }
    }
  }
};
var USAGE_REQUIRED = {
  custom: {
    fn: (value, _ref2) => {
      var {
        fullObject
      } = _ref2;

      // We can check all usage sections instead of the asset specific ones
      // as long as no usage options are defaulted to on.
      var valid = _lodash.default.reduce(fullObject.usage, (result, usageOptions) => {
        return result || _lodash.default.reduce(usageOptions, (result, value, key) => {
          if (['sendTo', 'shipping', 'deliveryDeadline'].indexOf(key) >= 0) {
            return result;
          }

          return result || value;
        }, result);
      }, false);

      return valid ? undefined : 'Must select at least one usage';
    }
  }
};
var ORDER_CONSTRAINTS = {
  due: {
    dateTime: {
      if: {
        condition: (value, _ref3) => {
          var {
            meta
          } = _ref3;
          var {
            order
          } = meta;
          return !(order.localization.autosubs || order.localization.auto);
        },
        validations: {
          presence: true,
          datetime: {
            earliest: _moment.default.utc().add(1, 'day'),
            tooEarly: 'must be at least 24 hours from now'
          }
        }
      }
    }
  },
  localization: {
    language: {
      presence: {
        allowEmpty: false
      }
    },
    territory: {
      presence: {
        allowEmpty: false
      }
    },
    audioFile: {
      if: {
        condition: (value, _ref4) => {
          var {
            meta
          } = _ref4;
          var {
            order
          } = meta;
          return order.localization.auto === 'audio';
        },
        validations: {
          presence: {
            allowEmpty: false
          }
        }
      }
    },
    translationProviderType: {
      presence: {
        allowEmpty: false
      }
    },
    translationProvider: {
      if: {
        condition: (value, _ref5) => {
          var {
            meta
          } = _ref5;
          var {
            order
          } = meta;
          var needsTranslation = [order.localization.dialogue, order.localization.narration, order.localization.graphics].indexOf('Subtitled') > -1;

          if (!needsTranslation) {
            return false;
          }

          return order.localization.translationProviderType === 'email';
        },
        validations: {
          presence: {
            allowEmpty: false
          }
        }
      }
    },
    translationFile: {
      if: {
        condition: (value, _ref6) => {
          var {
            meta
          } = _ref6;
          var {
            order
          } = meta;
          var needsTranslation = [order.localization.dialogue, order.localization.narration, order.localization.graphics].indexOf('Subtitled') > -1;

          if (!needsTranslation) {
            return false;
          }

          return order.localization.translationProviderType === 'upload';
        },
        validations: {
          presence: {
            allowEmpty: false
          }
        }
      }
    }
  },
  tags: {
    tags: {
      if: {
        condition: (value, _ref7) => {
          var {
            meta
          } = _ref7;
          var {
            order,
            asset
          } = meta;
          return !(order.localization.autosubs || order.localization.auto) && !_assetService.default.isPrintAsset(asset) && !_assetService.default.isRoadshowAsset(asset);
        },
        validations: {
          presence: {
            allowEmpty: false,
            message: 'must have at least 1 tag'
          },
          arrayOf: {
            tag: {
              presence: {
                allowEmpty: false
              },
              unique: {
                getAllValues: (value, _ref8) => {
                  var {
                    fullObject
                  } = _ref8;
                  return _lodash.default.map(fullObject.tags, 'tag');
                },
                compareFn: (v1, v2) => {
                  return v1 === v2;
                },
                findDuplicates: tags => {
                  return new Set(tags.map(e => e.tag.toLowerCase())).size !== tags.length;
                }
              }
            }
          }
        }
      }
    }
  },
  usage: {
    broadcast: USAGE_REQUIRED,
    online: USAGE_REQUIRED,
    theatrical: USAGE_REQUIRED,
    print: USAGE_REQUIRED,
    digital: USAGE_REQUIRED,
    roadshow: USAGE_REQUIRED,
    streaming: USAGE_REQUIRED
  },
  questions: {
    eventName: ROADSHOW_REQUIRED,
    territory: ROADSHOW_REQUIRED,
    venueName: ROADSHOW_REQUIRED,
    venueAddress: ROADSHOW_REQUIRED,
    rehearsalDate: ROADSHOW_REQUIRED,
    eventDate: ROADSHOW_REQUIRED,
    attendeeDetails: ROADSHOW_REQUIRED
  },
  additionalInfo: {}
};
exports.ORDER_CONSTRAINTS = ORDER_CONSTRAINTS;
var WorkRequestOrderService = {
  createBlankOrder: () => {
    var future = new Date();
    future.setTime(future.getTime() + 48 * 60 * 60 * 1000);
    return {
      due: {
        dateTime: future
      },
      localization: {
        language: '',
        territory: '',
        autosubs: false,
        auto: false,
        dialogue: 'OV',
        narration: 'OV',
        graphics: 'OV',
        printTranslation: 'ov',
        roadshowTranslation: 'OV',
        translationProviderType: 'me',
        translationProvider: null,
        translationFile: null,
        needsApproval: false,
        dubbed: false,
        audioFile: null
      },
      tags: {
        tags: [createTag()]
      },
      usage: {
        broadcast: {
          sendTo: [],
          'SD 4:3': false,
          'SD 16:9': false,
          'HD': false
        },
        online: {
          sendTo: [],
          'Orban': false,
          '1920p': false,
          '1350p': false,
          '1080p': false,
          '720p': false,
          '480p': false,
          '360p': false
        },
        theatrical: {
          sendTo: [],
          deliveryDeadline: {
            dateTime: future
          },
          shipping: {
            qty: 0,
            addresses: []
          },
          '2D Flat': false,
          '2D Scope': false,
          '2D Combo': false,
          '3D Flat': false,
          '3D Scope': false,
          '3D Combo': false,
          '2D Flat (4K)': false,
          '2D Scope (4K)': false,
          '2D Combo (4K)': false,
          '3D Flat (4K)': false,
          '3D Scope (4K)': false,
          '3D Combo (4K)': false,
          'IMAX Laser': false,
          'IMAX Xenon': false,
          'ATMOS': false
        },
        print: {
          sendTo: [],
          cmyk: false
        },
        digital: {
          sendTo: [],
          rgb: false
        },
        roadshow: {
          sendTo: [],
          physical: false,
          aspera: false
        },
        streaming: {
          sendTo: [],
          streamingfile: false
        }
      },
      questions: {
        eventName: '',
        territory: '',
        venueName: '',
        venueAddress: '',
        rehearsalDate: null,
        eventDate: null,
        attendeeDetails: '',
        securityDetails: '',
        notes: ''
      },
      additionalInfo: {
        specialInstructions: '',
        files: []
      }
    };
  },
  validateOrder: (order, asset) => {
    var options = {
      meta: {
        order,
        asset
      }
    };
    return _validationService.default.validateNested(order, ORDER_CONSTRAINTS, options);
  },
  wrapOrderWithValidations: (order, asset) => {
    var validations = WorkRequestOrderService.validateOrder(order, asset);
    var wrappedOrder = {};

    _lodash.default.forEach(order, (orderSection, section) => {
      // If the section is simple data type (i.e. not an object)
      // then we skip validation. This is probably meta information like _id
      if (!_lodash.default.isObject(orderSection)) {
        return;
      }

      wrappedOrder[section] = wrapFields(orderSection, validations[section], BLANK_ORDER[section]);
    });

    return wrappedOrder;

    function wrapFields(section, sectionValidation, BLANKS) {
      var fields = {};

      _lodash.default.forEach(section, (fieldValue, field) => {
        var fieldValidation = (0, _lodash2.default)(sectionValidation, field);

        var isArray = _lodash.default.isArray(fieldValue);

        var wrappedField = isArray ? [] : {};

        if (field === 'theatrical') {
          wrappedField.isPristine = _lodash.default.isEqual(_objectSpread(_objectSpread({}, fieldValue), {}, {
            deliveryDeadline: ''
          }), _objectSpread(_objectSpread({}, BLANKS[field]), {}, {
            deliveryDeadline: ''
          }));
        } else {
          wrappedField.isPristine = _lodash.default.isEqual(fieldValue, BLANKS[field]);
        }

        wrappedField.isInvalid = !_lodash.default.isEmpty(fieldValidation);
        wrappedField.value = fieldValue;
        wrappedField.validations = fieldValidation;

        if (isArray) {
          var arrayOfValidations = (0, _lodash2.default)(fieldValidation, 'arrayOf');

          _lodash.default.forEach(fieldValue, (element, i) => {
            var elementValidation = (0, _lodash2.default)(arrayOfValidations, i);
            var wrappedElement = elementValidation ? wrapFields(element, elementValidation, BLANK_PARTIALS[field]) : undefined;
            wrappedField.push(wrappedElement);
          });
        }

        fields[field] = wrappedField;
      });

      return fields;
    }
  },
  getCostEstimate: (order, asset, assetType) => {
    var workRequest = WorkRequestOrderService.toWorkRequest(order, asset, assetType);
    return _apiService.default.client.post('/workrequests/estimate', workRequest).then(estimate => _lodash.default.pick(estimate.data, ['applied', 'base', 'errors']));
  },
  doesOrderNeedTranslation: order => {
    var {
      dialogue,
      narration,
      graphics,
      printTranslation,
      roadshowTranslation
    } = order.localization;
    var isPrintTranslation = ['date', 'full'].includes(printTranslation);
    var isAvTranslation = [dialogue, narration, graphics, roadshowTranslation].includes('Subtitled') || [graphics].includes('Dedicated / Localized');
    return isPrintTranslation || isAvTranslation;
  },
  doesGfxOrderNeedTranslation: (order, asset) => {
    var _order$localization, _order$localization2, _order$localization3, _asset$transcription;

    return ['Dedicated / Localized', 'Subtitled'].includes((_order$localization = order.localization) === null || _order$localization === void 0 ? void 0 : _order$localization.graphics) && ((_order$localization2 = order.localization) === null || _order$localization2 === void 0 ? void 0 : _order$localization2.dialogue) !== 'Subtitled' && ((_order$localization3 = order.localization) === null || _order$localization3 === void 0 ? void 0 : _order$localization3.narration) !== 'Subtitled' && !!((_asset$transcription = asset.transcription) !== null && _asset$transcription !== void 0 && _asset$transcription.graphics);
  },
  toWorkRequest: (order, asset, assetType) => {
    var _order$usage$theatric, _order$usage$theatric2, _order$localization4, _order$localization4$;

    var isPrintAsset = _assetService.default.isPrintAsset(asset);

    var isRoadshowAsset = _assetService.default.isRoadshowAsset(asset);

    if (order.localization.autosubs || order.localization.auto) {
      order.usage.theatrical = BLANK_ORDER.usage.theatrical;
    }

    var transformedOrder = {
      asset: asset._id,
      assetType: asset === null || asset === void 0 ? void 0 : asset.type,
      assetCategory: assetType === null || assetType === void 0 ? void 0 : assetType.category,
      due: {
        date: order.due.dateTime,
        tz: _jstz.default.determine().name()
      },
      deliveryDeadline: {
        date: (_order$usage$theatric = order.usage['theatrical']) === null || _order$usage$theatric === void 0 ? void 0 : (_order$usage$theatric2 = _order$usage$theatric.deliveryDeadline) === null || _order$usage$theatric2 === void 0 ? void 0 : _order$usage$theatric2.dateTime,
        tz: _jstz.default.determine().name()
      },
      auto: !!order.localization.auto,
      autoMode: order.localization.auto,
      autosubs: order.localization.autosubs,
      language: order.localization.language,
      territory: order.localization.territory,
      dng: {
        dialogue: [order.localization.dialogue],
        graphics: [order.localization.graphics],
        narration: [order.localization.narration]
      },
      audioFile: order.localization.dubbed ? order.localization.audioFile : null,
      instructions: order.additionalInfo.specialInstructions,
      urls: _lodash.default.mapValues({
        view: _routeService.default.stateHref('work-request', {
          id: ':workRequest'
        }, {
          absolute: true
        }),
        manage: _routeService.default.stateHref('work-request', {
          id: ':workRequest'
        }, {
          absolute: true
        }),
        invite: _routeService.default.stateHref('register', {
          code: ':code'
        }, {
          absolute: true
        }),
        translate: _routeService.default.stateHref('subtitler', {
          id: ':workRequest'
        }, {
          absolute: true
        })
      }, decodeURIComponent)
    }; // If this order has an id, assign it to work request

    if (order._id) {
      transformedOrder._id = order._id;
    }

    if ((_order$localization4 = order.localization) !== null && _order$localization4 !== void 0 && (_order$localization4$ = _order$localization4.translationProvider) !== null && _order$localization4$ !== void 0 && _order$localization4$.id) {
      var _order$localization5, _order$localization5$;

      transformedOrder.translator = (_order$localization5 = order.localization) === null || _order$localization5 === void 0 ? void 0 : (_order$localization5$ = _order$localization5.translationProvider) === null || _order$localization5$ === void 0 ? void 0 : _order$localization5$.id;
    }

    if (isPrintAsset) {
      transformedOrder.dng = 'OV';
      transformedOrder.printTranslation = order.localization.printTranslation;
      transformedOrder.tags = [];
      var {
        uses,
        recipients,
        shipping
      } = transformUsage(_lodash.default.pick(order.usage, ['print', 'digital']));
      transformedOrder.uses = uses;
      transformedOrder.recipients = recipients;
      transformedOrder.shipping = shipping;
    } else if (isRoadshowAsset) {
      transformedOrder.dng = order.localization.roadshowTranslation;
      transformedOrder.questions = order.questions;
      var {
        uses: _uses,
        recipients: _recipients
      } = transformUsage(_lodash.default.pick(order.usage, ['roadshow', 'streaming']));
      transformedOrder.uses = _uses;
      transformedOrder.recipients = _recipients;
    } else {
      if (!(order.localization.autosubs || order.localization.auto)) {
        transformedOrder.tags = _lodash.default.map(order.tags.tags, tag => ({
          name: tag.tag,
          description: tag.instructions,
          files: [] // Don't send tag files on WR creation, they are saved later using a PATCH request (see *placeOrder function)

        }));
      }

      transformedOrder.files = [{
        name: 'attachments',
        files: []
      }];
      var {
        uses: _uses2,
        dcp,
        recipients: _recipients2,
        shipping: _shipping
      } = transformUsage(_lodash.default.pick(order.usage, ['broadcast', 'online', 'theatrical']));
      transformedOrder.uses = _uses2;
      transformedOrder.dcp = dcp;
      transformedOrder.recipients = _recipients2;
      transformedOrder.shippingAddresses = _shipping;
    }

    return transformedOrder;
  },
  fromWorkRequest: workRequest => {
    var isPrintAsset = workRequest.assetCategory === 'print';
    var isRoadshowAsset = workRequest.assetType === 'roadshow';
    var transformedWorkRequest = {
      _id: workRequest._id,
      due: {
        dateTime: _moment.default.unix(workRequest.due.date).toDate()
      },
      localization: {
        language: workRequest.language,
        territory: workRequest.territory,
        autosubs: workRequest.autosubs,
        auto: workRequest.auto,
        autoMode: workRequest.autoMode,
        dialogue: 'OV',
        narration: 'OV',
        graphics: 'OV',
        printTranslation: 'ov',
        roadshowTranslation: 'OV',
        translationProviderType: 'me',
        translationProvider: null,
        translationFile: null,
        needsApproval: false,
        dubbed: false,
        audioFile: null
      },
      usage: {
        // TODO - leave this as is, loop through and switch properties to true below
        broadcast: {
          sendTo: [],
          'SD 4:3': false,
          'SD 16:9': false,
          HD: false
        },
        online: {
          sendTo: [],
          Orban: false,
          '1920p': false,
          '1350p': false,
          '1080p': false,
          '720p': false,
          '480p': false,
          '360p': false
        },
        theatrical: {
          sendTo: [],
          shipping: {
            qty: 0,
            addresses: []
          },
          '2D Flat': false,
          '2D Scope': false,
          '2D Combo': false,
          '3D Flat': false,
          '3D Scope': false,
          '3D Combo': false,
          '2D Flat (4K)': false,
          '2D Scope (4K)': false,
          '2D Combo (4K)': false,
          '3D Flat (4K)': false,
          '3D Scope (4K)': false,
          '3D Combo (4K)': false,
          'IMAX Laser': false,
          'IMAX Xenon': false,
          ATMOS: false
        },
        print: {
          sendTo: [],
          cmyk: false
        },
        digital: {
          sendTo: [],
          rgb: false
        },
        roadshow: {
          sendTo: [],
          physical: false,
          aspera: false
        },
        streaming: {
          sendTo: [],
          streamingfile: false
        }
      },
      questions: {
        eventName: '',
        territory: '',
        venueName: '',
        venueAddress: '',
        rehearsalDate: null,
        eventDate: null,
        attendeeDetails: '',
        securityDetails: '',
        notes: ''
      },
      additionalInfo: {
        specialInstructions: workRequest.instructions || '',
        files: workRequest.files
      }
    };

    if (workRequest.deliveryDeadline) {
      transformedWorkRequest.usage.theatrical.deliveryDeadline = {
        dateTime: (0, _moment.default)(workRequest.deliveryDeadline.date).toDate()
      };
    }

    if (isPrintAsset) {
      // localization
      transformedWorkRequest.localization.printTranslation = workRequest.printTranslation; // tags

      transformedWorkRequest.tags = []; // usage

      transformedWorkRequest.usage.print.cmyk = !!workRequest.uses.details.Cmyk;
      transformedWorkRequest.usage.digital.rgb = !!workRequest.uses.details.Rgb; // print recipients

      if (workRequest.recipients.Cmyk.length > 0) {
        transformedWorkRequest.usage.print.sendTo = _idService.default.convertToIds(workRequest.recipients.Cmyk);
      } // digital recipients


      if (workRequest.recipients.Rgb.length > 0) {
        transformedWorkRequest.usage.digital.sendTo = _idService.default.convertToIds(workRequest.recipients.Rgb);
      }
    } else if (isRoadshowAsset) {
      // localization
      transformedWorkRequest.localization.roadshowTranslation = workRequest.dng; // translation

      if (workRequest.translation) {
        var _workRequest$translat, _workRequest$graphics;

        transformedWorkRequest.localization = _objectSpread(_objectSpread({}, transformedWorkRequest.localization), {}, {
          translationProviderType: getTranslationProviderType(workRequest),
          translationProvider: _idService.default.convertToId(workRequest.translation.translator),
          translationFile: workRequest.translation.file || null,
          needsApproval: ((_workRequest$translat = workRequest.translation) === null || _workRequest$translat === void 0 ? void 0 : _workRequest$translat.approval) || ((_workRequest$graphics = workRequest.graphicsTranslation) === null || _workRequest$graphics === void 0 ? void 0 : _workRequest$graphics.approval) || false
        });
      } // usage


      if (workRequest.uses.details.Dcp) {
        workRequest.uses.details.Dcp.forEach(use => {
          transformedWorkRequest.usage.roadshow[use.value] = true;
        });
      }

      if (workRequest.uses.details.Streamingfile) {
        workRequest.uses.details.Streamingfile.forEach(use => {
          transformedWorkRequest.usage.streaming[use.value] = true;
        });
      } // recipients


      if (workRequest.recipients.Dcp.length > 0) {
        transformedWorkRequest.usage.roadshow.sendTo = _idService.default.convertToIds(workRequest.recipients.Dcp);
      }

      if (workRequest.recipients.Streamingfile.length > 0) {
        transformedWorkRequest.usage.streaming.sendTo = _idService.default.convertToIds(workRequest.recipients.Streamingfile);
      } // questions


      transformedWorkRequest.questions = workRequest.questions;
    } else {
      // localization
      transformedWorkRequest.localization = _objectSpread(_objectSpread({}, transformedWorkRequest.localization), {}, {
        dialogue: workRequest.dng.dialogue[0],
        narration: workRequest.dng.narration[0],
        graphics: workRequest.dng.graphics[0]
      }); // tags

      if (!(workRequest.autosubs || workRequest.auto)) {
        transformedWorkRequest.tags = {
          tags: workRequest.tags.map(tag => ({
            tag: tag.name,
            instructions: tag.description || '',
            files: tag.files || []
          }))
        };
      } // translation


      if (workRequest.translation) {
        var _workRequest$translat2, _workRequest$graphics2;

        transformedWorkRequest.localization = _objectSpread(_objectSpread({}, transformedWorkRequest.localization), {}, {
          translationProviderType: getTranslationProviderType(workRequest),
          translationProvider: _idService.default.convertToId(workRequest.translation.translator),
          translationFile: workRequest.translation.file || null,
          needsApproval: ((_workRequest$translat2 = workRequest.translation) === null || _workRequest$translat2 === void 0 ? void 0 : _workRequest$translat2.approval) || ((_workRequest$graphics2 = workRequest.graphicsTranslation) === null || _workRequest$graphics2 === void 0 ? void 0 : _workRequest$graphics2.approval) || false
        });
      } // broadcast/online usage


      _lodash.default.forEach(workRequest.uses.details, (usesArr, category) => {
        // 'Broadcast' -> 'broadcast'
        var useCategory = category.toLowerCase();
        var useKey;
        usesArr.forEach(use => {
          // 'Sd 4:3' -> 'SD 4:3'
          useKey = useCategory === 'broadcast' ? use.value.toUpperCase() : use.value;
          transformedWorkRequest.usage[useCategory][useKey] = true;
        });
      }); // dcp usage


      Object.keys(workRequest.dcp.details).forEach(key => {
        var details = workRequest.dcp.details[key];
        var useCategory = 'theatrical';
        var useKey = key;
        transformedWorkRequest.usage[useCategory][useKey] = true;
        transformedWorkRequest.usage[useCategory].shipping.qty += details.quantity;
      });

      if (transformedWorkRequest.usage['theatrical'].shipping.qty) {
        transformedWorkRequest.usage['theatrical'].shipping.qty /= Object.keys(workRequest.dcp.details).length;
      } // recipients


      _lodash.default.forEach(workRequest.recipients, (recipients, category) => {
        if (recipients.length > 0) {
          var useCategory = category;

          if (useCategory in REVERSE_USAGE_KEY_MAP) {
            useCategory = REVERSE_USAGE_KEY_MAP[useCategory];
          }

          transformedWorkRequest.usage[useCategory].sendTo = _idService.default.convertToIds(recipients);
        }
      });

      transformedWorkRequest.usage.theatrical.shipping.addresses = workRequest.shippingAddresses;
      transformedWorkRequest.usage.theatrical.shipping.addresses = setBlankAddresses(transformedWorkRequest.usage.theatrical);
    }

    return transformedWorkRequest;
  },
  toWorkRequestPatch: function () {
    var _toWorkRequestPatch = (0, _asyncToGenerator2.default)(function* (section, order, workRequest, asset, newDueDate) {
      var isPrintAsset = _assetService.default.isPrintAsset(asset);

      var isRoadshowAsset = _assetService.default.isRoadshowAsset(asset);

      var orderDueDateUnix = (0, _moment.default)(order.due.dateTime).unix();
      var patchData = {};
      var translationPatchData = {};
      var graphicsTranslationPatchData = {};
      var uploadRequest;
      var uploadedFile; // Localization updated

      if (section === 'localization') {
        // due date
        if (orderDueDateUnix !== workRequest.due.date) {
          patchData.due = _objectSpread(_objectSpread({}, workRequest.due), {}, {
            date: order.due.dateTime
          });
        } // language & territory


        if (order.localization.language !== workRequest.language) {
          patchData.language = order.localization.language;
        }

        if (order.localization.territory !== workRequest.territory) {
          patchData.territory = order.localization.territory;
        }

        var needsTranslation = WorkRequestOrderService.doesOrderNeedTranslation(order);
        var needsGfxTranslation = WorkRequestOrderService.doesGfxOrderNeedTranslation(order, asset); // edit existing translation

        if (workRequest.translation) {
          var _workRequest$graphics3, _workRequest$graphics4;

          // language
          if (patchData.language) {
            translationPatchData.language = patchData.language;
            graphicsTranslationPatchData.language = patchData.language;
          } // reset all translation properties if order no longer needs translation


          if (!needsTranslation && !needsGfxTranslation) {
            patchData.translation = null;
            patchData.status = 'submitted';
            order.localization = _objectSpread(_objectSpread({}, order.localization), {}, {
              translationProvider: 'me',
              translationProviderType: null,
              translationFile: null,
              needsApproval: false,
              dubbed: false,
              audioFile: null
            });
          } // translator


          if (!order.localization.translationProvider) {
            order.localization.translationProvider = workRequest.from;
          }

          var translatorId = order.localization.translationProvider._id || order.localization.translationProvider.id;

          if (translatorId !== workRequest.translation.translator._id) {
            translationPatchData.translator = translatorId;
          }

          if (translatorId !== ((_workRequest$graphics3 = workRequest.graphicsTranslation) === null || _workRequest$graphics3 === void 0 ? void 0 : (_workRequest$graphics4 = _workRequest$graphics3.translator) === null || _workRequest$graphics4 === void 0 ? void 0 : _workRequest$graphics4._id)) {
            graphicsTranslationPatchData.translator = translatorId;
          } // translation file


          if (order.localization.translationProviderType === 'upload' && order.localization.translationFile instanceof File) {
            uploadRequest = {
              translationId: workRequest.translation._id,
              file: order.localization.translationFile
            };

            try {
              uploadedFile = yield _uploadService.default.uploadTranslation(order.localization.translationFile, uploadRequest, _lodash.default.noop);
            } catch (err) {
              (0, _exceptionHandler.captureError)(err, "Failed to upload translation file");
            } finally {
              translationPatchData.file = uploadedFile;
              translationPatchData.status = 'submitted';
            }
          } else {
            translationPatchData.file = null;
          } // translation approval


          translationPatchData.approval = order.localization.needsApproval;
          graphicsTranslationPatchData.approval = order.localization.needsApproval;
          yield _translationService.default.updateTranslation(workRequest.translation._id, translationPatchData);

          if (workRequest.graphicsTranslation) {
            yield _translationService.default.updateTranslation(workRequest.graphicsTranslation._id, graphicsTranslationPatchData);
          }
        } // creating new translation
        else {
          if ((needsTranslation || needsGfxTranslation) && workRequest.status === 'submitted') {
            patchData.status = 'incomplete';
          }

          if (order.localization.translationProviderType === 'email') {
            patchData.translator = order.localization.translationProvider.id;
          }
        } // if new translation is type 'upload', handle uploading file after updating
        // work request, since we'll need the translation id created by the API


        if (isPrintAsset) {
          patchData.dng = 'OV';
          patchData.printTranslation = order.localization.printTranslation;
        } else if (isRoadshowAsset) {
          patchData.dng = order.localization.roadshowTranslation;
        } else {
          // AV asset
          patchData.dng = {
            dialogue: [order.localization.dialogue],
            graphics: [order.localization.graphics],
            narration: [order.localization.narration]
          };
        }
      } // Tags updated


      if (section === 'tags') {
        if (!(order.localization.autosubs || order.localization.auto)) {
          patchData.tags = order.tags.tags.map(tag => ({
            name: tag.tag,
            description: tag.instructions,
            files: tag.files.filter(file => typeof file === 'string') // keep any existing files (URLs)

          }));
        }
      } // Usage updated


      if (section === 'usage') {
        var keys = [];

        if (isPrintAsset) {
          keys.push('print', 'digital');
        } else if (isRoadshowAsset) {
          keys.push('roadshow', 'streaming');
        } else {
          // AV asset
          keys.push('broadcast', 'online', 'theatrical');
        }

        var {
          uses,
          dcp,
          recipients,
          shipping
        } = transformUsage(_lodash.default.pick(order.usage, keys));
        patchData.uses = uses;
        patchData.dcp = dcp;
        patchData.recipients = recipients;
        patchData.shippingAddresses = shipping;
      } // Due date pushed 24 hrs


      if (newDueDate) {
        patchData.due = _objectSpread(_objectSpread({}, workRequest.due), {}, {
          date: newDueDate
        });
      }

      return patchData;
    });

    function toWorkRequestPatch(_x, _x2, _x3, _x4, _x5) {
      return _toWorkRequestPatch.apply(this, arguments);
    }

    return toWorkRequestPatch;
  }()
}; // Used for checking if an order's field is pristine

var BLANK_ORDER = WorkRequestOrderService.createBlankOrder();
var BLANK_PARTIALS = {
  tags: createTag()
};
var USAGE_KEY_MAP = {
  Roadshow: 'Dcp',
  Theatrical: 'Dcp',
  Streaming: 'Streamingfile',
  Digital: 'Rgb',
  Print: 'Cmyk'
};
var REVERSE_USAGE_KEY_MAP = {
  '2D Flat': 'theatrical',
  '2D Scope': 'theatrical',
  '2D Combo': 'theatrical',
  '3D Flat': 'theatrical',
  '3D Scope': 'theatrical',
  Broadcast: 'broadcast',
  Online: 'online',
  dcp: 'theatrical',
  streamingfile: 'streaming',
  rgb: 'digital',
  cmyk: 'print'
};

function createTag() {
  return {
    tag: '',
    instructions: '',
    files: []
  };
}

function transformUsage(usage) {
  var uses = {
    items: [],
    details: {}
  };
  var dcp = {
    items: [],
    details: {}
  };
  var recipients = {};
  var shipping = {};

  _lodash.default.forEach(usage, (use, category) => {
    var useKey = (0, _lodash3.default)(category);

    if (useKey in USAGE_KEY_MAP) {
      useKey = USAGE_KEY_MAP[useKey];
    } // Get all selected usage


    var values = _lodash.default.filter(_lodash.default.keys(use), usage => {
      return ['sendTo', 'shipping', 'deliveryDeadline'].indexOf(usage) < 0 && use[usage];
    }); // If values have been selected, add them to the work order


    if (values.length) {
      if (useKey === 'Dcp' && category !== 'roadshow') {
        values.forEach(dcpItem => {
          var qty = parseInt(use.shipping.qty);
          dcp.items.push(dcpItem);
          dcp.details[dcpItem] = {
            link: true,
            ship: qty > 0,
            quantity: qty
          };
          recipients[dcpItem] = _idService.default.convertFromIds(use.sendTo);

          if (use.shipping.qty) {
            shipping = use.shipping.addresses;
          }
        });
      } else {
        uses.items.push(useKey);
        uses.details[useKey] = _lodash.default.map(values, value => ({
          value: value,
          // TODO: Shipping
          ship: false
        }));

        if (use.sendTo.length) {
          recipients[useKey] = _idService.default.convertFromIds(use.sendTo);
        }
      }
    }
  });

  return {
    uses,
    dcp,
    recipients,
    shipping
  };
}

function getTranslationProviderType(workRequest) {
  var {
    translation,
    from
  } = workRequest;
  var isUserTranslator = translation.translator._id === from._id;

  if (!isUserTranslator) {
    return 'email';
  }

  if (translation.file) {
    return 'upload';
  }

  return 'me';
}

function setBlankAddresses(field) {
  field.sendTo.forEach(user => {
    if (!field.shipping.addresses.hasOwnProperty(user.name)) {
      field.shipping.addresses[user.name] = {
        name: '$links' in user ? user.name : '',
        address: '',
        city: '',
        state: '',
        postalCode: '',
        country: ''
      };
    }
  });
  return field.shipping.addresses;
}

var _default = WorkRequestOrderService;
exports.default = _default;